import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  TextField,
  IconButton,
  MenuItem,
  Autocomplete,
  Popper,
  Checkbox,
} from "@mui/material";
import { headingStyle, xBtn } from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import // textfieldTextBlueBorder,
  "../../style/AppointmentStyle";
import Select from "react-select";
import { textfieldTextBlueBorder } from "../../style/PatientDetailsStyle";
import AddIcon from "@mui/icons-material/Add";
import { textfieldTextBlueBr } from "../../style/PharmacyStyle";
import { orangeButton, primaryBtnBlue } from "../../style/PatientDetailsStyle";
import { showToast } from "../../utils/toastUtil";
import {
  autoFocusOffWithOutBorder,
  drTableRowBlueBg,
} from "../../style/PatinetFormStyle";
import AddCardIcon from "@mui/icons-material/AddCard";
import UseFetchLabInventories from "../../helper/UseFetchLabInventories";
import UseFetchPackList from "../../helper/UseFetchPackList";
import {
  generatePurchaseOrder,
  medicalTestReports,
} from "../../connections/postApi";
import {
  menuItemStyle,
  textfieldTextBlueBorderPharmacy,
} from "../../style/AppointmentStyle";
import { getMedicineByName } from "../../connections/getApi";
import ScienceTwoToneIcon from "@mui/icons-material/ScienceTwoTone";
import MedicalTestCategoryListAll from "../../helper/MedicalTestCategoryListAll";
import LabTestListAll from "../../helper/LabTestListAll";
import UseGetallMedicalTestAndComposite from "../../helper/UseGetallMedicalTestAndComposite";
import { CheckBox } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { putUpdateMedicalTest } from "../../connections/putApi";
import useDebounce from "../../hooks/UseDebounce";

const EditMedicalTestModal = ({ onClose, refetch }) => {
  const { data: MedicalTestCategoryList } = MedicalTestCategoryListAll();
  const { data: LabTestList } = LabTestListAll();
  const location = useLocation();
  const medicalTestData = location.state || null;
  useEffect(() => {
    if (LabTestList?.payload?.labs?.length > 0) {
      localStorage.setItem(
        "LabTestList",
        JSON.stringify(LabTestList.payload.labs)
      );
      setFieldOptions((prev) => ({
        ...prev,
        LabTestList: LabTestList.payload.labs,
      }));
    }

    if (MedicalTestCategoryList?.payload?.medical_test_categories?.length > 0) {
      localStorage.setItem(
        "MedicalTestCategoryList",
        JSON.stringify(MedicalTestCategoryList.payload.medical_test_categories)
      );
      setFieldOptions((prev) => ({
        ...prev,
        MedicalTestCategoryList:
          MedicalTestCategoryList?.payload?.medical_test_categories,
      }));
    }
  }, [MedicalTestCategoryList, LabTestList]);

  const [fieldOptions, setFieldOptions] = useState({
    LabTestList: JSON.parse(localStorage.getItem("LabTestList")) || [],
    MedicalTestCategoryList:
      JSON.parse(localStorage.getItem("MedicalTestCategoryList")) || [],
  });

  // console.log(fieldOptions)

  const [formState, setFormState] = useState({
    name: "",
    code: "",
    lab_id: "",
    medical_test_category_id: "",
    female_min: "",
    male_min: "",
    female_max: "",
    male_max: "",
    unit: "",
    critical: "",
    sorting_order: "",
    amount: "",
    discount: "",
    cost_to_lab: "",
    number_of_films: "",
    composite: false,
    composite_fields: [],
  });
  const [formattedMedicalTestOptions, setFormattedMedicalTestOptions] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  const [errors, setErrors] = useState({});


  let debounceMedicalTest = useDebounce(searchTerm, 200);
  const { data: medicalTestDataList } = UseGetallMedicalTestAndComposite("1", "10", debounceMedicalTest);

  // const AllMedicalTestOptions =
  //   medicalTestDataList?.payload?.medical_tests || [];
  // const formattedMedicalTestOptions = AllMedicalTestOptions?.map((el) => ({
  //   value: el?.id?.toString(),
  //   label: el?.name,
  // }));

  // useEffect(() => {
  //   const baseOptions = medicalTestDataList?.payload?.medical_tests?.map((el) => ({
  //     value: el?.id?.toString(),
  //     label: el?.name,
  //   })) || [];

  //   let options = baseOptions;

  //   if (medicalTestData?.composite_fields?.length) {
  //     const additionalOptions = medicalTestData.composite_fields.map((el) => ({
  //       value: el?.id?.toString(),
  //       label: el?.name,
  //     }));
  //     options = [...baseOptions, ...additionalOptions];
  //   }

  //   setFormattedMedicalTestOptions(options);
  // }, [medicalTestDataList, medicalTestData]);

  useEffect(() => {
    // Start with the previously formatted options to ensure we preserve previous search results
    const baseOptions = formattedMedicalTestOptions || [];

    // Add new options from the search result (API response)
    const newOptions = medicalTestDataList?.payload?.medical_tests?.map((el) => ({
      value: el?.id?.toString(),
      label: el?.name,
    })) || [];

    // Merge the new options with the previously saved ones
    let options = [...baseOptions, ...newOptions];

    // If composite_fields are available, add them as well
    if (medicalTestData?.composite_fields?.length) {
      const additionalOptions = medicalTestData.composite_fields.map((el) => ({
        value: el?.id?.toString(),
        label: el?.name,
      }));
      options = [...options, ...additionalOptions];
    }

    const uniqueOptions = [
      ...new Map(options.map((item) => [item.value, item])).values(),
    ];

    // Set the combined options to state
    setFormattedMedicalTestOptions(uniqueOptions);

  }, [medicalTestDataList, medicalTestData]); // Dependencies for re-running the effect



  //handle form change

  // console.log(formattedMedicalTestOptions, 'formattedMedicalTestOptions')
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({ ...prev, [name]: undefined }));
  };

  const handleChange = (name, value) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const validateForm = () => {
    const errors = {};

    // Check required fields in formState
    if (!formState?.name) {
      errors.name = "Name is required";
    }
    if (!formState?.code) {
      errors.code = "code  is required";
    }
    if (!formState.amount) {
      errors.amount = "Amount is required";
    }
    if (!formState.lab_id) {
      errors.lab_id = " Please Select Lab";
    }


    setErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const handleCreateMedicalTest = async (e) => {

    if (!validateForm()) {
      return;
    }

    e.preventDefault();
    const payload = {
      medical_test: formState,
    };
    try {
      const result = await putUpdateMedicalTest(payload, medicalTestData?.id);
      if (result?.status?.status === "SUCCESS") {
        showToast(
          result?.status?.message || result?.status?.status,
          result?.status?.status
        );
        refetch();
        onClose();
        setFormState({});
      }
      else {
        showToast(
          result?.status?.message || result?.status?.status,
          result?.status?.status
        );
      }
    } catch (error) {
      showToast(error.message, "ERROR");
    }
  };
  useEffect(() => {
    if (medicalTestData) {
      console.log(medicalTestData);
      setFormState((prev) => ({
        ...prev,
        name: medicalTestData?.name,
        code: medicalTestData?.code,
        lab_id: medicalTestData?.lab_id,
        medical_test_category_id: medicalTestData?.medical_test_category_id,
        female_min: medicalTestData?.female_min,
        male_min: medicalTestData?.male_min,
        female_max: medicalTestData?.female_max,
        male_max: medicalTestData?.male_max,
        unit: medicalTestData?.unit,
        critical: medicalTestData?.critical,
        sorting_order: medicalTestData?.sorting_order,
        amount: medicalTestData?.amount,
        discount: medicalTestData?.discount,
        cost_to_lab: medicalTestData?.cost_to_lab,
        number_of_films: medicalTestData?.number_of_films,
        composite: medicalTestData?.composite,
        composite_fields: medicalTestData?.composite_fields?.map((el) =>
          el?.id?.toString()
        ),
      }));
      // setSelectedTestOption(medicalTestData?.composite_fields || []);
    }
  }, [medicalTestData]);
  //   console.log(formState);
  //   console.log(medicalTestData);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: "90vh",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
        //   sx={{
        //     overflowY: "auto",
        //     maxHeight: "80vh",
        //     "&::-webkit-scrollbar": {
        //       display: "none",
        //     },
        //     "-ms-overflow-style": "none",
        //     "scrollbar-width": "none",
        //   }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
          >
            <Box></Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ScienceTwoToneIcon
                sx={{
                  color: "neutral.navyBlue",
                  marginBottom: "4px",
                  marginRight: "8px",
                }}
              />
              <Typography
                variant="heading_Large"
                fontWeight={700}
                gutterBottom
                sx={headingStyle}
              >
                Update Medical Test
              </Typography>
            </Box>
            <IconButton sx={{ marginBottom: "2rem" }} onClick={onClose}>
              <CloseIcon size="small" sx={{ color: "red" }} />
            </IconButton>
          </Box>

          <Grid container spacing={2} sx={{ marginBottom: "10px" }}>
            <Grid item sm={3}>
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  required
                  onChange={handleFormChange}
                  value={formState?.name}
                  type="text"
                  name="name"
                  sx={autoFocusOffWithOutBorder}
                  placeholder="Name"
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </FormControl>
            </Grid>

            <Grid item sm={3}>
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  required
                  disabled
                  onChange={(e) => {
                    const valueUpp = e.target.value?.toUpperCase(); // Convert input to uppercase
                    handleChange("code", valueUpp); // Update form state with uppercase value
                  }}
                  // onChange={handleFormChange}
                  value={formState?.code}
                  type="text"
                  name="code"
                  sx={autoFocusOffWithOutBorder}
                  placeholder="Code*"
                  error={!!errors.code}
                  helperText={errors.code}
                />
              </FormControl>
            </Grid>

            <Grid item sm={3}>
              <Autocomplete
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "15px",
                    border: "none", // Remove border
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border on hover
                    borderRadius: "15px",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "15px",
                    border: "none", // Ensure border remains none on focus
                  },
                  "& .MuiInputBase-input": {
                    padding: "2px 8px",
                  },
                  "& .MuiAutocomplete-inputRoot": {
                    padding: "2px 8px", // Remove extra padding around input
                  },
                  // padding:'0px',
                  borderRadius: "15px",
                  backgroundColor: "#F7F7FF",
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                  },
                }}
                options={fieldOptions?.LabTestList || []}
                getOptionLabel={(option) => option.name || ""}
                value={
                  fieldOptions?.LabTestList?.find(
                    (test) => test.id === formState.lab_id
                  ) || null
                }
                onChange={(e, newValue) => {
                  handleFormChange({
                    target: {
                      name: "lab_id",
                      value: newValue ? newValue.id : "",
                    },
                  });
                  handleFormChange({
                    target: {
                      name: "lab_id",
                      value: newValue.id || "",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Lab"
                    error={!!errors.lab_id}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem
                    {...props}
                    key={option.id}
                    value={option.id}
                    sx={menuItemStyle}
                  >
                    {option.name}
                  </MenuItem>
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
              {errors.lab_id && (
                <Typography color="error">{errors.lab_id}</Typography>
              )}
            </Grid>

            <Grid item sm={3}>
              <Autocomplete
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "15px",
                    border: "none", // Remove border
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border on hover
                    borderRadius: "15px",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "15px",
                    border: "none", // Ensure border remains none on focus
                  },
                  "& .MuiInputBase-input": {
                    padding: "2px 8px",
                  },
                  "& .MuiAutocomplete-inputRoot": {
                    padding: "2px 8px", // Remove extra padding around input
                  },
                  // padding:'0px',
                  borderRadius: "15px",
                  backgroundColor: "#F7F7FF",
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                  },
                }}
                options={fieldOptions?.MedicalTestCategoryList || []}
                getOptionLabel={(option) => option.name || ""}
                value={
                  fieldOptions?.MedicalTestCategoryList?.find(
                    (category) =>
                      category?.id === formState.medical_test_category_id
                  ) || null
                }
                onChange={(e, newValue) => {
                  handleFormChange({
                    target: {
                      name: "medical_test_category_id",
                      value: newValue ? newValue.id : "",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Medical Test Category"
                    error={!!errors.medical_test_category_id}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem
                    {...props}
                    key={option.id}
                    value={option.id}
                    sx={menuItemStyle}
                  >
                    {option.name}
                  </MenuItem>
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
              {errors.medical_test_category_id && (
                <Typography color="error">
                  {errors.medical_test_category_id}
                </Typography>
              )}
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                // label="Bill number"
                //   InputLabelProps={{
                //     shrink: true,
                //   }}
                onChange={handleFormChange}
                value={formState.female_min}
                name="female_min"
                placeholder="Female Min "
                type="number"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.female_min}
                helperText={errors.female_min}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                // label="Bill number"
                //   InputLabelProps={{
                //     shrink: true,
                //   }}
                onChange={handleFormChange}
                value={formState.female_max}
                name="female_max"
                placeholder="Female Max "
                type="number"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.female_max}
                helperText={errors.female_max}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                // label="Bill number"
                //   InputLabelProps={{
                //     shrink: true,
                //   }}
                onChange={handleFormChange}
                value={formState.male_min}
                name="male_min"
                placeholder="Male Min "
                type="number"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.male_min}
                helperText={errors.male_min}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                // label="Bill number"
                //   InputLabelProps={{
                //     shrink: true,
                //   }}
                onChange={handleFormChange}
                value={formState.male_max}
                name="male_max"
                placeholder="Male Max "
                type="text"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.male_max}
                helperText={errors.male_max}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                // label="Bill number"
                //   InputLabelProps={{
                //     shrink: true,
                //   }}
                onChange={handleFormChange}
                value={formState.unit}
                name="unit"
                placeholder="Unit"
                type="text"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.unit}
                helperText={errors.unit}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                // label="Bill number"
                //   InputLabelProps={{
                //     shrink: true,
                //   }}
                onChange={handleFormChange}
                value={formState.critical}
                name="critical"
                placeholder="Critical  "
                type="text"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.critical}
                helperText={errors.critical}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                // label="Bill number"
                //   InputLabelProps={{
                //     shrink: true,
                //   }}
                onChange={handleFormChange}
                value={formState.sorting_order}
                name="sorting_order"
                placeholder="Sorting  Order "
                type="text"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.sorting_order}
                helperText={errors.sorting_order}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                // label="Bill number"
                //   InputLabelProps={{
                //     shrink: true,
                //   }}
                onChange={handleFormChange}
                value={formState.amount}
                name="amount"
                placeholder="Amount "
                type="number"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.amount}
                helperText={errors.amount}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                // label="Bill number"
                //   InputLabelProps={{
                //     shrink: true,
                //   }}
                onChange={handleFormChange}
                value={formState.discount}
                name="discount"
                placeholder="Discount "
                type="number"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.discount}
                helperText={errors.discount}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                // label="Bill number"
                //   InputLabelProps={{
                //     shrink: true,
                //   }}
                onChange={handleFormChange}
                value={formState.cost_to_lab}
                name="cost_to_lab"
                placeholder="Cost to lab "
                type="number"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.cost_to_lab}
                helperText={errors.cost_to_lab}
              />
            </Grid>

            <Grid item sm={3}>
              <TextField
                fullWidth
                // label="Bill number"
                //   InputLabelProps={{
                //     shrink: true,
                //   }}
                onChange={handleFormChange}
                value={formState.number_of_films}
                name="number_of_films"
                placeholder="No of films "
                type="number"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.number_of_films}
                helperText={errors.number_of_films}
              />
            </Grid>

            <Grid sx={{ zIndex: 99999 }} item sm={3}>
              <Select
                options={formattedMedicalTestOptions}
                value={formState?.composite_fields?.map((test) => ({
                  value: test,
                  label: formattedMedicalTestOptions.find(
                    (option) => option.value == test
                  )?.label,
                }))}
                // st={{ width: "100%" }}
                isMulti
                isSearchable
                placeholder="Composite Fields"
                className="react-select"
                classNamePrefix="select"
                onInputChange={(value) => setSearchTerm(value)}
                onChange={(selectedOptions) => {
                  // Convert selectedOptions to an array of values
                  const selectedValues = Array.isArray(selectedOptions)
                    ? selectedOptions.map((option) => option.value)
                    : [];

                  handleChange("composite_fields", selectedValues); // Update formState with values only
                }}
                styles={{
                  // Menu z-index to avoid overlap issues
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999999,
                    fontFamily: 'Outfit',
                  }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 999999,
                    fontFamily: 'Outfit',
                  }),
                  // Control styles for the Select input box
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "#2e37a4"
                      : "rgba(46, 55, 164, 0.1)",
                    boxShadow: state.isFocused ? "0 0 0 1px #2e37a4" : "none",
                    "&:hover": {
                      borderColor: "#2e37a4",
                    },
                    backgroundColor: "#F7F7FF",
                    borderRadius: "15px",
                    fontSize: "14px",
                    minHeight: "45px",
                    fontFamily: "Outfit",
                    zIndex: 999999,
                  }),
                  // Dropdown indicator for animation and styling
                  dropdownIndicator: (base, state) => ({
                    ...base,
                    fontFamily: 'Outfit',
                    transform: state.selectProps.menuIsOpen
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "transform 250ms ease",
                    width: "35px",
                    height: "35px",
                  }),
                }}
              />
            </Grid>

            <Grid item sm={3}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={formState?.composite || false}
                  name="composite"
                  onChange={(e) =>
                    setFormState((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.checked,
                    }))
                  }
                />
                <Typography sx={{ marginTop: "5px" }}>Composite</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          container
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ zIndex: "10" }}
          lg={12}
          marginBottom={"5px"}
        >
          <Grid item></Grid>
          <Grid item>
            <Button
              onClick={handleCreateMedicalTest}
              className="btn draw-border"
              variant="contained"
              id="submit"
              sx={{
                ...primaryBtnBlue,
                width: "195px",
                display: "flex",

                alignItems: "center",
                justifyContent: "center",
              }}
            //   disabled={isLoading}
            >
              <Typography
                textTransform={"capitalize"}
                color={"neutral.white"}
                variant="title1_medium"
              >
                Update Medical Test
              </Typography>
            </Button>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default EditMedicalTestModal;

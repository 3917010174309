import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Popper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import {
  buttonWrapper,
  menuItemStyle,
  primaryBtn,
  tableCellStyle,
  textfieldText,
} from "../../style/AppointmentStyle";
import TextView from "../UI/TextView/TextView";
import { nurseForm } from "../../utils/constant";
import UseFetchOptions from "../../helper/UseFetchOptions";
import UseFetchDoctorList from "../../helper/UseFetchDoctorList";
import { UseFetchSlots } from "../../helper/UseFetchSlots";
import {
  addOptionInAppointForm,
  updateMedicalTest,
} from "../../connections/postApi";
import { orangeButton, primaryBtnBlue, textfieldTextBlueBorder, textfieldTextPrep } from "../../style/PatientDetailsStyle";
import {
  setAppointmentData,
  setPatientDetailsData,
  setSearchPatient,
} from "../../redux/slices/nurseSlice";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../hooks/UseDebounce";
import UsePatientDetail from "../../helper/UsePatientDetail";
import UseSearchPatient from "../../helper/UseSearchPatient";
import { drTableRow } from "../../style/DoctorListComponent";
import { showToast } from "../../utils/toastUtil";
import {
  saveOldPatientAppointment,
  updatePatientAppointment,
} from "../../connections/putApi";
import UseFetchReferDoctor from "../../helper/UseFetchReferDoctor";
import { tableRowLab, xBtn } from "../../style/LapPageStyle";
import CustomModal from "../CustomModal/CustomModal";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Referrer from "../ModalChildren/Referrer";
import UseFetchAppointmentDetailsPatient from "../../helper/UseFetchAppointmentDetailsPatient";
import { appointmentDetail } from "../../connections/getApi";
import { autoFocusOffWithOutBorder } from "../../style/PatinetFormStyle";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import UseFetchInvestigationList from "../../helper/UseFetchInvestigationList";
import UseGetQuickCheckReports from "../../helper/UseGetQuickCheckReports";

const PatientReport = () => {
  const [allFormFields, setAllFormFields] = useState(nurseForm);
  const [formState, setFormState] = useState({});
  const [newValues, setNewValues] = useState({});
  const [showNewValueField, setShowNewValueField] = useState({});
  const [fieldOptions, setFieldOptions] = useState({});
  const [openSearchDropDown, setOpenSearchDropDown] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const [resetSlot, setResetSlot] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [newSlotOption, setNewSlotOption] = useState({});
  const [errors, setErrors] = useState({});
  const [drConsultId, setDrConsultId] = useState("");


  const {
    nurse: { patientData, patientDetailsData, appointmentData, isLoading },
  } = useSelector((state) => state);
  // hooks to get data
  //  console.log(appointmentData)


  const dispatch = useDispatch();
  const { data: allOccupation, refetch: refetchOccupation } =
    UseFetchOptions("occupations");
  const { data: allCities, refetch: refetchCities } = UseFetchOptions("cities");
  const { data: allEducations, refetch: refetchEducation } =
    UseFetchOptions("educations");
  const { data: investigationList, isLoading: isLoadingInvestigation } = UseFetchInvestigationList(drConsultId);

  const { data: patientDetailsQuery } = UsePatientDetail(patientId);
  const { data: patientAppointmentQuery } = UseFetchAppointmentDetailsPatient(
    patientDetailsQuery?.payload?.appointments[0]?.id
  );
  const { data: quickCheckTestReports } = UseGetQuickCheckReports(patientDetailsData?.id,
    patientDetailsData?.appointments && patientDetailsData?.appointments[0]?.id)


  const { data: referDr } = UseFetchReferDoctor();
  // Initialize fieldOptions with the initial options from appointmentForm

  // set education occupation and cities option in fields
  useEffect(() => {
    const newReferDr = referDr?.payload?.referrers?.map((refer) => ({
      id: refer?.id,
      title: `${refer?.full_name || ""}${refer?.profession ? ` [${refer?.profession}]` : ""
        }${refer?.qualification ? ` [${refer?.qualification}]` : ""}${refer?.city ? `- ${refer?.city}` : ""
        }${refer?.phone ? ` - ${refer?.phone}` : ""}`,
      value: refer?.full_name,
      email: refer?.email,
      phone: refer?.phone,
      address: refer?.address,
    }));
    const newAllCities = allCities?.cities?.map((el) => ({
      id: el?.id,
      title: el?.name,
      value: el?.name,
    }));
    setFieldOptions((prev) => ({
      ...prev,
      occupation: allOccupation?.occupations,
      education: allEducations?.educations,
      city: newAllCities,
      referrer: newReferDr,
    }));
  }, [allCities, allEducations, allOccupation, referDr]);
  // //set doctor and slot option

  const saveReport = async () => {

    if (!patientDetailsData?.id) {
      showToast('Please select a patient first.', 'ERROR')
      return;
    }

    if (!appointmentData?.id) {
      showToast("Seems the patient doesn't have any appointment.", 'ERROR')
      return;
    }

    if (investigationArr.length === 0) {
      showToast("No investigations available to save report , select first .", 'ERROR');
      return;
    }

    // Filter out items with falsy id or name values
    const validInvestigations = investigationArr.filter(
      (investigation) => investigation.id || investigation.name
    );

    const reports = validInvestigations.map((investigation) => ({
      id: investigation.id,
      value: investigation?.value || "",
    }));


    // const reports = investigationArr?.map((investigation) => ({
    //   id: investigation.id,
    //   value: investigation?.value || "",
    // }));

    const data = {
      reports,
      patient_id: patientDetailsData.id,
      appointment_id: appointmentData.id,
      state: "green",
    };
    let result = await updateMedicalTest(data);
    console.log(result);
    showToast(result.status.message || result.status.status, result.status.status);
  };

  const handleFormChange = (event, value, name) => {
    let fieldName;

    if (event) {
      fieldName = event.target.name;
      const { name: eventName, value: eventValue } = event.target;
      setFormState((prevState) => ({
        ...prevState,
        [eventName]: eventValue,
      }));
    } else if (name) {
      if (value?.value === "other") {
        setShowNewValueField((prev) => ({ ...prev, [name]: true }));
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        setShowNewValueField((prev) => ({ ...prev, [name]: false }));
      }
    }
    setErrors((prev) => ({ ...prev, [fieldName]: undefined }));
  };

  // handle check box
  const handleCheckBox = (field) => {
    const currentValue = formState[field];
    const newValue = currentValue === false ? true : false;
    setFormState((prevState) => ({ ...prevState, [field]: newValue }));
  };

  //add new value to occupation education and cities also post req
  const handleAddNewValue = async (name) => {
    if (newValues[name]) {
      const newValue = newValues[name];

      let newObj = {
        id: `${Math.floor(Math.random() * 90) + 10}${newValue}`,
        value: newValue,
        title: newValue,
      };
      await addOptionInAppointForm({ value: newValue }, name);
      if (name === "occupation") {
        refetchOccupation();
      } else if (name === "education") {
        refetchEducation();
      } else {
        refetchCities();
      }
      setFormState((prevState) => ({
        ...prevState,
        [name]: newObj,
      }));
      // dispatch(setSelectedSlot({ date: newValue, slot: "" }));
      setFieldOptions((prevOptions) => ({
        ...prevOptions,
        [name]: [...(prevOptions[name] || []), newObj],
      }));
      setNewValues((prev) => ({ ...prev, [name]: "" }));
      setShowNewValueField((prev) => ({ ...prev, [name]: false }));
    }
  };

  const handleAddOrEditValue = (name, referrer) => {
    // console.log(name, referrer);
    referrer = {
      title: referrer.name + " - " + referrer.address + " - " + referrer.phone,
      value: referrer.name,
    };
    setFormState((prevState) => ({
      ...prevState,
      [name]: referrer,
    }));
  };

  const handleModal = (val) => {
    setOpenModal(val);
  };

  useEffect(() => {
    if (appointmentData || patientDetailsData) {
      const patientObj = {
        height: appointmentData?.height,
        weight: appointmentData?.weight,
        fever: appointmentData?.fever,
        bps: appointmentData?.bp_s,
        bpd: appointmentData?.bp_d,
        bmi: appointmentData?.bmi,
        smoking: appointmentData?.smoking,
        tobacco: appointmentData?.tobacco,
        alcohol: appointmentData?.alcohol,
        referrer: patientDetailsData?.appointments?.[0]?.referrer_id || null,
        ready_investigations: appointmentData?.ready_investigations,
        ready_investigations_prev: appointmentData?.ready_investigations,

      };
      setDrConsultId(appointmentData?.consultant?.id)
      // setInvestigationArr(
      //   appointmentData?.ready_investigations?.map((item) => ({
      //     id: item?.id,
      //     name: item?.name,
      //     value: item?.after_value,
      //     isDisabled: item?.after_value ? true : false,
      //   })) || []
      // );
      setFormState((prev) => ({ ...prev, ...patientObj }));
    }
  }, [patientDetailsData, appointmentData]);

  const [investigationArr, setInvestigationArr] = useState(quickCheckTestReports?.payload?.medical_test_reports?.map((item) => ({
    id: item?.medical_test_id,
    name: item?.test_name,
    value: item?.value,
    isDisabled: item?.value ? true : false,
  })) || []);

  useEffect(() => {
    if (quickCheckTestReports) {
      setInvestigationArr(
        quickCheckTestReports?.payload?.medical_test_reports?.map((item) => ({
          id: item?.medical_test_id,
          name: item?.test_name,
          value: item?.value,
          isDisabled: item?.value ? true : false,
        })) || []);
    }
  }, [quickCheckTestReports]);

  // console.log(quickCheckTestReports, 'quickCheckTestReports')
  // console.log(investigationArr)

  // console.log(appointmentData , 'appointmentData');
  // console.log(patientDetailsData , 'patientDetailsData');
  //  console.log(formState , 'formsState');

  // const handleInputChange = (event, newInputValue) => {

  //     // console.log(formS)

  // };

  const handleInputChange = (event, value, name) => {
    //  console.log("Event:", event, "Value:", value, "Name:", name);
    let fieldName;
    setInputValue(value);
    if (event) {
      const { name: eventName, value: eventValue } = event.target;
      fieldName = event.target.name;
      setFormState((prevState) => ({
        ...prevState,
        [eventName]: eventValue,
      }));
    } else if (name) {
      fieldName = name;
      if (value?.value === "other") {
        setFormState((prev) => ({ ...prev, [name]: true }));
      } else if (name === "referrer") {
        setFormState((prevState) => ({
          ...prevState,
          [name]: value,
        }));

        setInputValue(value);
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
    setErrors((prev) => ({ ...prev, [fieldName]: undefined }));
  };

  const handleUpdatePatient = async () => {
    // console.log(formState);

    // if (!validateForm(formState)) {
    //   return null;
    // }

    if (patientDetailsData?.id) {
      if (appointmentData?.id) {
        let data = {
          appointment: {
            consultant_id: formState?.consultant_id,
            doctor_id: formState?.doctor_id,
            appointment_slot_id: formState?.slot,
            referrer_id: formState?.referrer?.id || formState?.referrer || null,
            // state: "green",
            visit_date: formState?.visit_date,
            visit_type: formState?.visit_date,
            height: formState?.height,
            weight: formState?.weight,
            fever: formState?.fever,
            bp_s: formState?.bps,
            bp_d: formState?.bpd,
            bmi: formState?.bmi,
            smoking: formState?.smoking,
            tobacco: formState?.tobacco,
            alcohol: formState?.alcohol,
          },
        };
        let result = await updatePatientAppointment(data, appointmentData?.id);
        showToast(
          ` ${result?.status?.message || result?.status?.status} `,
          result?.status?.status
        );
        if (result?.status?.status) {
          // dispatch(setPatientDetailsData({}));
          // dispatch(setAppointmentData({}));
          // setFormState({});
        }
      } else {
        showToast("patient does not have any appointment ", "ERROR");
      }
    } else {
      showToast("select patient", "ERROR");
    }
  };

  const handleInvestigationChange = (index, value) => {
    setFormState((prev) => ({
      ...prev,
      ready_investigations: prev.ready_investigations.map((item, i) =>
        i === index
          ? {
            ...item,
            after_value: value,
          }
          : item
      ),
    }));
  };

  const handleAddTest = () => {
    setInvestigationArr([
      ...investigationArr,
      {
        id: '',
        name: "",
        value: "",
        isDisabled: false,
      },
    ]);
  }

  const handleDeleteTest = (index) => {
    const newInvestigationArr = investigationArr.filter((_, i) => i !== index);
    setInvestigationArr(newInvestigationArr);
  };

  const handleSelectTest = (index, newValue) => {
    setInvestigationArr((prev) => {
      const updatedArr = [...prev];
      updatedArr[index] = {
        id: newValue.value,
        name: newValue.label,
      };
      return updatedArr;
    });
  }

  const handleTestValueChange = (index, newValue) => {
    console.log(index, newValue, 'index , newValue')
    setInvestigationArr((prev) => {
      const updatedArr = [...prev];
      // Update only the value field for the specified index
      updatedArr[index] = {
        ...updatedArr[index],
        value: newValue || "", // Use an empty string if newValue is null/undefined
      };
      return updatedArr;
    });
  };

  const renderField = (field) => {
    switch (field.field) {
      case "Autocomplete1":
        // return (
        //   <>
        //     {/* {console.log(formState[field.name])} */}
        //     <Autocomplete
        //       options={fieldOptions[field.name] || field.options}
        //       clearIcon={false}
        //       popupIcon={false}
        //       getOptionLabel={(option) =>
        //         option.title && option.value
        //           ? `${option.title} - ${option.value}`
        //           : ""
        //       }
        //       // value={formState[field.name] || ""}
        //       value={
        //         (formState[field.name] &&
        //           (fieldOptions[field.name] || field?.options)?.find(
        //             (option) =>
        //               option.value ===
        //                 (formState[field.name]?.value ||
        //                   formState[field.name]) ||
        //               option.title ===
        //                 (formState[field.name]?.title ||
        //                   formState[field.name]) ||
        //               option.id === formState[field.name]
        //           )) ||
        //           formState[field.name]
        //       }
        //       // value={newValues[field.name] || ""}
        //       onChange={(event, newValue) =>
        //         handleFormChange(null, newValue, field.name)
        //       }
        //       renderOption={(props, option, { index }) => (
        //         <MenuItem
        //           {...props}
        //           sx={{
        //             whiteSpace: "nowrap",
        //             overflow: "hidden",
        //             textOverflow: "ellipsis",
        //             display: "flex",
        //             justifyContent: "space-between",
        //             width: "100%",
        //             // "&:hover": {
        //             //   backgroundColor: "#0067FF40 !important",
        //             //   color: "white !important",
        //             //   borderRadius:'16px',
        //             // },

        //             ...menuItemStyle,
        //             "&:hover": {
        //               backgroundColor: "#0067FF40 !important",
        //               borderRadius: "16px",
        //               color: "black !important",
        //             },
        //             ...(index === 0 && {
        //               backgroundColor: "#0067FF40 !important",
        //               color: "black !important",
        //               borderRadius:"16px",
        //             }),

        //           }}
        //         >
        //           {option.title} - {option.value}
        //         </MenuItem>
        //       )}

        //       renderInput={(params) => (
        //         <TextField
        //           {...params}
        //           placeholder={field.placeholder}
        //           variant={field.variant}
        //           fullWidth

        //             sx={{

        //               // maxHeight: 80,
        //               "& .MuiOutlinedInput-root": {
        //                 padding: "1px",
        //                 paddingX:'8px',
        //                 height: "auto",
        //                  borderRadius: "10px",
        //                  backgroundColor:'#F7F7FF',
        //               },
        //               "& .MuiOutlinedInput-notchedOutline": {
        //                 borderColor: "#ccc",
        //               },
        //               "&:hover .MuiOutlinedInput-notchedOutline": {
        //                 borderColor: "#ccc",
        //               },
        //               "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        //                 borderColor: "#ccc",
        //               },
        //               "&:hover": {
        //                 boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
        //                 borderRadius: "10px",

        //               },
        //               "& .MuiInputBase-input": {
        //                   padding: "0px 4PX",
        //                },
        //                "& fieldset": {
        //                 border: "none", // Remove default border
        //                },
        //                "&:hover fieldset": {
        //                  border: "none", // Remove border on hover
        //                },
        //             }}

        //           InputProps={{
        //             ...params.InputProps,
        //             endAdornment: (
        //               <div style={{ display: "flex", alignItems: "center", justifyContent:"flex-end" }}>
        //               {params.InputProps.endAdornment}
        //               <IconButton
        //                 onClick={() => handleModal(true)}
        //                 sx={{ marginLeft: "8px" }}
        //               >
        //                 <EditNoteIcon />
        //               </IconButton>
        //             </div>
        //             ),
        //           }}
        //         />
        //       )}
        //     />

        //     <CustomModal
        //       open={openModal}
        //       onClose={() => setOpenModal(false)}
        //     >
        //       <Referrer
        //         selectedReferrer={formState["referrer"]}
        //         setOpenModal={setOpenModal}
        //         onSave={handleAddOrEditValue}
        //       />
        //     </CustomModal>
        //   </>
        // );
        return (
          <>
            {/* {console.log(formState[field.name])} */}
            <Autocomplete
              options={fieldOptions[field.name] || field.options}
              clearIcon={false}
              popupIcon={false}
              getOptionLabel={(option) =>
                option.title && option.value ? `${option.title} ` : ""
              }
              // value={formState[field.name] || ""}
              value={
                (formState[field.name] &&
                  (fieldOptions[field.name] || field.options)?.find(
                    (option) =>
                      option.value ===
                      (formState[field.name]?.value ||
                        formState[field.name]) ||
                      option.title ===
                      (formState[field.name]?.title ||
                        formState[field.name]) ||
                      option.id === formState[field.name]
                  )) ||
                formState[field.name]
              }
              onChange={(event, newValue) =>
                // handleInputChange( field.name , newValue)
                // handleFormChange(event, newValue ,field.name)
                handleInputChange(null, newValue, field.name)
              }
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  sx={{
                    maxHeight: "578.51px",
                    overflowY: "auto",
                    minWidth: "400px",
                    zIndex: 1300,
                  }}
                  placement="bottom-start"
                />
              )}
              renderOption={(props, option, { index }) => (
                <MenuItem
                  {...props}
                  sx={{
                    ...menuItemStyle,
                    "&:hover": {
                      backgroundColor: "#0067FF40 !important",
                      borderRadius: "16px",
                      color: "black !important",
                    },
                    ...(index === 0 && {
                      backgroundColor: "#0067FF40 !important",
                      color: "black !important",
                      borderRadius: "16px",
                    }),
                  }}
                >
                  {option.title}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={field.placeholder}
                  variant={field.variant}
                  fullWidth
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Outfit",
                    transition: "all 0.4s ease-in-out",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "15px",
                      height: "40px",
                      padding: "9px !important",
                      "& input": {
                        padding: "4px",
                        fontSize: "14px",
                      },
                    },
                    "& .MuiInputBase-root": {
                      backgroundColor: "#F7F7FF",
                      transition: "background-color 0.3s",
                    },
                    "& .MuiInputBase-root.Mui-focused": {
                      backgroundColor: "#F7F7FF",
                      boxShadow:
                        // "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
                        "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
                    },
                    "& fieldset": {
                      border: "none", // Remove default border
                    },
                    "&:hover fieldset": {
                      border: "none", // Remove border on hover
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params?.InputProps?.endAdornment}
                        <IconButton onClick={() => handleModal(true)}>
                          <EditNoteIcon />
                        </IconButton>
                      </>
                    ),
                  }}
                />
              )}
            />

            <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
              <Referrer
                // selectedReferrer={formState["referrer"]}
                selectedReferrer={formState["referrer"]}
                setOpenModal={setOpenModal}
                onSave={handleAddOrEditValue}
              />
            </CustomModal>
          </>
        );
      default:
        return null;
    }
  };

  // console.log(investigationArr, 'investigationArr')
  // console.log(appointmentData?.ready_investigations, 'ready_investigations')
  return (
    <>
      <Grid container marginBottom={"auto"} lg={3.8} md={3.8} sm={5.5}>
        <Grid
          item
          sx={{
            padding: "1rem",
            paddingY: "22px",
            backgroundColor: "white",
            borderRadius: "20px",
          }}
        >
          {/* {"-----Patient Form----"}  */}
          <Grid container spacing={1.5}>
            {allFormFields
              ?.slice(allFormFields.length - 1, allFormFields.length)
              .map((field) => (
                <Grid item {...field.gridProps} key={field.id}>
                  {field.isTitle && (
                    <TextView variant="title_medium">{field.isTitle}</TextView>
                  )}
                  {renderField(field)}
                </Grid>
              ))}
          </Grid>

          <Grid
            item
            xs={12}
            sm={8}
            sx={{ marginTop: "2rem", marginBottom: "1rem" }}
          >
            <TextView variant={"heading2"}>Conditions</TextView>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                type="text"
                name="height"
                placeholder="Height (Ft 'In)"
                sx={{
                  padding: "0px",
                  // maxHeight: 80,
                  "& .MuiOutlinedInput-root": {
                    padding: "8px",
                    height: "auto",
                    borderRadius: "10px",
                    backgroundColor: "#F7F7FF",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                    borderRadius: "15px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "0px 4PX",
                  },
                  "& fieldset": {
                    border: "none", // Remove default border
                  },
                  "&:hover fieldset": {
                    border: "none", // Remove border on hover
                  },
                }}
                variant="outlined"
                value={formState?.height || ""}
                onChange={handleFormChange}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                placeholder="Weight"
                type="text"
                name="weight"
                sx={{
                  padding: "0px",
                  // maxHeight: 80,
                  "& .MuiOutlinedInput-root": {
                    padding: "8px",
                    height: "auto",
                    borderRadius: "10px",
                    backgroundColor: "#F7F7FF",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                    borderRadius: "15px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "0px 4PX",
                  },
                  "& fieldset": {
                    border: "none", // Remove default border
                  },
                  "&:hover fieldset": {
                    border: "none", // Remove border on hover
                  },
                }}
                variant="outlined"
                value={formState?.weight || ""}
                onChange={handleFormChange}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                type="text"
                name="fever"
                placeholder="Fever"
                sx={{
                  padding: "0px",
                  // maxHeight: 80,
                  "& .MuiOutlinedInput-root": {
                    padding: "8px",
                    height: "auto",
                    borderRadius: "10px",
                    backgroundColor: "#F7F7FF",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                    borderRadius: "15px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "0px 4PX",
                  },
                  "& fieldset": {
                    border: "none", // Remove default border
                  },
                  "&:hover fieldset": {
                    border: "none", // Remove border on hover
                  },
                }}
                variant="outlined"
                value={formState?.fever || ""}
                onChange={handleFormChange}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                placeholder="BP S"
                type="text"
                name="bps"
                sx={{
                  padding: "0px",
                  // maxHeight: 80,
                  "& .MuiOutlinedInput-root": {
                    padding: "6px",
                    height: "auto",
                    borderRadius: "10px",
                    backgroundColor: "#F7F7FF",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                    borderRadius: "15px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "0px 4PX",
                  },
                  "& fieldset": {
                    border: "none", // Remove default border
                  },
                  "&:hover fieldset": {
                    border: "none", // Remove border on hover
                  },
                }}
                variant="outlined"
                value={formState?.bps || ""}
                onChange={handleFormChange}
              />
            </Grid>

            <Grid item xs={12} sm={2.1}>
              <TextField
                fullWidth
                type="text"
                name="bpd"
                placeholder="BP D"
                sx={{
                  padding: "0px",
                  // maxHeight: 80,
                  "& .MuiOutlinedInput-root": {
                    padding: "6px",
                    height: "auto",
                    borderRadius: "10px",
                    backgroundColor: "#F7F7FF",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                    borderRadius: "15px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "0px 4PX",
                  },
                  "& fieldset": {
                    border: "none", // Remove default border
                  },
                  "&:hover fieldset": {
                    border: "none", // Remove border on hover
                  },
                }}
                variant="outlined"
                value={formState?.bpd || ""}
                onChange={handleFormChange}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                placeholder="BMI"
                type="text"
                name="bmi"
                sx={{
                  padding: "0px",
                  // maxHeight: 80,
                  "& .MuiOutlinedInput-root": {
                    padding: "8px",
                    height: "auto",
                    borderRadius: "10px",
                    backgroundColor: "#F7F7FF",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                    borderRadius: "15px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "0px 4PX",
                  },
                  "& fieldset": {
                    border: "none", // Remove default border
                  },
                  "&:hover fieldset": {
                    border: "none", // Remove border on hover
                  },
                }}
                variant="outlined"
                value={formState?.bmi || ""}
                onChange={handleFormChange}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                alignItems="center"
                mb={2}
                onClick={() => handleCheckBox("smoking")}
              >
                <Typography
                  variant="title1_medium"
                  sx={{ marginRight: -1, marginLeft: "20px" }}
                >
                  Smoking
                </Typography>

                <Checkbox name="smoking" checked={!!formState?.smoking} />
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                alignItems="center"
                marginLeft="20px"
                mb={2}
                onClick={() => handleCheckBox("tobacco")}
              >
                <Typography variant="title1_medium" sx={{ marginRight: -1 }}>
                  Tobacco
                </Typography>
                <Checkbox name="tobacco" checked={!!formState?.tobacco} />
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                alignItems="center"
                mb={2}
                sx={{ justifyContent: "flex-end", marginRight: "20px" }}
                onClick={() => handleCheckBox("alcohol")}
              >
                <Typography variant="title1_medium" sx={{ marginRight: -1 }}>
                  Alcohol
                </Typography>
                <Checkbox name="alcohol" checked={!!formState?.alcohol} />
              </Box>
            </Grid>
          </Grid>

          {/* {"Button For update"} */}
          <Box sx={buttonWrapper}>
            <Button
              onClick={handleUpdatePatient}
              variant="contained"
              sx={primaryBtn}
              disabled={isLoading}
            >
              Update Info
            </Button>
          </Box>
        </Grid>
        {/* {formState?.ready_investigations?.length > 0 && (
          <Grid
            item
            sx={{
              padding: "1rem",
              paddingY: "22px",
              marginTop: "2rem",
              backgroundColor: "white",
              borderRadius: "20px",
            }}
            sm={12}
          // md={5.4}
          // marginBottom={"auto"}
          // lg={3.8}
          >
            {/* <Grid
            item
            sx={{ border: "1px solid rgba(0, 0, 0, 0.125)", padding: "15px" }}
          > */}


        {/* <Grid container spacing={2}>
          {formState?.ready_investigations?.length > 0 &&
            formState?.ready_investigations?.map((investigation, index) => (
              <Grid item xs={6} sm={6} key={index}>
                <Typography variant="body1_text_bold">
                  {investigation?.name}
                </Typography>
                <Stack gap={2} direction={"row"}>

                  <TextField
                    sx={{
                      padding: "0px",
                      // maxHeight: 80,
                      "& .MuiOutlinedInput-root": {
                        padding: "8px",
                        height: "auto",
                        borderRadius: "10px",
                        backgroundColor: "#F7F7FF",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                      "&:hover": {
                        boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                        borderRadius: "15px",
                      },
                      "& .MuiInputBase-input": {
                        padding: "0px 4PX",
                      },
                      "& fieldset": {
                        border: "none", // Remove default border
                      },
                      "&:hover fieldset": {
                        border: "none", // Remove border on hover
                      },
                    }}
                    fullWidth
                    disabled={
                      formState?.ready_investigations_prev[index]
                        ?.after_value
                    }
                    placeholder={investigation?.name || 'value'}
                    value={investigation?.after_value}
                    onChange={(e) =>
                      handleInvestigationChange(index, e.target.value)
                    }
                    variant="outlined"
                  />
                </Stack>
              </Grid>
            ))}
        </Grid> */}
        {/* 
        {formState?.ready_investigations?.length > 0 && (
          <Box
            sm={12}
            lg={12}
            sx={{ minWidth: "30%", mt: 2, float: "right" }}
          >
            <Button
              variant="contained"
              xs={12}
              sm={12}
              sx={{ ...primaryBtn, width: "100%", marginTop: '18px' }}
              onClick={saveReport}
            //  disabled={isLoading}
            >
              <Typography variant="small_Text">Save</Typography>
            </Button>
          </Box>
        )}
      </Grid>
        )} */}


        <Grid
          item
          sx={{
            padding: "1rem",
            paddingY: "22px",
            marginTop: "2rem",
            backgroundColor: "white",
            borderRadius: "20px",
          }}
          sm={12}
        >


          <Grid
            container
            spacing={2}
            sx={{
              paddingX: '10px',

              justifyContent: "space-between",
              alignItems: "start",
            }}
          >

            <Grid
              sx={{
                paddingLeft: 0,

              }}
              item
              sm={6}
              lg={6}
            >
              <Typography marginLeft={'-10px'} variant="title1_medium" fontSize={'17px'}>Test Name</Typography>
            </Grid>

            <Grid
              sx={{
                paddingLeft: 0,
              }}
              item
              sm={5}
              lg={5}
            >
              <Typography variant="title1_medium" fontSize={'17px'}>Value</Typography>
            </Grid>


            <Grid
              sx={{
                paddingLeft: 0,
              }}
              item
              sm={1}
              lg={1}
            >
              <IconButton
                variant="contained"
                sx={{ ...orangeButton, marginLeft: "8px" }}
                onClick={() => handleAddTest()}
              >
                <AddIcon
                  sx={{
                    width: "25px",
                    color: "neutral.white",
                    margin: "0px",
                    height: "25px",
                    padding: "0px",
                  }}
                />
              </IconButton>
            </Grid>

            {/* row start  */}

            {investigationArr?.map((test, index) => (
              < Grid
                container
                spacing={2}
                key={index}
                sx={{
                  marginBottom: '7px',
                  paddingX: '0px',
                  marginTop: index === 0 ? "4px" : '',
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >

                <Grid
                  sx={{
                    paddingLeft: 0,
                  }}
                  item
                  sm={6}
                  lg={6}
                >
                  <Autocomplete
                    clearIcon={false}
                    // InputProps={{ readOnly: test?.isDisabled }}
                    disabled={test?.isDisabled}
                    sx={{
                      // padding: "4px",
                      "& .MuiOutlinedInput-root": {
                        padding: "6px",
                        height: '40px',

                        borderRadius: "10px",
                        backgroundColor: "#F7F7FF",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                      "&:hover": {
                        boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                        borderRadius: "15px",
                      },
                      "& .MuiInputBase-input": {
                        padding: "0px 4PX",
                      },
                      "& fieldset": {
                        border: "none", // Remove default border
                      },
                      "&:hover fieldset": {
                        border: "none", // Remove border on hover
                      },
                    }}
                    fullWidth
                    options={investigationList?.payload?.medical_tests
                      ?.filter(
                        (item) =>
                          !investigationArr?.some(
                            (inv) => inv?.id === item?.id
                          )
                      )
                      ?.map((item) => ({
                        value: item?.id,
                        label: item?.name,
                      })) || []}
                    getOptionLabel={(option) => option.label || option.name || test?.name} // Use the 'name' 
                    value={
                      (investigationList?.payload?.medical_tests || [])
                        ?.find(
                          (item) =>
                            item?.id === test?.id || item?.name === test?.name
                        ) || test?.name
                    }
                    onChange={
                      (event, newValue) =>
                        handleSelectTest(index, newValue)
                    }
                    PopperComponent={(props) => (
                      <Grid item lg={8}>
                        <Popper
                          {...props}
                          placement="bottom-start"
                          style={menuItemStyle}
                        />
                      </Grid>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Test"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    renderOption={(props, option) => (
                      <MenuItem
                        sx={{
                          width: '100%',
                          fontFamily: 'Outfit',
                          // textTransform:'capitalize',
                          textTransform: 'capitalize',
                          "&:hover": {
                            backgroundColor: "#0067FF40 !important",
                            borderRadius: "16px",
                            color: "black !important",
                          },
                        }}
                        {...props}
                        key={option.id}
                      >
                        {option.label}{" "}
                        {/* Display the 'name' of each test */}
                      </MenuItem>
                    )}
                  />
                </Grid>

                <Grid
                  sx={{
                    paddingLeft: 0,
                    marginBottom: "7px",
                  }}
                  item
                  sm={5}
                  lg={5}
                >
                  <TextField
                    value={test?.value}
                    placeholder=" Test value"
                    fullWidth
                    onChange={(event) =>
                      handleTestValueChange(index, event.target.value)
                    }
                    // InputProps={{ readOnly: test?.isDisabled }}
                    sx={{
                      // padding: "4px",
                      "& .MuiOutlinedInput-root": {
                        padding: "6px",
                        height: '40px',

                        borderRadius: "10px",
                        backgroundColor: "#F7F7FF",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                      "&:hover": {
                        boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                        borderRadius: "15px",
                      },
                      "& .MuiInputBase-input": {
                        padding: "0px 4PX",
                      },
                      "& fieldset": {
                        border: "none", // Remove default border
                      },
                      "&:hover fieldset": {
                        border: "none", // Remove border on hover
                      },
                    }}
                    disabled={test?.isDisabled}
                  />
                </Grid>

                <Grid
                  sx={{
                    paddingLeft: 0,
                  }}
                  item
                  sm={1}
                  lg={1}
                >
                  <IconButton
                    sx={{
                      // marginBottom: "7px",
                      display: test?.isDisabled ? 'none' : 'block',
                    }}
                    onClick={() =>
                      handleDeleteTest(index)
                    }
                  >
                    <CloseIcon
                      sx={{
                        ...xBtn,
                        width: "23px",
                        height: "23px",
                        borderRadius: "20%",

                      }}
                    />
                  </IconButton>
                </Grid>

              </Grid>
            ))}


            <Grid
              sx={{
                paddingLeft: 0,
              }}
              item
              sm={12}
              lg={12}
            >
              <Box
                sx={{ minWidth: "30%", mt: 1, float: "right", justifyContent: 'end' }}
              >
                <Button
                  variant="contained"
                  xs={12}
                  sm={12}
                  sx={{ ...primaryBtn, width: "100%", marginTop: '8px' }}
                  onClick={saveReport}
                // disabled={investigationArr?.length === 0}
                >
                  <Typography variant="small_Text">Save</Typography>
                </Button>
              </Box>
            </Grid>


          </Grid>
        </Grid>
      </Grid >
    </>
  );
};

export default PatientReport;

// old section  don't delete this comment
// import React, { useEffect, useState } from "react";
// import { Box, Grid, TextField, Button, Typography } from "@mui/material";
// import { primaryBtn, textfieldText } from "../../style/AppointmentStyle";
// import { useSelector } from "react-redux";
// import { updateMedicalTest } from "../../connections/postApi";
// import { showToast } from "../../utils/toastUtil";

// const PatientReport = () => {
//   const appointmentData  = useSelector((state)=> state.nurse.appointmentData);
//   const patientDetailsData  = useSelector((state)=> state.nurse.patientDetailsData);
//   const isLoading  = useSelector((state)=> state.nurse.isLoading);

//   useEffect(()=>{
//     setFormState(appointmentData?.quick_investigations)
//   },[appointmentData])

//   const[formState,setFormState]=useState(appointmentData?.quick_investigations ||[]);
//   // console.log(formState);

//   const handleInputChange = (index, value) => {
//     const updatedFormState = formState.map((investigation, i) =>
//       i === index ? { ...investigation, value } : investigation
//     );
//     setFormState(updatedFormState);
//   };

//   const saveReport= async()=>{
//     const reports = formState.reduce((acc, investigation) => {
//       acc[investigation.name] = investigation.value || "";
//       return acc;
//     }, {});

//     const data ={
//         reports,
//         patient_id:  patientDetailsData.id,
//         appointment_id : appointmentData.id,
//         state: "green",
//     }
//   let result = await updateMedicalTest(data);
//   console.log(result);
//   showToast('patient report update ' , 'SUCCESS');
//   }

//   return (
//     <Grid container sm={12} md={5.4} marginBottom={"auto"} lg={3.8}>
//       <Grid
//         item
//         sx={{ border: "1px solid rgba(0, 0, 0, 0.125)", padding: "15px" }}
//       >

//         <Grid container spacing={2}>
//          {formState?.length>1 && formState?.map((investigation , index)=>
//           <Grid item xs={6} sm={3} key={index}>
//           <Typography variant="body1_text_bold">{investigation.name}</Typography>
//           <TextField
//             sx={textfieldText}
//             fullWidth
//             placeholder={investigation.name}
//             value={investigation.value}
//             onChange={(e) => handleInputChange(index, e.target.value)}
//             variant="outlined"
//           />
//           </Grid>
//            )}
//      </Grid>

//        {formState?.length>1 &&  <Box sm={12} lg={12} sx={{width :'100%'}}>
//           <Button variant="contained" xs={12} sm={12} sx={{...primaryBtn , width:'100%'}}  onClick={saveReport}  disabled={isLoading}>
//             <Typography variant="small_Text" >Save</Typography>
//           </Button>
//        </Box>
//          }

//       </Grid>
//     </Grid>

//   );
// };

// export default PatientReport;

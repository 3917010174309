import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  IconButton,
  Checkbox,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import ReactToPrint from "react-to-print";
import { drTableRow } from "../../style/DrApotComponentStyle";
import { primaryBtn, textfieldText } from "../../style/AppointmentStyle";
import {
  collectBtn,
  headingStyle,
  labPageButton,
  startBtn,
  tableRowLab,
  xBtn,
} from "../../style/LapPageStyle";
import { Print } from "@mui/icons-material";
import ComponentToPrint from "../Lab/printContent";
import CloseIcon from "@mui/icons-material/Close";
import updownar from "../../assets/updownar.svg";
import { primaryBtnBlue } from "../../style/PatientDetailsStyle";

const ReportPrint = ({
  handlePostMedicalReportValue,
  setReportValue,
  reportValue,
  handleCollectSample,
  patientDataReport,
  onClose,
  handleAction,
  handleDeleteReportItem,
  selectedDate,
}) => {
  const { patientData, report } = patientDataReport;
  // console.log(patientDataReport ,'patientDataReport');
  const [allReports, setAllReports] = useState([]);
  const [checkedItems, setCheckedItems] = useState(allReports?.map(() => true));
  // const [renderCheck, setRenderCheck] = useState("");
  const handleCheckboxChange = (index) => {
    setCheckedItems((prevCheckedItems) =>
      prevCheckedItems.map((checked, i) => (i === index ? !checked : checked))
    );
    // setRenderCheck(new Date());
  };

  const filteredReportData = allReports.filter(
    (_, index) => checkedItems[index]
  );

  const printRef = useRef();
  // console.log(filteredReportData, "filteredReportData", checkedItems);
  useEffect(() => {
    // Combine all values from the object into a single array
    const filteredData = Object.fromEntries(
      Object.entries(report).filter(([key]) => key === selectedDate)
    );
    console.log(filteredData, selectedDate);
    const combinedReports = Object.values(filteredData).flat();
    // console.log(combinedReports, "combinedReports");
    setAllReports(combinedReports);
    setCheckedItems(combinedReports?.map(() => true));
  }, []);

  return (
    <Box sx={{ width: "80vw" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="heading_Large"
          fontWeight={700}
          gutterBottom
          sx={headingStyle}
        >
          Print Report
        </Typography>
        <IconButton sx={{ marginBottom: "2rem" }} onClick={onClose}>
          <CloseIcon size="small" sx={{ color: 'red' }} />
        </IconButton>
      </Box>

      <Grid
        container
        sx={{ justifyContent: "space-between", width: "100%", mt: "30px" }}
      >
        <Typography>Name: {patientData?.name}</Typography>
        <Typography>Patient ID: {patientData?.uid}</Typography>
        <Typography>Mobile: 1234567890</Typography>
        <Typography>Age :{patientData?.age}</Typography>
        <Typography>{patientData?.gender}</Typography>
      </Grid>

      <Grid container sm={12} md={12} marginBottom={"auto"} lg={12}>
        <Grid item xs={12}>
          <TableContainer sx={{ minHeight: "30vh", mt: 2, width: "100%" }}>
            <Table sx={{ width: "100%" }}>
              <TableHead
                sx={{
                  backgroundColor: "#f0f0f0",
                }}
                stickyHeader
              >
                <TableRow sx={{ py: "-5px" }}>
                  <TableCell sx={{ fontSize: "14px" }}>Name</TableCell>
                  <TableCell sx={{ fontSize: "14px" }}>Amount</TableCell>
                  <TableCell sx={{ fontSize: "14px" }}>
                    Generated Date
                  </TableCell>
                  <TableCell sx={{ fontSize: "14px" }}>
                    Critical Range
                  </TableCell>
                  <TableCell sx={{ fontSize: "14px" }}>Min-Max Range</TableCell>
                  <TableCell sx={{ fontSize: "14px" }}>Value/Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allReports?.map((report, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      sx={{
                        ...tableRowLab,
                        borderTop: "2px solid white",
                        borderBottom: "8px solid white",
                      }}
                    >
                      <TableCell
                        sx={{
                          padding: "4px",
                          paddingLeft: "15px",
                          borderTopLeftRadius: "20px",
                          borderBottomLeftRadius: "20px",
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: "bold", fontSize: "12px" }}
                        >
                          <Checkbox
                            sx={{
                              height: "5px",
                              width: "5px",
                              marginRight: "4px",
                            }}
                            // defaultChecked={true}
                            checked={checkedItems[index]}
                            onChange={() => handleCheckboxChange(index)}
                          />
                          {report.name}
                        </Typography>
                        <Typography
                          variant="body_Grey"
                          sx={{ fontSize: "11px" }}
                        >
                          {report.name}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: "4px", paddingLeft: "15px" }}>
                        <Typography variant="body_Grey">
                          {report.amount}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: "4px", paddingLeft: "15px" }}>
                        <Typography variant="body_Grey">
                          {report?.generated_date}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: "4px", paddingLeft: "15px" }}>
                        <Typography> </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: "2px", paddingLeft: "15px" }}>
                        <Typography variant="body_Grey">
                          {report?.composites ? "" : report?.min_max_range}
                        </Typography>
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "2px",
                          paddingLeft: "15px",
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }}
                      >
                        {report?.composites ? ""
                          :
                          <Typography variant="body_Grey">
                            {report?.value === "Processing" ||
                              report?.value === "Done"
                              ? ""
                              : report?.value}
                          </Typography>

                        }
                      </TableCell>
                    </TableRow>

                    {report?.composites &&
                      report?.composites?.map((subReport, subIndex) => (
                        <TableRow
                          key={`${index}-${subIndex}`}
                          sx={{
                            ...tableRowLab,
                            borderTop: "2px solid white",
                            borderBottom: "8px solid white",
                          }}
                        >
                          <TableCell
                            sx={{
                              padding: "0px",
                              paddingLeft: "40px",
                              borderTopLeftRadius: "20px",
                              borderBottomLeftRadius: "20px",
                            }}
                          >
                            <Typography variant="body_Grey">
                              {subReport?.name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "6px",
                              paddingLeft: "16px",
                              height: "30px",
                            }}
                          >
                            {/* {report?.amount} */}
                          </TableCell>
                          <TableCell sx={{ padding: "0px" }}>
                            {subReport?.generated_date}
                          </TableCell>

                          <TableCell
                            sx={{ padding: "0px", textAlign: "center" }}
                          >
                            <Typography
                              variant="body_Grey"
                              sx={{ fontWeight: "bold" }}
                            >
                              {subReport.critical_range}
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{ padding: "0px", paddingLeft: "15px" }}
                          >
                            {/* <Typography
                              variant="body_Grey"
                              sx={{ fontWeight: "400", padding: "0px" }}
                            >
                              {patientData?.gender === "Female"
                                ? "Female Min-Max:"
                                : "Male Min-Max:"}
                            </Typography> */}
                            {report?.min_max_range}
                          </TableCell>

                          {/* <TableCell width={"190px"} sx={{ padding: "8px" ,  borderTopRightRadius:"20px" , borderBottomRightRadius:'20px' }}>
                            {subReport?.value === "Sample collecting" && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              >
                                <Button
                                  sx={{
                                    ...startBtn,
                                    paddingY: "2px",
                                    paddingX: "4px",
                                  }}
                                  onClick={() =>
                                    handleAction(index, index, subReport)
                                  }
                                >
                                  Start
                                </Button>
                                <IconButton
                                  onClick={() =>
                                    handleDeleteReportItem(subReport)
                                  }
                                >
                                  <CloseIcon
                                    sx={{
                                      ...xBtn,
                                      paddingY: "0px",
                                      paddingX: "2px",
                                    }}
                                  />
                                </IconButton>
                              </Box>
                            )}
                            {subReport?.value === null && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              >
                                <Button
                                  sx={{
                                    ...collectBtn,
                                    paddingY: "2px",
                                    paddingX: "4px",
                                  }}
                                  onClick={() =>
                                    handleCollectSample(index, index, subReport)
                                  }
                                >
                                  Collect Sample
                                </Button>
                                <IconButton
                                  onClick={() =>
                                    handleDeleteReportItem(subReport)
                                  }
                                >
                                  <CloseIcon
                                    sx={{
                                      ...xBtn,
                                      paddingY: "0px",
                                      paddingX: "2px",
                                    }}
                                  />
                                </IconButton>
                              </Box>
                            )}
                            {subReport?.value === "Processing" && (
                              <TextField
                                placeholder="Enter value"
                                // type="number"
                                sx={{ ...textfieldText }}
                                variant="outlined"
                                name="value"
                                value={reportValue[index]}
                                onChange={(e) => {
                                  setReportValue((prev) => ({
                                    ...prev,
                                    [index]: e.target.value,
                                  }));
                                }}
                                onBlur={() =>
                                  handlePostMedicalReportValue(subReport, index)
                                }
                              />
                            )}
                            {subReport?.value !== "Processing" &&
                              subReport?.value !== null &&
                              subReport?.value !== "Sample collecting" && (
                                <TextField
                                  placeholder="Enter value"
                                  // type="number"
                                  defaultValue={subReport?.value}
                                  sx={{ ...textfieldText }}
                                  variant="outlined"
                                  name="value"
                                  value={reportValue[index]}
                                  onChange={(e) => {
                                    setReportValue((prev) => ({
                                      ...prev,
                                      [index]: e.target.value,
                                    }));
                                  }}
                                  onBlur={() =>
                                    handlePostMedicalReportValue(
                                      subReport,
                                      index
                                    )
                                  }
                                />
                              )}
                          </TableCell> */}

                          <TableCell
                            sx={{ padding: "2px", paddingLeft: "15px" }}
                          >
                            <Typography variant="body_Grey">
                              {/* {report?.value} */}
                              {report?.value === "Processing" ||
                                report?.value === "Done"
                                ? ""
                                : report?.value}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid
        container
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ mt: 2 }}
        lg={12}
      >
        <Grid item></Grid>
        <Grid item>
          <ReactToPrint
            trigger={() => (
              <Button
                disabled={filteredReportData?.length < 1}
                variant="contained"
                sx={primaryBtnBlue}
              >
                <IconButton>
                  <Print
                    sx={{ height: "16px", width: "16px", color: "white" }}
                  />
                </IconButton>
                <Typography fontSize={"1.1rem"}>Print</Typography>
              </Button>
            )}
            content={() => printRef.current}
            pageStyle="@page { size: auto; margin: 6mm; }"
            copyStyles={true}
          />
        </Grid>
      </Grid>

      <ComponentToPrint
        ref={printRef}
        reportData={filteredReportData}
        patientData={patientData}
        selectedDate={selectedDate}
      />
    </Box>
  );
};

export default ReportPrint;

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  TextField,
  IconButton,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import {
  headingStyle,
  xBtn,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { textfieldTextBlueBr } from "../../style/PharmacyStyle";
import { orangeButton, primaryBtnBlue, textfieldTextBlueBorder } from "../../style/PatientDetailsStyle";
import { generateMedicine } from "../../connections/postApi";
import { showToast } from "../../utils/toastUtil";
import { autoFocusOffWithOutBorder, drTableRowBlueBg } from "../../style/PatinetFormStyle";
import medicines_icon from '../../assets/medicine.svg'
import UseFetchPackList from "../../helper/UseFetchPackList";
import UseFetchFormType from "../../helper/UseFetchFormType";
// import UseGetAllMedicineSaltList from "../../helper/UseGetAllMedicineSaltList";
import { useLocation } from "react-router-dom";
import { updateMedicine, updateMedicineBatch } from "../../connections/putApi";
import UseGetBatchDetail from "../../helper/UseGetBatchDetail";

const UpdateMedicineBatch = ({ onClose, refetch, medicineDetailsId }) => {

  const location = useLocation();
  const batchDetails = location.state || null;

  const { data: batchDetail } = UseGetBatchDetail(batchDetails.id);
  console.log(batchDetail, 'batchDetail')

  const { data: packList } = UseFetchPackList();
  const { data: formTypeList } = UseFetchFormType();
  // const { data: medicineSaltList } = UseGetAllMedicineSaltList();

  const [fieldOptions, setFieldOptions] = useState({
    formTypeList: JSON.parse(localStorage.getItem("formTypeList")) || [],
    // medicineSaltList: JSON.parse(localStorage.getItem("medicineSaltList")) || [],
    packList: JSON.parse(localStorage.getItem("packList")) || [],
  });

  useEffect(() => {

    if (packList?.payload?.packing?.length > 0) {
      localStorage.setItem(
        "packList",
        JSON.stringify(packList?.payload?.packing)
      );
      setFieldOptions((prev) => ({
        ...prev,
        packList: packList?.payload?.packing,
      }));
    }

    if (formTypeList?.payload?.form_types?.length > 0) {
      localStorage.setItem(
        "formTypeList",
        JSON.stringify(formTypeList?.payload?.form_types)
      );
      setFieldOptions((prev) => ({
        ...prev,
        formTypeList: formTypeList?.payload?.form_types,
      }));
    }

    // if (medicineSaltList?.payload?.medicine_salt?.length > 0) {
    //   localStorage.setItem(
    //     "medicineSaltList",
    //     JSON.stringify(medicineSaltList?.payload?.medicine_salt)
    //   );
    //   setFieldOptions((prev) => ({
    //     ...prev,
    //     medicineSaltList: medicineSaltList?.payload?.medicine_salt,
    //   }));
    // }
  }, [formTypeList, packList]);



  const [formState, setFormState] = useState({
    id: batchDetails?.id || "",
    amount: batchDetails?.amount || "",
    batch_no: batchDetails?.batch_no || "",
    expiry_date: batchDetails?.expiry_date || "",
    flat_price: batchDetails?.flat_price || "",
    form_type: batchDetails?.form_type || "",
    medicine_id: batchDetails?.medicine_id || "",
    packing: batchDetails?.packing || "",
    purchase_cost: batchDetails?.purchase_cost || "",
    stock_left: batchDetails?.stock_left || "",
  });

  console.log(batchDetails, 'batchDetails');
  console.log(formState, 'formState')

  // UseEffect to populate formState when batchDetails changes

  // useEffect(() => {
  //   if (batchDetails) {
  //     setFormState((prevState) => ({
  //       ...prevState,
  //       id: batchDetails?.id,
  //       amount: batchDetails?.amount || "",
  //       batch_no: batchDetails?.batch_no || "",
  //       expiry_date: batchDetails?.expiry_date || "",
  //       flat_price: batchDetails?.flat_price || "",
  //       form_type: batchDetails?.form_type || "",
  //       medicine_id: batchDetails?.medicine_id || "",
  //       packing: batchDetails?.packing || "",
  //       purchase_cost: batchDetails?.purchase_cost || "",
  //       stock_left: batchDetails?.stock_left || "",
  //     }));
  //   }
  // }, [batchDetails]);

  const [errors, setErrors] = useState({});

  // console.log(batches)
  //handle form change
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({ ...prev, [name]: undefined }));
  };

  const handleChange = (field, value) => {
    // console.log(index, field, value)

    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
    setErrors((prev) => ({ ...prev, [field]: undefined }));
  };


  const handleUpdateBatch = async (e) => {
    // if (!validateForm()) {
    //   return; // Stop submission if validation fails
    // }

    e.preventDefault();
    const payload = {
      medicine_batch: {
        ...formState,
      }
    };
    try {
      const result = await updateMedicineBatch(
        payload, batchDetails?.id
      );
      console.log(result);
      showToast(
        result?.status?.message || result?.status?.status,
        result?.status?.status
      );
      if (result?.status?.status === "SUCCESS") {
        refetch(medicineDetailsId);
        onClose();
      }
      // if (result?.status?.status === "SUCCESS") {
      //   showToast("Purchase Order Created  Successfully" ,"SUCCESS");
      //   // navigate("/admin/purchase_orders");
      // }
    } catch (error) {
      showToast(error.message, "ERROR");
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", display: 'flex', height: "90vh", flexDirection: 'column', justifyContent: "space-between" }}>

        <Box sx={{
          maxHeight: "80vh",

        }}>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={4}
            position="sticky"
          >
            <Box></Box>


            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: "8px" }} >
              <img src={medicines_icon} alt='' sx={{ color: 'neutral.navyBlue', marginBottom: "4px", marginRight: '8px' }} />
              <Typography
                variant="heading_Large"
                fontWeight={700}
                gutterBottom
                sx={headingStyle}
              >
                Update Medicine Batch
              </Typography>
            </Box>
            <IconButton sx={{ marginBottom: "r0em" }} onClick={onClose}>
              <CloseIcon size="small" sx={{ color: "red" }} />
            </IconButton>
          </Box>

          <Grid container spacing={2}>

            <Grid item sm={6}>
              <TextField value={formState?.batch_no} fullWidth placeholder="Batch No " name="batch_no" onChange={(e) => handleFormChange(e)} sx={autoFocusOffWithOutBorder}
                error={!!errors['batch_no']}
                helperText={errors['batch_no']}
                type="text"
              />
            </Grid>

            <Grid item sm={6}>
              <TextField value={formState?.expiry_date} fullWidth placeholder="Expiry date " name="expiry_date" onChange={(e) => handleFormChange(e)} sx={autoFocusOffWithOutBorder}
                error={!!errors['expiry_date']}
                type="date"
                helperText={errors['expiry_date']}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField value={formState?.amount} fullWidth placeholder="Amount " name="amount" onChange={(e) => handleFormChange(e)} sx={autoFocusOffWithOutBorder}
                type="number"
                error={!!errors['amount']}
                helperText={errors['amount']}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField value={formState?.purchase_cost} fullWidth placeholder="Purchase cost " name="purchase_cost" onChange={(e) => handleFormChange(e)} sx={autoFocusOffWithOutBorder}
                type="number"
                error={!!errors['purchase_cost']}
                helperText={errors['purchase_cost']}
              />
            </Grid>

            <Grid item sm={6}>
              <Autocomplete
                clearOnEscape={false}
                clearIcon={null}
                sx={{
                  ...autoFocusOffWithOutBorder,
                  backgroundColor: '#F7F7FF;',
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    height: "40px",
                    padding: "9px !important",
                    "& input": {
                      padding: "8px",
                      fontSize: "14px",
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      display: "none", // Hides the dropdown icon
                    },
                  },
                }}
                options={
                  fieldOptions?.formTypeList?.filter(
                    (option) => option
                  ) || []
                }
                getOptionLabel={(option) => option || ""}
                value={formState?.form_type || ""}
                onInputChange={(e, value) => {
                  handleChange('form_type', value); // Update the form state on input change
                }}
                onChange={(e, value) => {
                  if (value) {
                    // Assuming `value` is a string directly
                    console.log(value);
                    handleFormChange(e)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Form type"
                    error={!!errors.form_type}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option}>
                    {option}
                  </MenuItem>
                )}
                isOptionEqualToValue={(option, value) =>
                  option === value
                }
              />
            </Grid>


            <Grid item sm={6}>
              <Autocomplete
                clearOnEscape={false}
                clearIcon={null}
                sx={{
                  ...autoFocusOffWithOutBorder,
                  backgroundColor: '#F7F7FF;',
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    height: "40px",
                    padding: "9px !important",
                    "& input": {
                      padding: "8px",
                      fontSize: "14px",
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      display: "none", // Hides the dropdown icon
                    },
                  },
                }}
                options={
                  fieldOptions?.packList?.filter(
                    (option) => option
                  ) || []
                } // Filter out invalid entries
                getOptionLabel={(option) => option || ""} // Use the string value directly
                value={formState?.packing || ""} // Handle input display safely
                onInputChange={(e, value) => {
                  handleChange('packing', value);
                }}
                onChange={(e, value) => {
                  if (value) {
                    // Assuming `value` is a string directly
                    console.log(value);
                    handleFormChange(e);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select pack"
                    error={!!errors.packing}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option}>
                    {option}
                  </MenuItem>
                )}
                isOptionEqualToValue={(option, value) =>
                  option === value
                }
              />
            </Grid>



            <Grid item sm={12}>
              <TextField
                fullWidth
                onChange={(e) => handleFormChange(e)}
                value={formState.stock_left}
                type="number"
                name="stock_left"
                placeholder="Stock left"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.stock_left}
                helperText={errors.stock_left}
              />
            </Grid>
          </Grid>


        </Box>


        <Box
          container
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ zIndex: "10" }}
          lg={12}
          marginBottom={'5px'}
        >
          <Grid item>

          </Grid>

          <Grid item>
            <Button
              onClick={handleUpdateBatch}
              className="btn draw-border"
              variant="contained"
              id="submit"
              sx={{
                ...primaryBtnBlue,
                width: '195px',
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
              }}
            //   disabled={isLoading}
            >
              Update Batch
            </Button>
          </Grid>
        </Box>
      </Box>

    </>
  );
};

export default UpdateMedicineBatch;

import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getAllMedicalTestAndCompositeLab } from "../connections/getApi";
import { setError, setLoading } from "../redux/slices/prescriptionSlice";

const UseGetallMedicalTestAndComposite = (page, perPage, q) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ["getAllMedicalTestAndCompositeLab", page, perPage, q],
    queryFn: () => getAllMedicalTestAndCompositeLab(page, perPage, q),
    enabled: !!q || !!page || !!perPage,
    onError: (error) => {
      console.error("Error in get Medicine lists :", error);
      dispatch(setError(true));
      dispatch(setLoading(false));
    },
    onSuccess: (data) => {
      console.log("Successfully fetched medicines list:", data);
    },
    onSettled: () => {
      dispatch(setLoading(false));
    },

    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false,

    // refetchOnWindowFocus: false, // Don't refetch when window regains focus
    // refetchOnReconnect: false, // Don't refetch when reconnecting
  });
};

export default UseGetallMedicalTestAndComposite;

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  FormControl,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
  Popper,
  InputAdornment,
} from "@mui/material";
import {
  headingStyle,
  labPageButton,
  tableCellLab,
  tableRowLab,
  xBtn,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import {
  dateFiled,
  menuItemStyle,
  primaryBtn,
  selectField,
  textfieldText,
  textfieldTextBlueBorder,
  textfieldTextBlueBorderPharmacy,
} from "../../style/AppointmentStyle";
import SearchIcon from "@mui/icons-material/Search";
import { drTableRow } from "../../style/DrApotComponentStyle";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { addBtn, primaryBtnPharmacy } from "../../style/PharmacyStyle";
import {
  setSearchPatient,
  setPatientDetailsData,
  setLoading,
} from "../../redux/slices/pharmacySlice";
import { useDispatch, useSelector } from "react-redux";
import { orangeButton, primaryBtnBlue, textfieldTextPrep } from "../../style/PatientDetailsStyle";
import UseSearchPatient from "../../helper/UseSearchPatient";
import UsePatientDetail from "../../helper/UsePatientDetail";
import useDebounce from "../../hooks/UseDebounce";
import UseGetMedicineList from "../../helper/UseGetMedicineList";
import UseMedicineByName from "../../helper/UseMedicineByName";
import UseGetMedicineDetail from "../../helper/UseGetMedicineDetail";
import {
  getMedicineByName,
  getPriceByQuantity,
  getPriceByQuantityForReturn,
} from "../../connections/getApi";
import PharmacyInvoice from "../PrintCompoents/PharmacyInvoice";
import { useReactToPrint } from "react-to-print";
import { generateBill } from "../../connections/postApi";
import { showToast } from "../../utils/toastUtil";
import UseGetAllMedicineList from "../../helper/UseGetAllMedicineList";
import { Link } from "react-router-dom";
import calender_icon from '../../assets/calender_icon.svg';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { autoFocusOffWithOutBorder, drTableRowBlueBg } from "../../style/PatinetFormStyle";

const NewBillPharmacy = ({ onClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMedicines, setFilteredMedicines] = useState([]);
  const [openSearchDropDown, setOpenSearchDropDown] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [patientId, setPatientId] = useState(null);
  const [searchData, setSearchData] = useState({
    q: "",
    page: 1,
    perPage: 10,
  });
  const [formState, setFormState] = useState({
    id: "",
    full_name: "",
    mobile: "",
    email: "",
    dob: "",
    gender: "",
  });
  const [medicines, setMedicines] = useState([
    {
      id: "",
      name: "",
      quantity: "",
      batchId: null,
      batch: "",
      batchArray: [],
      returned: false,
      stock: "",
      packing: "",
      unitMrp: null,
      mrp: null,
      discount: null,
      gst: null,
      amount: null,
    },
  ]);
  const [total, setTotal] = useState(0);
  const [select, setSelect] = useState(null);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [showButton, setShowButton] = useState(true);
  const [printInvoiceData, setPrintInvoiceData] = useState({});
  const [errors, setErrors] = useState({});
  const [openMedicineList, setOpenMedicines] = useState(false);
  const [highlightedIndexMedicine, setHighlightedIndexMedicine] = useState(0);

  const dispatch = useDispatch();
  const { data: medicineList } = UseGetAllMedicineList();

  useEffect(() => {
    // Simulating fetching all data on component mount and storing it in local storage or state.
    if (
      medicineList?.payload?.medicines &&
      medicineList?.payload?.medicines?.length > 0
    ) {
      localStorage.setItem(
        "allMedicines",
        JSON.stringify(medicineList?.payload?.medicines)
      );
    }
  }, [medicineList]);

  const handleSearch = (event) => {
    // console.log(event.target.value);
    const query = event.target.value.toLowerCase();
    setSearchTerm(query);

    const storedMedicines =
      JSON.parse(localStorage.getItem("allMedicines")) || [];

    // Filter medicines based on search query
    const filteredMedicineList = storedMedicines.filter((medicine) =>
      medicine[1].toLowerCase().startsWith(query)
    );

    // Limit the results to top 10 and sort
    const limitedResults = filteredMedicineList.slice(0, 10);

    setFilteredMedicines(limitedResults);
  };

  const handleChange = (index, field, value) => {
    const newMedicines = medicines.map((medicine, idx) =>
      idx === index ? { ...medicine, [field]: value } : medicine
    );
    setMedicines(newMedicines);
  };

  const updateNameAndId = (index, value) => {
    const newMedicines = [...medicines];
    newMedicines[index] = {
      ...newMedicines[index],
      id: value[0],
      name: value[1],
    };
    setMedicines(newMedicines);
  };

  const handlePresence = async (index, value) => {
    const medicineNames = medicines
      ?.slice(0, medicines?.length > 1 ? medicines?.length - 1 : 0)
      .map((med) => med.name)
      .join(",");
    // encodeURIComponent(medicineNames)
    // console.log(medicineNames);
    try {
      const res = await getMedicineByName(
        encodeURIComponent(medicineNames),
        encodeURIComponent(value[1])
      );

      //  console.log(res.payload?.presence);
      if (res?.payload?.presence) {
        const updatedMedicines = medicines?.map((medicine, i) =>
          i === index
            ? { ...medicine, id: "", name: "", description: "", remark: "" }
            : medicine
        );

        // Update the state with the cleared fields
        setMedicines(updatedMedicines);

        console.log(updatedMedicines, "Updated medicines list");
        showToast("Medicine already present in the list", "ERROR");
      }
    } catch (error) {
      console.log(error);
    }
    // console.log(value, " call api to check ");
    setFilteredMedicines([]);
  };

  // const handleKeyDown = (e, index, field) => {
  //   if (e.key === "Enter" && field === "name") {
  //     e.preventDefault();

  //     if (!medicines[index]?.name) {
  //       setTimeout(() => document.getElementById(`submit`).focus(), 0);
  //       return;
  //     }

  //     const currentInput = medicines[index]?.name?.toLowerCase() || "";

  //     const topOption = filteredMedicines.find((medicine) =>
  //       (medicine[1] || "").toLowerCase().startsWith(currentInput)
  //     );

  //     if (topOption) {
  //       setTimeout(() => {
  //         updateNameAndId(index, topOption);
  //         handlePresence(index, topOption);
  //         setSelectedMedicine({ name: topOption[1], id: topOption[0] });
  //         document.getElementById(`quantity-${index}`).focus();
  //       }, 100); // Delay to ensure the input value is fully captured
  //     }
  //   } else if (e.key === "Enter" && field === "quantity") {
  //     setTimeout(() => document.getElementById(`return-${index}`).focus(), 0);
  //   } else if (e.key === "Enter" && field === "return") {
  //     handleAddRow();
  //     setTimeout(() => document.getElementById(`name-${index + 1}`).focus(), 0);
  //   }
  // };

  const handleKeyDown = (e, index, field) => {
    if (field === "name") {

      if (["ArrowDown", "ArrowUp"].includes(e.key)) {
        e.preventDefault();
        // Navigate options with arrow keys
        setHighlightedIndexMedicine((prevIndex) => {
          if (e.key === "ArrowDown") {
            return prevIndex < filteredMedicines?.length - 1
              ? prevIndex + 1
              : 0;
          } else {
            return prevIndex > 0
              ? prevIndex - 1
              : filteredMedicines?.length - 1;
          }
        });
      }

      else if (e.key === "Tab") {
        if (!medicines[index]?.name) {
          setTimeout(() => document.getElementById(`submit`).focus(), 0);
          return;
        }

        e.preventDefault();
        const topOption = filteredMedicines[highlightedIndexMedicine];

        if (topOption) {
          updateNameAndId(index, topOption);
          handlePresence(index, topOption);
          setHighlightedIndexMedicine(0);
          setTimeout(
            () => document.getElementById(`quantity-${index}`).focus(),
            0
          );
        } else {
          setHighlightedIndexMedicine(0);
          setTimeout(
            () => document.getElementById(`quantity-${index}`).focus(),
            0
          ); // Focus next field
        }
      }

      else if (e.key === "Enter") {
        if (!medicines[index]?.name) {
          setTimeout(() => document.getElementById(`submit`).focus(), 0);
          return;
        }

        e.preventDefault();
        const topOption = filteredMedicines[highlightedIndexMedicine];

        if (topOption) {
          setTimeout(() => {
            setHighlightedIndexMedicine(0);
            updateNameAndId(index, topOption); // Ensure this is wrapped inside a function
            handlePresence(index, topOption); // Ensure this is wrapped inside a function
            // document.getElementById(`description-${index}`).focus(); // Focus next field
          }, 0);
        }
      }
    }
    else if (e.key === "Enter" && field === "quantity") {
      setTimeout(() => document.getElementById(`return-${index}`).focus(), 0);
    }
    else if (e.key === "Enter" && field === "return") {
      handleAddRow();
      setTimeout(() => document.getElementById(`name-${index + 1}`).focus(), 0);
    }
  };



  const { data: patientDetailsQuery } = UsePatientDetail(patientId);
  const { data: searchResult } = UseSearchPatient(searchData);
  const { data: medicineDetail } = UseGetMedicineDetail(selectedMedicine?.id);

  const { patientData } = useSelector((state) => state.pharmacy);
  const { patientDetailsData } = useSelector((state) => state.pharmacy);
  const { isLoading } = useSelector((state) => state.pharmacy);

  const debouncedInputValue = useDebounce(inputValue, 1000);

  // search query for patient by name or id
  useEffect(() => {
    if (debouncedInputValue) {
      setSearchData((prev) => ({ ...prev, q: debouncedInputValue }));
    } else {
      dispatch(setSearchPatient([]));
    }
  }, [debouncedInputValue, dispatch]);

  //save existing patient on select search
  const selectedPatient = (id) => {
    setPatientId(id?.id);
  };

  // handle search input
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  useEffect(() => {
    if (searchResult?.payload?.patients) {
      dispatch(setSearchPatient(searchResult?.payload?.patients));
    }
  }, [searchResult, dispatch, inputValue]);

  useEffect(() => {
    if (patientDetailsQuery?.payload) {
      dispatch(setPatientDetailsData(patientDetailsQuery?.payload));
      setFormState({
        id: patientDetailsQuery?.payload?.id,
        full_name: patientDetailsQuery?.payload?.full_name,
        mobile: patientDetailsQuery?.payload?.mobile,
        email: patientDetailsQuery?.payload?.email,
        dob: patientDetailsQuery?.payload?.dob,
        gender: patientDetailsQuery?.payload?.gender,
      });
    }
    setErrors({});
  }, [dispatch, patientId, patientDetailsQuery]);
  //  select patient code ends here

  //handle form change
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({ ...prev, [name]: undefined }));
  };

  // handle add row
  const handleAddRow = () => {
    setMedicines([
      ...medicines,
      {
        name: "",
        quantity: "",
        batch: "",
        return: false,
        stock: "",
        packing: "",
        UnitMRP: null,
        MRP: null,
        GST: null,
        amount: null,
      },
    ]);
  };

  //packing and stock show after select medicine
  useEffect(() => {
    if (medicineDetail && select !== null) {
      setMedicines((prevMedicines) =>
        prevMedicines?.map((medicine, idx) =>
          idx === select
            ? {
              ...medicine,
              stock: medicineDetail?.payload?.stock_left,
              packing: medicineDetail?.payload?.packing,
            }
            : medicine
        )
      );
      // console.log(packing, stock_left, '<-stock and packing');
    }
  }, [medicineDetail, select]);

  const handleQuantityChange = (index, field, value) => {

    if (value) {
      console.log("hello");
      setMedicines((prevMedicines) =>
        prevMedicines.map((medicine, idx) =>
          idx === index
            ? {
              ...medicine,
              packing: "",
              unitMrp: null,
              mrp: null,
              discount: null,
              gst: null,
              amount: null,
            }
            : medicine
        )
      );

      // setSelectedMedicine(null);
    }

    const newMedicines = medicines?.map((medicine, idx) =>
      idx === index ? { ...medicine, [field]: value } : medicine
    );

    setMedicines(newMedicines);
    dispatch(setLoading(true));
    getPriceByQuantity(medicines[index].id, field === "batchId" ? medicines[index].quantity : value, field === "batchId" ? value : medicines[index].batchId)
      .then((res) => {
        const {
          selected_batch,
          discount,
          gst,
          mrp,
          batches,
          unit_price,
          grand_total,
        } = res.payload;

        // Update the medicine entry with the new price data
        const updatedMedicines = newMedicines.map((medicine, idx) =>
          idx === index
            ? {
              ...medicine,
              batchId: selected_batch?.id,
              batch: selected_batch,
              batchArray: batches,
              packing: batches[0]?.packing,
              unitMrp: medicine.returned ? -unit_price : unit_price,
              mrp: medicine.returned ? -mrp : mrp,
              discount: medicine.returned ? `-${discount}%` : `${discount}%`,
              gst,
              amount: medicine.returned ? -grand_total : grand_total,
            }
            : medicine
        );
        setMedicines(updatedMedicines);
        dispatch(setLoading(false));
      })
      .catch((error) => {
        console.error("Error fetching price:", error);
        dispatch(setLoading(false));
      });
  };

  const handleReturnChange = (index) => {
    const newMedicines = medicines.map((medicine, idx) =>
      idx === index ? { ...medicine, returned: !medicine.returned } : medicine
    );
    setMedicines(newMedicines);

    getPriceByQuantityForReturn(medicines[index].id, medicines[index].quantity)
      .then((res) => {
        // console.log(res.payload);
        const {
          selected_batch,
          discount,
          gst,
          mrp,
          batches,
          unit_price,
          grand_total,
        } = res.payload;

        // Update the medicine entry with the new price data
        const updatedMedicines = newMedicines.map((medicine, idx) =>
          idx === index
            ? {
              ...medicine,
              batchId: selected_batch?.id,
              batch: selected_batch,
              batchArray: batches,
              packing: batches[0]?.packing,
              unitMrp: medicine.returned ? -unit_price : unit_price,
              mrp: medicine.returned ? -mrp : mrp,
              discount: medicine.returned ? `-${discount}%` : `${discount}%`,
              gst,
              amount: medicine.returned ? -grand_total : grand_total,
            }
            : medicine
        );

        setMedicines(updatedMedicines);
      })
      .catch((error) => {
        console.error("Error fetching price:", error);
      });
  };

  useEffect(() => {
    let totalAmount = 0;
    let validMedicines = 0;
    let returnMedicine = 0;
    let hasInvalidQuantity = false;

    medicines.forEach((medicine) => {
      // Check if the medicine has a valid name and quantity
      if (medicine.name && medicine.quantity && medicine.quantity > 0) {
        validMedicines++;

        if (medicine?.amount < 0) {
          returnMedicine += parseFloat(medicine.amount);
        } else {
          totalAmount += parseFloat(medicine.amount);
        }
      }

      // Check if there are medicines with a name but invalid quantity (null, 0, or undefined)
      if (medicine.name && (!medicine.quantity || medicine.quantity <= 0)) {
        hasInvalidQuantity = true;
      }
    });

    setSelect(validMedicines);
    setTotal(Math.abs(totalAmount + returnMedicine));

    // If any invalid quantity is found, hide the button
    setShowButton(!hasInvalidQuantity && validMedicines > 0);
  }, [medicines]);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "medicine invoice Print",
    onAfterPrint: () => console.log("Print job finished"),
  });

  const handleRemoveRow = (index) => {
    const newMedicines = medicines.filter((_, idx) => idx !== index);
    setMedicines(newMedicines);
  };

  const validateForm = () => {
    let formValid = true;
    const newErrors = {};

    if (!formState.full_name) {
      newErrors.full_name = "Full Name cannot be blank";
      formValid = false;
    }

    // Mobile number validation (10 digits)
    if (!formState?.mobile) {
      newErrors.mobile = "Mobile cannot be blank";
      formValid = false;
    } else if (!/^\d{10}$/.test(formState.mobile)) {
      newErrors.mobile = "Please enter a valid 10-digit mobile number";
      formValid = false;
    }

    // Email validation using regex pattern
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!formState?.email) {
    //   newErrors.email = "Email cannot be blank";
    //   formValid = false;
    // } else if (!emailPattern.test(formState.email)) {
    //   newErrors.email = "Please enter a valid email address";
    //   formValid = false;
    // }

    if (!formState?.dob) {
      newErrors.dob = "Date of Birth cannot be blank";
      formValid = false;
    }
    if (!formState?.gender) {
      newErrors.gender = "Gender cannot be blank";
      formValid = false;
    }

    // Validate medicines
    // medicines.forEach((medicine, index) => {
    //   if (!medicine.name) {
    //     newErrors[`medicine_name_${index}`] = "Medicine name cannot be blank";
    //     formValid = false;
    //   }
    //   if (!medicine.quantity) {
    //     newErrors[`medicine_quantity_${index}`] = "Quantity cannot be blank";
    //     formValid = false;
    //   }
    // });

    setErrors(newErrors);
    return formValid;
  };

  //handle save & print invoice
  const saveAndPrint = async () => {
    if (!validateForm()) {
      return;
    }

    const formattedMedicines = medicines.map((medicine) => ({
      id: medicine?.id,
      name: medicine.name,
      batch: medicine?.batch?.id,
      packing: medicine?.packing,
      quantity: medicine?.quantity,
      cost_per_unit: medicine?.unitMrp,
      cost: (medicine?.unitMrp * medicine?.quantity).toFixed(2),
      amount: medicine?.amount,
      discount_per_unit: (((medicine?.quantity * medicine?.unitMrp) - medicine?.amount) / (+medicine?.quantity)).toFixed(2),
      discount: ((medicine?.quantity * medicine?.unitMrp) - medicine?.amount).toFixed(2),
      // medicine.discount,

      // amount: medicine.unitMrp * medicine.quantity,
      // discount: medicine.discount,
      // net: medicine.amount,


      // example 
      // "id": "7210",
      // "name": "TEST PARACETAMOL",
      // "batch": "29",
      // "packing": "10",
      // "quantity": "2",
      // "cost_per_unit": "200",
      // "cost": "400",
      // "discount_per_unit": "10",
      // "discount": "40",
      // "amount": "360"

    }));

    const total = medicines.reduce((sum, medicine) => {
      return sum + (medicine?.unitMrp || 0) * (medicine?.quantity || 0);
    }, 0);

    const grandTotal = medicines.reduce((sum, medicine) => {
      return sum + (medicine?.amount || 0);
    }, 0);

    const discount = total - grandTotal;


    if (patientDetailsData?.id) {
      const payload = {
        patient: {
          full_name: formState?.full_name,
          dob: formState?.dob,
          gender: formState?.gender,
          mobile: formState?.mobile,
          email: formState?.email,
          // patient_id: patientDetailsData?.id,
        },
        medicines: formattedMedicines,
        discount: discount.toFixed(2),
        total: total.toFixed(2),
        grand_total: grandTotal.toFixed(2),
        prescription_id: patientDetailsData?.appointments?.prescription_id || null,
        invoice_id: patientDetailsData?.invoice_id || null,
        patient_id: patientDetailsData?.id,
      };
      const res = await generateBill(payload);
      setPrintInvoiceData(res);
      console.log(res);

      if (res?.status?.status === "SUCCESS") {
        setTimeout(() => {
          showToast("generating invoice print ", "SUCCESS");
          handlePrint();
        }, 0);
      }
      showToast(
        res?.status?.message || res?.status?.status,
        res?.status?.status
      );
    } else {
      const payload = {
        patient: {
          full_name: formState?.full_name,
          dob: formState?.dob,
          gender: formState?.gender,
          mobile: formState?.mobile,
          email: formState?.email,
        },
        discount: discount.toFixed(2),
        total: total.toFixed(2),
        grand_total: grandTotal.toFixed(2),
        medicines: formattedMedicines,
      };

      const res = await generateBill(payload);
      setPrintInvoiceData(res);
      console.log(res);
      if (res?.status?.status === "SUCCESS") {
        setTimeout(() => {
          showToast("generating invoice print ", "SUCCESS");
          handlePrint();
        }, 0);
      }
      showToast(res?.status?.message, res?.status?.status);
    }
  };

  // console.log(medicines);
  return (
    <>
      <Box sx={{ width: "100%", display: 'flex', height: "90vh", flexDirection: 'column', justifyContent: "space-between" }}>

        <Box sx={{
          overflowY: 'auto', maxHeight: "80vh", '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}  >

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
          >
            <Box></Box>


            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              <ReceiptLongIcon sx={{ color: 'neutral.navyBlue', marginBottom: "4px", marginRight: '8px' }} />
              <Typography
                variant="heading_Large"
                fontWeight={700}
                gutterBottom
                sx={headingStyle}
              >
                New  Bill
              </Typography>
            </Box>
            <IconButton sx={{ marginBottom: "2rem" }} onClick={onClose}>
              <CloseIcon size="small" sx={{ color: "red" }} />
            </IconButton>
          </Box>

          <Grid container spacing={2}>
            {/* {"-----Search Patient Information-----"} */}
            <Grid item sm={4}>
              <Autocomplete
                freeSolo
                popupIcon={false}
                open={openSearchDropDown}
                inputValue={inputValue}
                clearIcon={false}
                sx={{
                  ...autoFocusOffWithOutBorder,
                  "& .MuiOutlinedInput-root": {
                    paddingX: "8px", // No padding inside the input field
                    paddingY: "1.5px",
                    borderRadius: "15px",
                    backgroundColor: '#F7F7FF',
                  },
                }}
                onOpen={() => setOpenSearchDropDown(true)}
                filterOptions={(options) => options}
                onClose={() => {
                  dispatch(setSearchPatient([]));
                  setOpenSearchDropDown(false);
                }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    selectedPatient(newValue);
                    setInputValue("");
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                  handleInputChange(event, newInputValue);
                }}
                // noOptionsText={inputValue ? `Add "${inputValue}" as a new patient` : "No patients found"}
                options={patientData || []}
                // loading={loading}
                getOptionLabel={(option) =>
                  option.uid && option.full_name
                    ? `${option.uid} - ${option.full_name}`
                    : `${option.full_name}`
                }
                renderOption={(props, option) => (
                  <MenuItem
                    key={option.id + Math.random()}
                    // onClick={() => selectedPatient(option)}
                    {...props}
                    sx={{
                      ...menuItemStyle,
                      width: "100%",
                      "&:hover, &.Mui-focused": {
                        backgroundColor: "#blue !important",
                        color: "black !important",
                      },
                    }}
                  >
                    {" "}
                    {option.uid
                      ? `${option.uid} - ${option.full_name}`
                      : option.full_name}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    name="full_name"
                    onChange={handleFormChange}
                    required
                    id="search"
                    placeholder="Search Patient"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          <SearchIcon sx={{ opacity: 0.4 }} color="grey" />
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}

                    error={!!errors.full_name}
                    helperText={errors.full_name}
                  />
                )}
              />
            </Grid>

            <Grid item sm={2}>
              <TextField
                fullWidth
                onChange={handleFormChange}
                value={formState.email}
                name="email"
                placeholder="Email"
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.email}
                helperText={errors.email}
                type="email"
              />
            </Grid>

            <Grid item sm={2}>
              <TextField
                fullWidth
                onChange={handleFormChange}
                value={formState.mobile}
                name="mobile"
                placeholder="Mobile"
                // type="mobile"
                required
                variant="outlined"
                inputProps={{
                  maxLength: 10,
                  inputMode: "numeric",
                  pattern: "[0-9]*" // Ensure only numeric values are accepted
                }}
                sx={{ ...autoFocusOffWithOutBorder, }}
                error={!!errors.mobile}
                helperText={errors.mobile}
              />
            </Grid>


            <Grid item sm={2}>
              <FormControl variant="outlined" sx={{ width: '100%' }}>
                <TextField
                  fullWidth
                  required
                  onChange={handleFormChange}
                  value={formState.dob}
                  type="date"
                  name="dob"
                  sx={autoFocusOffWithOutBorder}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="end">
                  //       <img src={calender_icon} alt="" style={{ width: 20, height: 20 }} /> {/* Your custom SVG */}
                  //     </InputAdornment>
                  //   ),
                  //   // Hide the default calendar icon
                  //   inputProps: {
                  //     style: { appearance: 'none', WebkitAppearance: 'none', MozAppearance: 'none' },  // Hide default icon
                  //   }
                  // }}
                  placeholder="DOB"
                  error={!!errors.dob}
                  helperText={errors.dob}
                  inputProps={{ max: new Date().toISOString().split("T")[0] }}
                />
              </FormControl>
            </Grid>


            <Grid item sm={2}>
              <FormControl fullWidth variant="outlined">
                <Select
                  error={!!errors.gender}
                  required
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none", // Remove border
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none", // Remove border on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none", // Ensure border remains none on focus
                    },
                    "& .MuiSelect-select": {
                      padding: "9px",
                      borderRadius: "15px",
                      backgroundColor: "#F7F7FF",
                    },
                    "&:hover": {
                      borderRadius: '15px',
                      boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                    },
                  }}
                  value={formState?.gender}
                  onChange={handleFormChange}
                  name="gender"
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <span style={{ color: "text.default_grey" }}>
                          Select Gender
                        </span>
                      );
                    }
                    return selected === "male"
                      ? "Male"
                      : selected === "female"
                        ? "Female"
                        : "Other";
                  }}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
                {errors.gender && (
                  <Typography color="error">{errors.gender}</Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TableContainer sx={{
              mt: 2, width: "100%",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                display: "none", // Hide the scrollbar for Webkit browsers (Chrome, Safari)
              },
              "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
              "scrollbar-width": "none",

            }}>
              <Table
                sx={{
                  width: "100%",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide the scrollbar for Webkit browsers (Chrome, Safari)
                  },
                  "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
                  "scrollbar-width": "none", // Hide scrollbar for Firefox
                }}>
                <TableHead stickyHeader >
                  <TableRow sx={{ ...drTableRowBlueBg, textAlign: "center", }}>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        borderTopLeftRadius: "20px",
                        color: 'neutral.white',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      ID
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "8px",
                        //  paddingX:"4px",
                        textAlign: "center",
                        width: "200px",
                      }}
                    >
                      Name
                    </TableCell>

                    <TableCell

                      variant="body1_text_bold"
                      sx={{
                        width: "150px",
                        paddingRight: "30px",
                        color: 'neutral.white',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Batch
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Return
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Total Stock
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Pack
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Unit MRP
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      MRP
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Discount
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      GST
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Amount
                    </TableCell>

                    <TableCell
                      sx={{ p: "4px", textAlign: "center", borderTopRightRadius: "20px" }}

                    >
                      <IconButton onClick={handleAddRow} sx={{ ...orangeButton, marginLeft: "7px", marginY: '2px' }}>
                        <AddIcon sx={{
                          color: 'neutral.white',
                          width: '30px',
                          height: '32px', padding: '0px'
                        }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {medicines?.map((medicine, index) => (
                    <TableRow key={index} sx={{
                      ...tableRowLab, borderBottom: '7px solid white', borderTop: index === 0 ? 'p4x solid white' : " ",
                      "&:hover": {
                        transform: "scale(1.001)",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        borderRadius: "20px !important",
                      },
                    }}>
                      <TableCell sx={tableCellLab}>
                        <Typography variant="body_Grey1" color={"text.grey"}>
                          {index + 1}
                        </Typography>
                      </TableCell>

                      <TableCell sx={tableCellLab}>
                        {/* <Autocomplete
                  options={filteredMedicines}
                  freeSolo
                  clearOnEscape={false}   
                  clearIcon={null} 
                  open={openMedicineList}
                  onOpen={() => setOpenMedicines(true)}
                  onClose={() => setOpenMedicines(false)}
                  id={`name-${index}`}
                  inputValue={medicine?.name !== null ? medicine?.name : ""}
                  onInputChange={(e, value) => {
                    handleChange(index, "name", value, e);
                  }}
                  onChange={(e, value) => {
                    console.log(value , 'inside auto complete');
                    if (value) {
                      handleChange(index, "id", value[0]);
                      handlePresence(index, value);
                      setSelectedMedicine({
                        name: value[1],
                        id: value[0],
                      });
                    }

                  }}
                  sx={textfieldText}
                  PopperComponent={(props) => (
                    <Grid item lg={8}>
                      <Popper
                        {...props}
                        placement="bottom-start"
                        style={menuItemStyle}
                        modifiers={[
                          {
                            name: "preventOverflow",
                            options: {
                              boundary: "window",
                            },
                          },
                        ]}
                      >
                    <div
                      style={{
                        maxHeight: 300, 
                        overflowY: "auto", 
                        scrollbarWidth: "none", 
                        maxWidth:'220px',
                        minWidth:'210px',
                      }}
                    >
                      {props.children}
                    </div>
                  </Popper>
                </Grid>
              )}
              getOptionLabel={(option) => option[1]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={handleSearch}
                  value={searchTerm}
                  placeholder="Medicine"
                  onKeyDown={(e) => handleKeyDown(e, index, "name")}
                />
              )}
              renderOption={(props, option, { index }) => (
                <MenuItem
                  sx={{
                    ...menuItemStyle,
                    "&:hover": {
                      backgroundColor: "#f58633 !important", 
                      color: "white !important",
                    },
                    ...(index === 0 && {
                      backgroundColor: "#f58633 !important", 
                      color: "white !important", 
                    }),
                  }}
                  {...props}
                  key={option[0]}
                >
                  {option[1]}
                </MenuItem>
              )}
            /> */}
                        <Autocomplete
                          options={filteredMedicines || []}
                          freeSolo
                          clearOnEscape={false}
                          // clearIcon={null}
                          open={openMedicineList === index}
                          onOpen={() => setOpenMedicines(index)}
                          onClose={() => setOpenMedicines(false)}
                          id={`name-${index}`}
                          inputValue={medicine?.name !== null ? medicine?.name : ""}
                          onInputChange={(e, value) => {
                            handleChange(index, "name", value, e);
                          }}
                          onChange={(e, value) => {
                            // console.log(value);
                            if (!value) {
                              console.log("hello");
                              setMedicines((prevMedicines) =>
                                prevMedicines.map((medicine, idx) =>
                                  idx === index
                                    ? {
                                      id: "",
                                      name: "",
                                      quantity: "",
                                      batchId: null,
                                      batch: "",
                                      batchArray: [],
                                      returned: false,
                                      stock: "",
                                      packing: "",
                                      unitMrp: null,
                                      mrp: null,
                                      discount: null,
                                      gst: null,
                                      amount: null,
                                    }
                                    : medicine
                                )
                              );

                              setSelectedMedicine(null);
                            }
                            if (value) {
                              handleChange(index, "id", value[0]);
                              handlePresence(index, value);
                              setSelectedMedicine({ name: value[1], id: value[0] });
                              setTimeout(() => {
                                document
                                  .getElementById(`quantity-${index}`)
                                  .focus();
                              }, 0);
                            }
                          }}
                          sx={textfieldTextBlueBorderPharmacy}
                          PopperComponent={(props) => (
                            <Grid item lg={8}>
                              <Popper
                                {...props}
                                placement="bottom-start"
                                style={menuItemStyle}
                                modifiers={[
                                  {
                                    name: "preventOverflow",
                                    options: {
                                      boundary: "block",
                                    },
                                  },
                                ]}
                              >
                                <div
                                  style={{
                                    maxHeight: 300,
                                    overflowY: "auto",
                                    scrollbarWidth: "none",
                                    maxWidth: "320px",
                                    minWidth: "310px",
                                  }}
                                >
                                  {props.children}
                                </div>
                              </Popper>
                            </Grid>
                          )}
                          getOptionLabel={(option) => option[1]}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={handleSearch}
                              value={searchTerm}
                              placeholder="Medicine"
                              onKeyDown={(e) => handleKeyDown(e, index, "name")}
                            />
                          )}
                          renderOption={(props, option, { index }) => (
                            <MenuItem
                              sx={{
                                ...menuItemStyle,
                                "&:hover": {
                                  backgroundColor: "#0067FF40 !important",
                                  // borderRadius:"20%",
                                  borderRadius: "16px",
                                  color: "black !important",
                                },
                                ...(highlightedIndexMedicine === index && {
                                  backgroundColor: "#0067FF40 !important",
                                  color: "black !important",
                                  borderRadius: "16px",
                                }),
                              }}
                              {...props}
                              key={option[0]}
                            >
                              {option[1]}
                            </MenuItem>
                          )}
                        />
                      </TableCell>

                      <TableCell sx={tableCellLab}>
                        <TextField
                          fullWidth
                          placeholder="Quantity"
                          variant="outlined"
                          type="number"
                          value={medicine?.quantity}
                          sx={textfieldTextBlueBorderPharmacy}
                          id={`quantity-${index}`}
                          onKeyDown={(e) => handleKeyDown(e, index, "quantity")}
                          onChange={(e) => {
                            handleChange(index, "quantity", e.target.value);
                            handleQuantityChange(index, "quantity", e.target.value);
                          }}
                          error={
                            medicine?.quantity ? medicine?.quantity <= 0 : false
                          }
                          helperText={
                            medicine?.quantity <= 0 && medicine?.quantity
                              ? "Quantity must be more than zero"
                              : null
                          }
                        />
                      </TableCell>

                      <TableCell sx={{ ...tableCellLab, width: "100px" }}>
                        <FormControl fullWidth variant="outlined">
                          <Select
                            sx={{
                              ...textfieldTextBlueBorderPharmacy,
                              borderRadius: '10px',
                              "& .MuiSelect-select": {
                                padding: "9px", // Custom padding for select dropdown
                              },
                            }}
                            placeholder="batch"
                            fullWidth
                            value={medicines[index]?.batchId || ""}
                            onChange={(e) => {
                              handleChange(index, "batchId", e.target.value);
                              handleQuantityChange(
                                index,
                                "batchId",
                                e.target.value
                              );
                            }}
                          >
                            {medicines[index]?.batchArray?.map((batch) => (
                              <MenuItem key={batch.id} value={batch.id}>
                                {batch.batch_no}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* Render the batch stock and expiry date */}

                          <Box
                            justifyContent={"space-between"}
                            display={"flex"}
                            paddingX={"2px"}
                          >
                            <Typography
                              fontSize={"12px"}
                              color={` ${medicine?.quantity >
                                medicines[index]?.batch?.stock_left
                                ? "#ed5564"
                                : "text.grey"
                                } `}
                            >
                              {medicines[index]?.batch?.stock_left}
                            </Typography>
                            <Typography fontSize={"12px"}>
                              {medicines[index]?.batch?.expiry_date}
                            </Typography>
                          </Box>
                        </FormControl>
                      </TableCell>

                      <TableCell sx={tableCellLab}>
                        <Checkbox
                          id={`return-${index}`}
                          onKeyDown={(e) => handleKeyDown(e, index, "return")}
                          onChange={(e) => {
                            handleChange(index, "return", e.target.value);
                            handleReturnChange(index);
                          }}
                          sx={{ height: "10px", width: "10px" }}
                        />
                      </TableCell>

                      <TableCell sx={tableCellLab}>
                        <Typography
                          variant="body_Grey1"
                          color={` ${medicine?.quantity > medicine.stock
                            ? "#ed5564"
                            : "text.grey"
                            } `}
                        >
                          {medicine.stock}
                        </Typography>
                      </TableCell>

                      <TableCell sx={tableCellLab}>
                        <Typography variant="body_Grey1" color={"text.grey"}>
                          {medicine?.packing}
                        </Typography>
                      </TableCell>

                      <TableCell sx={tableCellLab}>
                        {medicine.returned ? (
                          <Typography variant="body_Grey1" color={"#ed5564"}>
                            {" "}
                            {medicine?.unitMrp}{" "}
                          </Typography>
                        ) : (
                          <Typography variant="body_Grey1" color={"text.grey"}>
                            {medicine?.unitMrp}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell sx={tableCellLab}>
                        {medicine.returned ? (
                          <Typography variant="body_Grey1" color={"#ed5564"}>
                            {" "}
                            {/* {(medicine?.mrp)}{" "} */}
                            {isNaN(medicine?.mrp) ? "" : (medicine?.mrp)}{" "}
                          </Typography>
                        ) : (
                          <Typography variant="body_Grey1" color={"text.grey"}>
                            {isNaN(+medicine?.mrp) ? "" : (medicine?.mrp)}{" "}
                            {/* {(+medicine.mrp)} */}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell sx={tableCellLab}>
                        {medicine.returned ? (
                          <Typography variant="body_Grey1" color={"#ed5564"}>
                            {" "}
                            {medicine.discount}{" "}
                          </Typography>
                        ) : (
                          <Typography variant="body_Grey1" color={"text.grey"}>
                            {medicine.discount}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell sx={tableCellLab}>
                        <Typography variant="body_Grey1" color={"text.grey"}>
                          {medicine.gst}
                        </Typography>
                      </TableCell>

                      <TableCell sx={tableCellLab}>
                        {medicine.returned ? (
                          <Typography variant="body_Grey1" color={"#ed5564"}>
                            {" "}
                            {medicine?.amount}{" "}
                          </Typography>
                        ) : (
                          <Typography variant="body_Grey1" color={"text.grey"}>
                            {medicine?.amount}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell sx={tableCellLab}>
                        <IconButton sx={{ marginLeft: '8px', marginBottom: "7px", }}
                          onClick={() => handleRemoveRow(index)}
                        >
                          <CloseIcon sx={{ ...xBtn, width: '26px', height: '26px', borderRadius: '20%' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box
              sx={{
                ...tableRowLab,
                "&:hover": {
                  transform: "scale(1.001)",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "20px !important",
                },
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "20px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                justifyContent: "space-between",
                display: "flex",
                height: "60px",
                alignItems: "center",
                padding: "3px",
                paddingX: "40px"
              }}
            >
              <Box sx={{
                justifyContent: "space-between",
                display: "flex",
                height: "60px",
                alignItems: "center"
              }} columnGap={'10px'}>
                <Typography variant="h6" fontSize={'19px'}>Selected : </Typography>
                <Typography variant="h6" fontSize={'19px'}>
                  {select}
                </Typography>
              </Box>
              {/*           
          <Box></Box>

          <Box></Box> */}

              <Box sx={{
                justifyContent: "space-between",
                display: "flex",
                height: "60px",
                alignItems: "center"
              }} columnGap={'10px'}>
                <Typography variant="h6" fontSize={'20px'}>Total:</Typography>

                <Typography variant="h6" fontSize={"20px"}>{isNaN(total) ? 0 : total?.toFixed(2)}</Typography>
              </Box>
            </Box>

          </Grid>
        </Box>

        <Box
          container
          display={"flex"}

          justifyContent={"space-between"}
          sx={{ zIndex: "10" }}
          lg={12}
          marginBottom={'5px'}
        >
          <Grid item>
          </Grid>

          <Grid item>
            <Button
              onClick={saveAndPrint}
              className="btn draw-border"
              variant="contained"
              id="submit"
              sx={{
                ...primaryBtnBlue,
                width: '195px',
                display: showButton ? "flex" : "none",

                alignItems: 'center',
                justifyContent: 'center',
              }}
              disabled={isLoading}
            >
              <Typography
                textTransform={"capitalize"}
                color={"neutral.white"}
                variant="title1_medium"
              >
                Save and Print
              </Typography>
            </Button>
          </Grid>
        </Box>


      </Box>
      <div style={{ display: "none" }}>
        <PharmacyInvoice props={printInvoiceData} ref={printRef} />
      </div>
    </>
  );
};

export default NewBillPharmacy;

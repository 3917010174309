import {
  dateFiled,
  disableSelectField,
  disableTextField,
  menuItemStyle,
  selectField,
  textfieldText,
} from "../style/AppointmentStyle";
import {
  dateFiledPrep,
  primaryBtnBlue,
  primaryBtnPrep,
  selectFieldPrep,
  textfieldTextPrep,
} from "../style/PatientDetailsStyle";
const times = [
  "9:50am !R",
  "11:10 am2R",
  "12:10pm",
  "12:30pm",
  "12:55pm",
  "13:40pm",
  "15:10pm",
];
const customValues = Array.from({ length: 41 }, (_, i) => `c${10 + i}`);
const initialCities = ["City A", "City B"];
const occupation = [
  "Agriculture",
  "Architect",
  "Business",
  "Driver",
  "Electrician",
  "Engineer",
  "Farmer",
  "FREE",
  "GOVT JOB",
  "Housewife",
  "Labour",
  "Lawyer",
];
const initialEducationLevels = [
  "High School",
  "Bachelor's",
  "Master's",
  "PhD",
  "Associate's",
];
const doctorsData = [
  {
    id: 1,
    name: "Dr. John Smith",
    email: "john.smith@example.com",
    phone: "555-1234",
    address: "123 Main St, Springfield, IL",
  },
  {
    id: 2,
    name: "Dr. Emily Johnson",
    email: "emily.johnson@example.com",
    phone: "555-5678",
    address: "456 Elm St, Springfield, IL",
  },
  {
    id: 3,
    name: "Dr. Michael Brown",
    email: "michael.brown@example.com",
    phone: "555-8765",
    address: "789 Maple St, Springfield, IL",
  },
  {
    id: 4,
    name: "Dr. Sarah Williams",
    email: "sarah.williams@example.com",
    phone: "555-3456",
    address: "101 Oak St, Springfield, IL",
  },
  {
    id: 5,
    name: "Dr. David Miller",
    email: "david.miller@example.com",
    phone: "555-6543",
    address: "202 Pine St, Springfield, IL",
  },
];
const getIndiaTimeISO = () => {
  const indiaTime = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });
  return new Date(indiaTime).toISOString().split("T")[0];
};

export const capitalizeFirstLetter = (string) => {
  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : null;
};
export const appointmentForm = [
  {
    id: 1,
    field: "TextField",
    type: "text",
    isDisabled: true,
    placeholder: "Patient ID",
    sx: disableTextField,
    variant: "outlined",
    name: "uid",
    isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 6 },
  },
  {
    id: 2,
    field: "TextField",
    type: "text",
    isDisabled: false,
    placeholder: "Full Name",
    sx: textfieldText,
    variant: "outlined",
    name: "fullName",
    // isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 6 },
    // pattern: "^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$",
    required: true,
    errorMessage: "Invalid full name",
  },
  {
    id: 3,
    field: "TextField",
    type: "email",
    isDisabled: false,
    placeholder: "Email",
    sx: textfieldText,
    variant: "outlined",
    name: "email",
    // isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 6 },
    // pattern: "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
  },
  {
    id: 4, 
    field: "TextField",
    type: "number",
    isDisabled: false,
    placeholder: "Mobile Number",
    sx: textfieldText,
    variant: "outlined",
    name: "mobileNumber",
    // isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 6 },
    errorMessage: "Invalid mobile number",
    required: true,
  },
  {
    id: 5,
    field: "Select",
    type: "select",
    isDisabled: false,
    placeholder: "Marital Status",
    sx: selectField,
    variant: "outlined",
    name: "maritalStatus",
    // isTitle: "",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "default", label: "Marital Status" },
      { value: "Married", label: "Married" },
      { value: "Unmarried", label: "Unmarried" },
      { value: "Divorced", label: "Divorced" },
    ],
    gridProps: { xs: 12, sm: 4 },
  },
  {
    id: 6,
    field: "Select",
    type: "select",
    isDisabled: false,
    placeholder: "Diet",
    sx: selectField,
    variant: "outlined",
    name: "diet",
    // isTitle: "",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "default", label: "Diet" },
      { value: "veg", label: "Veg" },
      { value: "non_veg", label: "Non Veg" },
      { value: "eggitarian", label: "Eggitarian" },
      { value: "vegan", label: "Vegan" },
    ],
    gridProps: { xs: 12, sm: 4 },
  },
  {
    id: 7,
    field: "Select",
    type: "select",
    isDisabled: false,
    placeholder: "Gender",
    sx: selectField,
    variant: "outlined",
    name: "gender",
    // isTitle: "",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "default", label: "Gender" },
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
      { value: "other", label: "Other" },
    ],
    gridProps: { xs: 12, sm: 4 },
  },
  // {
  //   id: 8,
  //   field: "TextField",
  //   type: "date",
  //   isDisabled: false,
  //   placeholder: "",
  //   sx: dateFiled,
  //   variant: "outlined",
  //   name: "dob",
  //   // isTitle: "DOB",
  //   defaultValue: "default",
  //   gridProps: { xs: 12, sm: 4 },
  //   required: true,
  //   errorMessage: "Please select a date",
  // },
  {
    id: 8,
    field: "TextField",
    type: "number",
    isDisabled: false,
    placeholder: "Age ",
    sx: textfieldText,
    variant: "outlined",
    name: "age",
    // isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 4 },
    // pattern: "^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$",
    // pattern: "^[0-9]{3}$",
    required: true,
    errorMessage: "Age is required",
  },
  {
    id: 9,
    field: "Autocomplete",
    type: "autocomplete",
    isDisabled: false,
    placeholder: "Occupation",
    sx: textfieldText,
    variant: "outlined",
    name: "occupation",
    // isTitle: "Occupation",
    options: [],
    allowAddNew: false,
    gridProps: { xs: 12, sm: 4 },
  },
  {
    id: 10,
    field: "Autocomplete",
    type: "autocomplete",
    isDisabled: false,
    placeholder: "Education",
    sx: textfieldText,
    variant: "outlined",
    name: "education",
    // isTitle: "Education",
    options: [],
    allowAddNew: false,
    gridProps: { xs: 12, sm: 4 },
  },
  {
    id: 11,
    field: "TextField",
    type: "text",
    isDisabled: false,
    placeholder: "Address",
    sx: textfieldText,
    variant: "outlined",
    name: "address",
    isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 12 },
  },
  {
    id: 12,
    field: "Autocomplete",
    type: "autocomplete",
    isDisabled: false,
    placeholder: " City",
    sx: textfieldText,
    variant: "outlined",
    name: "city",
    // isTitle: "City",
    options: [],
    allowAddNew: false,
    menuItemStyle: menuItemStyle,
    gridProps: { xs: 12, sm: 6 },
  },

  {
    id: 13,
    field: "TextField",
    type: "number",
    isDisabled: false,
    placeholder: "Pincode",
    sx: textfieldText,
    variant: "outlined",
    name: "pincode",
    isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 6 },
  },
  {
    id: 14,
    field: "Select",
    type: "select",
    isDisabled: false,
    placeholder: "VisitType",
    sx: selectField,
    variant: "outlined",
    name: "patientType",
    isTitle: "",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "default", label: "VisitType" },
      { value: "New", label: "New" },
      { value: "Old", label: "Old" },
      { value: "Review", label: "Review" },
      { value: "Complimentary", label: "Complimentary" },
    ],
    gridProps: { xs: 12, sm: 4 },
    errorMessage: "Invalid visit type",
    required: true,
  },
  {
    id: 15,
    field: "TextField",
    type: "date",
    isDisabled: false,
    placeholder: "",
    sx: dateFiled,
    variant: "outlined",
    name: "visitDate",
    // isTitle: "Visit Date",
    defaultValue: "",
    gridProps: { xs: 12, sm: 4 },
    errorMessage: "Visit date is required",
    required: true,
  },
  {
    id: 16,
    field: "Select",
    type: "select",
    isDisabled: false,
    placeholder: "Slot",
    sx: selectField,
    variant: "outlined",
    name: "slot",
    // isTitle: "Slot",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [{ value: "default", label: "Slot" }],
    gridProps: { xs: 12, sm: 4 },
    errorMessage: "Select slot",
    required: true,
  },
  {
    id: 17,
    field: "Select",
    type: "select",
    isDisabled: false,
    placeholder: "Attending doctor",
    sx: selectField,
    variant: "outlined",
    name: "attendingDoctor",
    // isTitle: "Attending doctor",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [{ value: "default", label: "Nothing Selected" }],
    gridProps: { xs: 12, sm: 6 },
    required: true,
    errorMessage: "Please select a Doctor",
  },
  {
    id: 18,
    field: "Select",
    type: "select",
    isDisabled: false,
    placeholder: "Consultancy",
    sx: selectField,
    variant: "outlined",
    name: "consultancyDoctor",
    // isTitle: "Consultancy",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "default", label: "Nothing Selected" },
      // { value: "Dr Doctor User", label: "Dr Doctor User" },
    ],
    gridProps: { xs: 12, sm: 6 },
    required: true,
    errorMessage: "Please select a Doctor",
  },
];

export const PrescriptionForm = [
  // {
  //   id: 0,
  //   field: "Typography",
  //   variant: "body1_text_bold",
  //   sx: { color: "neutral.black" },
  //   title: "Patient Info",
  //   gridProps: { xs: 12, sm: 12, md: 12 },
  // },
  {
    id: 1,
    field: "TextField",
    type: "text",
    isDisabled: true,
    placeholder: "Patient ID",
    sx: disableTextField,
    variant: "outlined",
    name: "id",
    gridProps: { xs: 12, sm: 6, md: 3 },
  },
  {
    id: 2,
    field: "TextField",
    type: "text",
    placeholder: "Full Name",
    sx: textfieldTextPrep,
    variant: "outlined",
    name: "fullName",
    gridProps: { xs: 12, sm: 6, md: 3 },
    required: true,
    errorMessage: "Full name is required",
  },
  {
    id: 3,
    field: "TextField",
    type: "email",
    placeholder: "Email",
    sx: textfieldTextPrep,
    variant: "outlined",
    name: "email",
    gridProps: { xs: 12, sm: 6, md: 3 },
  },
  {
    id: 4,
    field: "TextField",
    type: "number",
    placeholder: "Mobile",
    sx: textfieldTextPrep,
    variant: "outlined",
    name: "mobile",
    gridProps: { xs: 12, sm: 6, md: 3 },
    pattern: "^[0-9]{10}$",
    errorMessage: "Mobile number is required",
  },
  {
    id: 5,
    field: "Select",
    placeholder: "Marital Status",
    sx: selectFieldPrep,
    name: "maritalStatus",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "default", label: "Marital Status" },
      { value: "married", label: "Married" },
      { value: "unmarried", label: "Unmarried" },
      { value: "divorce", label: "Divorce" },
    ],
    gridProps: { xs: 12, sm: 6, md: 2 },
    // required: true,
    // errorMessage: "Please select a marital status",
  },
  {
    id: 6,
    field: "Select",
    placeholder: "Diet",
    sx: selectFieldPrep,
    name: "diet",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "default", label: "Diet" },
      { value: "veg", label: "Veg" },
      { value: "non_veg", label: "Non Veg" },
      { value: "eggitarian", label: "Eggitarian" },
      { value: "vegan", label: "Vegan" },
    ],
    gridProps: { xs: 12, sm: 6, md: 2 },
  },
  {
    id: 7,
    field: "Select",
    placeholder: "Gender",
    sx: selectFieldPrep,
    name: "gender",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "default", label: "Gender" },
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
      { value: "other", label: "Other" },
    ],
    gridProps: { xs: 12, sm: 6, md: 2 },
    // required: true,
    // errorMessage: "Please select a Gender",
  },
  // {
  //   id: 8,
  //   field: "TextField",
  //   type: "date",
  //   placeholder: "DOB",
  //   sx: dateFiledPrep,
  //   // sx: textfieldTextPrep,
  //   name: "dob",
  //   gridProps: { xs: 12, sm: 6, md: 2, lg: 2 },
  //   required: true,
  //   errorMessage: "Please select DOB",
  // },
  {
    id: 8,
    field: "TextField",
    type: "number",
    isDisabled: false,
    placeholder: "Age ",
    sx: textfieldText,
    variant: "outlined",
    name: "age",
    // isTitle: "",
    defaultValue: "",
    gridProps: {  xs: 12, sm: 6, md: 2, lg: 2 },
    // pattern: "^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$",
    // pattern: "^[0-9]{3}$",
    required: true,
    errorMessage: "Age is required",
  },
  {
    id: 9,
    field: "Autocomplete",
    placeholder: "Select Education",
    sx: textfieldTextPrep,
    name: "education",
    options: [],
    gridProps: { xs: 12, sm: 6, md: 2 },
    allowEdit: true,
    // required: true,
    // errorMessage: "Please select education",
  },
  {
    id: 10,
    field: "Autocomplete",
    placeholder: "Select Occupation",
    sx: textfieldTextPrep,
    name: "occupation",
    options: [],
    gridProps: { xs: 12, sm: 6, md: 2 },
    allowEdit: true,
    // required: true,
    // errorMessage: "Please select occupation",
  },
  {
    id: 11,
    field: "TextField",
    type: "text",
    placeholder: "Address",
    sx: textfieldTextPrep,
    variant: "outlined",
    name: "address",
    gridProps: { xs: 12, sm: 6, md: 4, lg: 4 },
    // required: true,
    // errorMessage: "address is required  ",
  },
  {
    id: 12,
    field: "Autocomplete",
    placeholder: "Select City",
    sx: textfieldTextPrep,
    name: "city",
    options: [],
    gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
    // required: true,
    // errorMessage: "Please select city",
  },
  {
    id: 13,
    field: "TextField",
    type: "text",
    placeholder: "Pincode",
    sx: textfieldTextPrep,
    variant: "outlined",
    name: "pincode",
    gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
    // required: true,
    // errorMessage: "address is required  ",
  },
  {
    id: 14,
    field: "Button",
    text: "Save Patient Info",
    // sx: primaryBtnPrep,
    sx: primaryBtnBlue,
    variant: "contained",
    position: "right",
    // gridProps: { xs: 12, sm: 6, md: 4, lg: 4 },
    gridProps: { xs: 12, sm: 6, md: 2, lg: 2 },
    hasIcon: false,
  },
  {
    id: 112,
    field: "Typography",
    variant: "title_small2",
    sx: { color: "neutral.black" },
    title: "Visit Info" ,
    subTitle: "",
    gridProps: { xs: 12, sm: 12, md: 12 },

  },
  {
    id: 15,
    field: "Select",
    placeholder: "Visit Type",
    sx: selectFieldPrep,
    name: "visitType",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "default", label: "Visit Type" },
      { value: "old", label: "old" },
      { value: "new", label: "new" },
      { value: "review", label: "review" },
      { value: "complimentary", label: "complimentary" },
    ],
    gridProps: { xs: 12, sm: 6, md: 2 },
    required: true,
    errorMessage: "Please select visit type",
  },
  {
    id: 16,
    field: "Select",
    placeholder: "Attending Doctor",
    sx: selectFieldPrep,
    name: "attendingDoctor",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [{ value: "default", label: "Attending Doctor" }],
    gridProps: { xs: 12, sm: 6, md: 2.5 },
    required: true,
    errorMessage: "Please select attending doctor",
  },
  {
    id: 17,
    field: "Select",
    placeholder: "Consultancy Doctor",
    sx: selectFieldPrep,
    name: "consultancyDoctor",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [{ value: "default", label: "Consultancy Doctor" }],
    gridProps: { xs: 12, sm: 6, md: 2.5 },
    required: true,
    errorMessage: "Please select consultancy doctor",
  },
  {
    id: 188,
    // field: "Autocomplete",
    // placeholder: "Refer To",
    // sx: textfieldTextPrep,
    // name: "referrer",
    // options: [],
    // gridProps: { xs: 12, sm: 6, md: 2 },
    // allowEdit: true,
    field: "Autocomplete1",
    type: "autocomplete",
    isDisabled: false,
    placeholder: "Referrer",
    sx: textfieldTextPrep,
    variant: "outlined",
    name: "referrer",
    isTitle: "",
    options: [],
    allowAddNew: false,
    gridProps: { xs: 12, sm: 6, md: 2.5, lg: 2.5 },
  },
  {
    id: 18,
    field: "TextField",
    type: "text",
    placeholder: "Comments",
    sx: textfieldTextPrep,
    variant: "outlined",
    name: "comments",
    gridProps: { xs: 12, sm: 1.5, md: 1.5 },
  },

  {
    id: 19,
    field: "Button",
    sx: primaryBtnBlue,
    variant: "contained",
    icon: "EmailIcon",
    gridProps: { xs: 12, sm: 6, md: 0.4 },
    hasIcon: true,
  },
  {
    id: 144,
    field: "Button",
    // text: "Reports",
    icon: "ReportsIcon",
    // sx: primaryBtnPrep,
    // sx : primaryBtnBlue,
    variant: "contained",
    gridProps: { xs: 12, sm: 6, md: 0.4, lg: 0.4 },
    hasIcon: true,
  },
  {
    id: 20,
    field: "TextField",
    type: "text",
    placeholder: "Height (FT'IN)",
    sx: textfieldTextPrep,
    variant: "outlined",
    name: "height",
    gridProps: { xs: 12, sm: 6, md: 2 },
  },
  {
    id: 21,
    field: "TextField",
    type: "text",
    placeholder: "Weight",
    sx: textfieldTextPrep,
    variant: "outlined",
    name: "weight",
    gridProps: { xs: 12, sm: 6, md: 2 },
  },
  {
    id: 22,
    field: "TextField",
    type: "text",
    placeholder: "Fever",
    sx: textfieldTextPrep,
    variant: "outlined",
    name: "fever",
    gridProps: { xs: 12, sm: 6, md: 2 },
  },
  {
    id: 23,
    field: "TextField",
    type: "text",
    placeholder: "BP S",
    sx: textfieldTextPrep,
    variant: "outlined",
    name: "bpSystolic",
    gridProps: { xs: 12, sm: 6, md: 1 },
  },
  {
    id: 24,
    field: "TextField",
    type: "text",
    placeholder: "BP D",
    sx: textfieldTextPrep,
    variant: "outlined",
    name: "bpDiastolic",
    gridProps: { xs: 12, sm: 6, md: 1 },
  },
  {
    id: 25,
    field: "TextField",
    type: "text",
    placeholder: "BMI",
    sx: textfieldTextPrep,
    variant: "outlined",
    name: "bmi",
    gridProps: { xs: 12, sm: 6, md: 1 },
  },
  {
    id: 26,
    field: "Select",
    placeholder: "Select investigation",
    sx: selectFieldPrep,
    name: "investigationType",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [],
    gridProps: { xs: 12, sm: 6, md: 3 },
  },
];

export const billingForm = [
  {
    id: 1,
    field: "TextField",
    type: "text",
    isDisabled: true,
    placeholder: "Patient ID",
    sx: disableTextField,
    variant: "outlined",
    name: "uid",
    isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 6 },
  },
  {
    id: 2,
    field: "TextField",
    type: "text",
    isDisabled: false,
    placeholder: "Full Name",
    sx: textfieldText,
    variant: "outlined",
    name: "fullName",
    isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 6 },
    // pattern: "^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$",
    required: true,
    errorMessage: "Invalid full name",
  },

  {
    id: 3,
    field: "TextField",
    type: "number",
    isDisabled: false,
    placeholder: "Mobile Number",
    sx: textfieldText,
    variant: "outlined",
    name: "mobileNumber",
    isTitle: "", 
    defaultValue: "",
    gridProps: { xs: 12, sm: 6 },
    required: true,
    pattern: "^[0-9]{10}$",
    errorMessage: "Invalid mobile number",
  },
  {
    id: 4,
    field: "Select",
    type: "select",
    isDisabled: false,
    placeholder: "Marital Status",
    sx: selectField,
    variant: "outlined",
    name: "maritalStatus",
    isTitle: "",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "default", label: "Marital Status" },
      { value: "Married", label: "Married" },
      { value: "Unmarried", label: "Unmarried" },
      { value: "Divorced", label: "Divorced" },
    ],
    gridProps: { xs: 12, sm: 6 },
  },

  // {
  //   id: 6,
  //   field: "TextField",
  //   type: "date",
  //   isDisabled: false,
  //   placeholder: "",
  //   sx: dateFiled,
  //   variant: "outlined",
  //   name: "dob",
  //   // isTitle: "DOB",
  //   defaultValue: "default",
  //   gridProps: { xs: 12, sm: 6 },
  //   required: true,
  //   errorMessage: "Please select a date",
  // },
  {
    id: 5,
    field: "Select",
    type: "select",
    isDisabled: false,
    placeholder: "Gender",
    sx: selectField,
    variant: "outlined",
    name: "gender",
    isTitle: "",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "default", label: "Gender" },
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
      { value: "other", label: "Other" },
    ],
    gridProps: { xs: 12, sm: 4 },
  },
  {
    id: 6,
    field: "TextField",
    type: "number",
    isDisabled: false,
    placeholder: "Age ",
    sx: textfieldText,
    variant: "outlined",
    name: "age",
    // isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 4 },
    // pattern: "^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$",
    // pattern: "^[0-9]{3}$",
   
    required: true,
    errorMessage: "Age is required",
  },
  {
    id: 7,
    field: "Autocomplete",
    type: "autocomplete",
    isDisabled: false,
    placeholder: "Select Occupation",
    sx: textfieldText,
    variant: "outlined",
    name: "occupation",
    // isTitle: "Occupation",
    options: [],
    allowAddNew: false,
    gridProps: { xs: 12, sm: 4},
  },
  {
    id: 8,
    field: "TextField",
    type: "date",
    isDisabled: false,
    placeholder: "",
    sx: dateFiled,
    variant: "outlined",
    name: "visitDate",
    // isTitle: "Visit Date",
    // defaultValue: "",
    defaultValue: getIndiaTimeISO(),
    gridProps: { xs: 12, sm: 4 },
  },
  {
    id: 9,
    field: "Select",
    type: "select",
    isDisabled: false,
    placeholder: "Slot",
    sx: selectField,
    variant: "outlined",
    name: "slot",
    // isTitle: "Slot",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [{ value: "default", label: "Slot" }],
    gridProps: { xs: 12, sm: 4 },
    // required: true,
    // errorMessage: "Select Slot",
  },
  {
    id: 10,
    field: "Select",
    type: "select",
    isDisabled: false,
    placeholder: "Patient Type",
    sx: selectField,
    variant: "outlined",
    name: "patientType",
    // isTitle: "Visit type",
    defaultValue: "New",
    defaultMenuStyle: { ...menuItemStyle },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "New", label: "New" },
      { value: "Old", label: "Old" },
      { value: "Review", label: "Review" },
      { value: "Complimentary", label: "Complimentary" },
    ],
    gridProps: { xs: 12, sm: 4 },
    required: true,
    errorMessage: "Select type",
  },
  {
    id: 11,
    field: "Select",
    type: "select",
    isDisabled: false,
    placeholder: "Attending doctor",
    sx: selectField,
    variant: "outlined",
    name: "attendingDoctor",
    // isTitle: "Attending doctor",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [{ value: "default", label: "Nothing Selected" }],
    gridProps: { xs: 12, sm: 6 },
    required: true,
    errorMessage: "Select Doctor",
  },
  {
    id: 12,
    field: "Select",
    type: "select",
    isDisabled: false,
    placeholder: "Consultancy",
    sx: selectField,
    variant: "outlined",
    name: "consultancyDoctor",
    // isTitle: "Consultancy",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [{ value: "default", label: "Nothing Selected" }],
    gridProps: { xs: 12, sm: 6 },
    required: true,
    errorMessage: "Select Doctor",
  },
  {
    id: 13,
    field: "Select",
    type: "select",
    isDisabled: false,
    placeholder: "Select plan",
    sx: selectField,
    variant: "outlined",
    name: "getPlans",
    // isTitle: "Consultancy",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [{ value: "default", label: "Nothing Selected" }],
    gridProps: { xs: 12, sm: 6 },
    required: true,
    errorMessage: "select  plans",
  },
  {
    id: 14,
    field: "TextField",
    type: "text",
    isDisabled: true,
    placeholder: "Doctor's Plan Amount",
    sx: textfieldText,
    variant: "outlined",
    name: "planAmount",
    isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 6 },
  },

  {
    id: 15,
    field: "Autocomplete",
    type: "autocomplete",
    isDisabled: false,
    placeholder: "Select Education",
    sx: textfieldText,
    variant: "outlined",
    name: "education",
    // isTitle: "Education",
    options: [],
    allowAddNew: false,
    gridProps: { xs: 12, sm: 6 },
  },
  {
    id: 16,
    field: "Autocomplete",
    type: "autocomplete",
    isDisabled: false,
    placeholder: "Select City",
    sx: textfieldText,
    variant: "outlined",
    name: "city",
    // isTitle: "City",
    options: [],
    allowAddNew: false,
    gridProps: { xs: 12, sm: 6 },
  },
];

export const nurseForm = [
  {
    id: 1,
    field: "TextField",
    type: "text",
    isDisabled: true,
    placeholder: "ID",
    sx: disableTextField,
    variant: "outlined",
    name: "id",
    isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 6 },
  },
  {
    id: 2,
    field: "TextField",
    type: "text",
    isDisabled: true,
    placeholder: "Full Name",
    sx: disableTextField,
    variant: "outlined",
    name: "fullName",
    isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 6 },
    required: true,
    errorMessage: "Invalid full name",
  },
  {
    id: 3,
    field: "TextField",
    type: "email",
    isDisabled: true,
    placeholder: "Email",
    sx: disableTextField,
    variant: "outlined",
    name: "email",
    isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 6 },
  },
  {
    id: 4,
    field: "TextField",
    type: "text",
    isDisabled: true,
    placeholder: "Mobile Number",
    sx: disableTextField,
    variant: "outlined",
    name: "mobileNumber",
    isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 6 },
    pattern: "^[0-9]{10}$",
    errorMessage: "Invalid mobile number",
  },
  {
    id: 5,
    field: "Select",
    type: "select",
    isDisabled: true,
    placeholder: "Marital Status",
    sx: disableSelectField,
    variant: "outlined",
    name: "maritalStatus",
    isTitle: "",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "default", label: "Marital Status" },
      { value: "married", label: "Married" },
      { value: "unmarried", label: "Unmarried" },
      { value: "divorced", label: "Divorced" },
    ],
    gridProps: { xs: 12, sm: 4 },
    // required: true,
    // errorMessage: "Please select a marital status",
  },
  {
    id: 6,
    field: "Select",
    type: "select",
    isDisabled: true,
    placeholder: "Diet",
    sx: disableSelectField,
    variant: "outlined",
    name: "diet",
    isTitle: "",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "default", label: "Diet" },
      { value: "veg", label: "Veg" },
      { value: "non_veg", label: "Non-Veg" },
      { value: "eggitarian", label: "Eggitarian" },
      { value: "vegan", label: "Vegan" },
    ],
    gridProps: { xs: 12, sm: 4 },
  },
  {
    id: 7,
    field: "Select",
    type: "select",
    isDisabled: true,
    placeholder: "Gender",
    sx: disableSelectField,
    variant: "outlined",
    name: "gender",
    isTitle: "",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "default", label: "Gender" },
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
      { value: "other", label: "Other" },
    ],
    gridProps: { xs: 12, sm: 4 },
    // required: true,
    // errorMessage: "Please select a Gender",
  },
  // {
  //   id: 8,
  //   field: "TextField",
  //   type: "date",
  //   isDisabled: true,
  //   placeholder: "",
  //   sx: disableTextField,
  //   variant: "outlined",
  //   name: "dob",
  //   // isTitle: "DOB",
  //   defaultValue: "default",
  //   gridProps: { xs: 12, sm: 4 },
  //   inputProps: {
  //     max: new Date().toISOString().split("T")[0],
  //   },
  //   required: true,
  //   errorMessage: "Please select a date",
  // },
  {
    id: 8,
    field: "TextField",
    type: "number",
    isDisabled: true,
    placeholder: "Age",
    sx: disableTextField,
    variant: "outlined",
    name: "age",
    // isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 4 },
    // pattern: "^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$",
    // pattern: "^[0-9]{3}$",
    required: true,
    errorMessage: "Age is required",
  },
  {
    id: 9,
    field: "Select",
    type: "select",
    isDisabled: true,
    placeholder: "Attending Doctor",
    sx: disableSelectField,
    variant: "outlined",
    name: "attendingDoctor",
    // isTitle: "Attending Doctor",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [{ value: "default", label: "Nothing Selected" }],
    gridProps: { xs: 12, sm: 4 },
    // required: true,
    // errorMessage: "Please select a Doctor",
  },
  {
    id: 10,
    field: "Select",
    type: "select",
    isDisabled: true,
    placeholder: "Consultancy",
    sx: disableSelectField,
    variant: "outlined",
    name: "consultancyDoctor",
    // isTitle: "Consultancy",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [{ value: "default", label: "Nothing Selected" }],
    gridProps: { xs: 12, sm: 4 },
    // required: true,
    // errorMessage: "Please select a Doctor",
  },
  {
    id: 11,
    field: "TextField",
    type: "date",
    isDisabled: true,
    placeholder: "",
    sx: disableTextField,
    variant: "outlined",
    name: "visitDate",
    // isTitle: "Visit Date",
    defaultValue: new Date().toISOString().split("T")[0],
    gridProps: { xs: 12, sm: 4 },
    // required: true,
    // errorMessage: "Please select a Date",
  },
  {
    id: 12,
    field: "Select",
    type: "select",
    isDisabled: true,
    placeholder: "Slot",
    sx: disableSelectField,
    variant: "outlined",
    name: "slot",
    // isTitle: "Slot",
    defaultValue: "default",
    defaultMenuStyle: { ...menuItemStyle, display: "none" },
    menuItemStyle: menuItemStyle,
    options: [],
    gridProps: { xs: 12, sm: 4 },
  },
  {
    id: 13,
    field: "Autocomplete",
    type: "autocomplete",
    isDisabled: true,
    placeholder: "Select Occupation",
    sx: disableTextField,
    variant: "outlined",
    name: "occupation",
    // isTitle: "Occupation",
    options: [],
    allowAddNew: false,
    gridProps: { xs: 12, sm: 4 },
    // required: true,
    // errorMessage: "Please select a occupation",
  },
  {
    id: 14,
    field: "Autocomplete",
    type: "autocomplete",
    isDisabled: true,
    placeholder: "Select Education",
    sx: disableTextField,
    variant: "outlined",
    name: "education",
    // isTitle: "Education",
    options: [],
    allowAddNew: false,
    gridProps: { xs: 12, sm: 6 },
    // required: true,
    // errorMessage: "Please select a Education",
  },
  {
    id: 15,
    field: "Autocomplete",
    type: "autocomplete",
    isDisabled: true,
    placeholder: "Select City",
    sx: disableTextField,
    variant: "outlined",
    name: "city",
    // isTitle: "City",
    options: [],
    allowAddNew: false,
    gridProps: { xs: 12, sm: 6 },
    // required: true,
    // errorMessage: "Please select a City",
  },
  {
    id: 18,
    field: "Select",
    type: "select",
    isDisabled: true,
    placeholder: "patient type",
    sx: disableSelectField,
    variant: "outlined",
    name: "patientType",
    isTitle: "",
    defaultValue: "",
    defaultMenuStyle: { ...menuItemStyle },
    menuItemStyle: menuItemStyle,
    options: [
      { value: "new", label: "New" },
      { value: "old", label: "Old" },
      { value: "review", label: "Review" },
      { value: "complimentary", label: "Complimentary" },
    ],
    gridProps: { xs: 12, sm: 4 },
    // required: true,
    // errorMessage: "Please select patient type",
  },
  {
    id: 16,
    field: "TextField",
    type: "text",
    isDisabled: true,
    placeholder: "Address",
    sx: disableTextField,
    variant: "outlined",
    name: "address",
    isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 4 },
    // required: true,
    // errorMessage: "address is required",
  },
  {
    id: 17,
    field: "TextField",
    type: "number",
    isDisabled: true,
    placeholder: "PinCode",
    sx: disableTextField,
    variant: "outlined",
    name: "pincode",
    isTitle: "",
    defaultValue: "",
    gridProps: { xs: 12, sm: 4 },
  },
  {
    id: 19,
    field: "Autocomplete1",
    type: "autocomplete",
    isDisabled: false,
    placeholder: "Referrer",
    sx: textfieldText,
    variant: "outlined",
    name: "referrer",
    isTitle: "",
    options: [],
    allowAddNew: false,
    gridProps: { xs: 12, sm: 12 },
  },
];

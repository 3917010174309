import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getAllMedicineSaltPharmacy } from "../connections/getApi";
import { setError, setLoading } from "../redux/slices/prescriptionSlice";

const UseMedicineSaltPharmacy = () => {

    const dispatch = useDispatch();
    return useQuery({
        queryKey: ["getAllMedicineSaltPharmacy"],
        queryFn: getAllMedicineSaltPharmacy,
        // enabled: !!,
        onError: (error) => {
            console.error("Error in get Medicine lists :", error);
            dispatch(setError(true));
            dispatch(setLoading(false));
        },
        onSuccess: (data) => {
            console.log("Successfully fetched medicines list:", data);
        },
        onSettled: () => {
            dispatch(setLoading(false));
        },
        // staleTime: Infinity, // Keep the data fresh indefinitely
        //cacheTime: 1000 * 60 * 60, / / Cache the data for 1 hour
        // refetchOnWindowFocus: false, // Don't refetch when window regains focus
        // refetchOnReconnect: false, // Don't refetch when reconnecting
        // refetchOnMount: false, // Don't refetch on component mount
    });
};

export default UseMedicineSaltPharmacy;



export const tableRowLab = {
  backgroundColor: "neutral.table_row_primary",
  textAlign: 'center',
  cursor:'pointer',
  p: 0,
  borderRadius:'20px',
  transition: 'box-shadow 0.3s ease-in-out', 
  '&:hover': {
    // boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    boxShadow: 'rgb(204, 219, 232) 1px 1px 2px 0px , rgba(255, 255, 255, 0.5) -3px -3px 3px 1px',
    // boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset',
  }
};

export const tableHeadCell={
  fontSize: "14px", 
  fontWeight: "bold" ,  
  textAlign: 'center',
  p:"8px"
}

export const tableCellLab={
   p:"4px",
  //  pl: "16px" ,
   textAlign: 'center',
}

export const addNewReportBg={
  bgcolor :'neutral.navyBlue' ,
  fontSize :'13px',
  color:'white',
  cursor :'pointer',
  p:"7px",

  paddingX:'20px',
  borderRadius :"30px",
  fontWeight :400,
  transition: 'bgcolor 0.3s ease-in-out', 
  '&:hover' :{
     bgcolor :'#0056d5',
  }
}

export const rowPreview={
  backgroundColor: "rgba(235, 235, 235, 0.3);",
  px:'-5px',
  Margin :'-10px',
  transition: 'box-shadow 0.3s ease-in-out', 
  '&:hover': {
  boxShadow: 'box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;', 
  }

}


export const labPageButton = {
mt: 3,
mb: 2,
color: "black",
backgroundColor: "neutral.olive",
textTransform: "capitalize",
boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px",
"&:hover": {
  backgroundColor: "neutral.olive",
},
};

export const labPageWrapper  = {
marginTop: "7rem",
display: "flex",
justifyContent: "space-between",
padding: "0rem 1rem",
flexWrap:"wrap",
// backgroundColor:'#F7F7FF',
}

export const startBtn = {
margin :"0px",
// paddingY:'10px',
borderRadius :'10px',
backgroundColor: "neutral.main",
textTransform: "capitalize",
color :'white',
boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px",
width: "auto",
minWidth: "0px",
"&:hover": {
  backgroundColor: "neutral.main",
  color: "black",
},
};


export const collectBtn = {

  margin :"0px",
  borderRadius :'15px',
  backgroundColor: "neutral.navyBlue",
  textTransform: "capitalize",
  color :'white', 
  fontWeight :'500',
  boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  width: "auto",
  cursor :'pointer', 
  minWidth: "0px",
  "&:hover": {
    backgroundColor: "neutral.navyBlue",
  },
  };
  

export const xBtn={
  margin :"0px",
  padding :'1px',
  backgroundColor: "#FF392B",
  color :'white',
  borderRadius :'5px',
  textTransform: "capitalize",
  boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px",
  "&:hover": {
      backgroundColor: "#c92662",
    },
}

export const headingStyle = {
flexGrow: 1,
textAlign: "center",
// fontSize: "1.7rem",
};
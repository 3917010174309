import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  FormControl,
  TextField,
  IconButton,
  MenuItem,
  Autocomplete,
  Checkbox,
  Popper,
  InputAdornment,
  Modal,
  Chip,
  Stack,
} from "@mui/material";
import {
  headingStyle,
  labPageButton,
  tableCellLab,
  tableRowLab,
  xBtn,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import {
  dateFiled,
  menuItemStyle,
  primaryBtn,
  selectField,
  textfieldText,
  textfieldTextBlueBorder,
  textfieldTextBlueBorderPharmacy,
} from "../../style/AppointmentStyle";
import Select from "react-select";
import SearchIcon from "@mui/icons-material/Search";
import { drTableRow } from "../../style/DrApotComponentStyle";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { addBtn, primaryBtnPharmacy, textfieldTextBlueBr } from "../../style/PharmacyStyle";
import {
  setSearchPatient,
  setPatientDetailsData,
  setLoading,
} from "../../redux/slices/pharmacySlice";
import { useDispatch, useSelector } from "react-redux";
import { orangeButton, primaryBtnBlue, textfieldTextPrep } from "../../style/PatientDetailsStyle";
import UseSearchPatient from "../../helper/UseSearchPatient";
import UsePatientDetail from "../../helper/UsePatientDetail";
import useDebounce from "../../hooks/UseDebounce";
import UseGetMedicineList from "../../helper/UseGetMedicineList";
import UseMedicineByName from "../../helper/UseMedicineByName";
import UseGetMedicineDetail from "../../helper/UseGetMedicineDetail";
import {
  getMedicineByName,
  getPriceByQuantity,
  getPriceByQuantityForReturn,
} from "../../connections/getApi";
import PharmacyInvoice from "../PrintCompoents/PharmacyInvoice";
import { useReactToPrint } from "react-to-print";
import { generateBill } from "../../connections/postApi";
import { showToast } from "../../utils/toastUtil";
import UseGetAllMedicineList from "../../helper/UseGetAllMedicineList";
import { Link } from "react-router-dom";
import calender_icon from '../../assets/calender_icon.svg';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { autoFocusOffWithOutBorder, drTableRowBlueBg } from "../../style/PatinetFormStyle";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import updownar from "../../assets/updownar.svg";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { DeleteForeverRounded } from "@mui/icons-material";
import json_icon from '../../assets/json_icon.svg';
import csv_icon from '../../assets/csv_icon.svg';
import CustomModal from "../CustomModal/CustomModal";
import PurchaseOrderDetails from "./PurchaseOrderDetails";
import EditPurchaseOrder from "./EditPurchaseOrder";
import NewPurchaseOrder from "./NewPurchaseOrder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditMedicines from "./EditMedicines";
import medicine_salt from '../../assets/medicine_salt.svg'
import UseGetallMedicalTestAndComposite from "../../helper/UseGetallMedicalTestAndComposite";

const EditMedicineSalt = ({ onClose }) => {

  // console.log(medicines); 
  const [filterModal, setFilterModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [editPurchaseOrderModal, setEditPurchaseOrderModal] = useState(false);
  const [newPurchaseOrderModal, setNewPurchaseOrderModal] = useState(false);
  const { data: medicalTestDataList } = UseGetMedicineList('a');

  const AllMedicalTestOptions = medicalTestDataList?.payload?.medicines || [];

  const formattedMedicalTestOptions = AllMedicalTestOptions.map(([id, name]) => ({
    value: id.toString(), // Convert id to string
    label: name,          // Use name as the label
  }));



  const [formState, setFormState] = useState({
    name: "",
    medicine: [],

  })

  const handleChange = (name, value) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Box sx={{ width: "100%", display: 'flex', height: "90vh", flexDirection: 'column', justifyContent: "space-between" }}>



        {/* //modals for delete and filter */}
        <Modal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          sx={{
            position: 'absolute',
            top: '25%',
            width: "450px",
            margin: 'auto',
            borderRadius: '20px',
          }}
        >

          <Box
            sx={{
              mt: 2,
              bgcolor: "background.paper",
              borderRadius: 3.6,
              boxShadow: 24,
              p: 4.5,
              "&:focus": {
                outline: "none",
              },


            }}
          >

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography textAlign={'center'}>Are you sure?</Typography>
              <Typography textAlign={'center'}>You want to delete these selected purchase orders?</Typography>

            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'flex', gap: '25px', width: 'max-content', margin: 'auto', marginTop: "40px" }}>
              <Button sx={{
                backgroundColor: "#FF392B", color: "white", border: '', width: "150px", borderRadius: "5px", fontSize: "12px", textTransform: 'capitalize', '&:hover': {
                  backgroundColor: "red",
                }
              }}>Delete</Button>
              <Button onClick={() => setDeleteModal(false)} sx={{ backgroundColor: "white", color: "black", border: '2px solid #FF392B', width: "150px", borderRadius: "5px", fontSize: "12px", textTransform: 'capitalize' }}>Cancel</Button>
            </Box>

          </Box>
        </Modal>

        <Modal
          open={filterModal}
          onClose={() => setFilterModal(false)}
          sx={{
            position: 'absolute',
            top: '14%',
            // left:'50%',
            width: "340px",
            // height:"400px",
            margin: 'auto',
            //  display: "flex",
            //  alignItems: "flex-start",
            //  justifyContent: "center",
            //  overflow: "auto",
            borderRadius: '20px',
          }}
        >

          <Box
            sx={{
              mt: 2,
              bgcolor: "background.paper",
              borderRadius: '20px',
              boxShadow: 24,
              p: 1,
              "&:focus": {
                outline: "none",
              },
            }}
          >

            <Grid container spacing={2} sx={{ padding: '9px' }}>
              <Grid item xs={12}>
                <Typography variant="subTitle116px" fontWeight={'600'} textAlign={'center'} margin={'auto'}>Filters</Typography>
              </Grid>


              <Grid item xs={12}>

                <Select fullWidth defaultValue="" placeholder="Select Filter" sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Ensure border remains none on focus
                  },
                  "& .MuiSelect-select": {
                    padding: "9px",
                    borderRadius: "15px",
                    backgroundColor: "#F7F7FF",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                  }
                }}

                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <span style={{ color: "text.default_grey" }}>
                          Select filter
                        </span>
                      );
                    }
                    return selected === "filter1"
                      ? "filter2"
                      : selected === "filter3"
                        ? "filter3"
                        : "select filter";
                  }}
                >
                  <MenuItem value="filter1">Filter 1</MenuItem>
                  <MenuItem value="filter2">Filter 2</MenuItem>
                  <MenuItem value="filter3">Filter 3</MenuItem>
                </Select>
              </Grid>

              {/* Purchase Date */}
              <Grid item xs={12}>
                <Typography variant="subTitle116px">Purchase Date</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="From"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="To"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Department Selection */}
              <Grid item xs={12}>
                <Typography variant="subTitle116px">Department</Typography>
                <Select fullWidth defaultValue="" sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Ensure border remains none on focus
                  },
                  "& .MuiSelect-select": {
                    padding: "9px",
                    borderRadius: "15px",
                    backgroundColor: "#F7F7FF",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                  }
                }}>
                  <MenuItem value="dept1">Department 1</MenuItem>
                  <MenuItem value="dept2">Department 2</MenuItem>
                  <MenuItem value="dept3">Department 3</MenuItem>
                </Select>
              </Grid>

              {/* Created At */}
              <Grid item xs={12}>
                <Typography variant="subTitle116px">Created At</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="From"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="To"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Buttons */}
              <Grid item xs={6}>
                <Button fullWidth variant="subTitle116px16px" sx={{ ...primaryBtnBlue, color: 'white' }}>Apply</Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={() => setFilterModal(false)} fullWidth variant="subTitle116px16px" sx={{ ...primaryBtn, color: 'white', border: 'none' }}>Clear Filters</Button>
              </Grid>
            </Grid>



          </Box>
        </Modal>


        <Box sx={{
          overflowY: 'auto', maxHeight: "80vh", '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}  >

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
          >
            <Box></Box>


            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: "5px" }} >
              <img src={medicine_salt} alt='' sx={{ color: 'neutral.navyBlue', marginBottom: "4px", marginRight: '14px' }} />
              <Typography
                variant="heading_Large"
                fontWeight={700}
                gutterBottom
                sx={{ ...headingStyle }}
              >
                New  Medicine salt
              </Typography>
            </Box>
            <IconButton sx={{ marginBottom: "1rem" }} onClick={onClose}>
              <CloseIcon size="small" sx={{ color: "red" }} />
            </IconButton>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
            // paddingX="25px"
            width='96%'
            gap='60px'
            margin='auto'
            marginBottom='30px'>
            <Typography> Name </Typography>

            <TextField placeholder="Name" fullWidth sx={{ ...autoFocusOffWithOutBorder }} />



          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
            // paddingX="25px"
            width='96%'
            gap='30px'
            margin='auto'
            marginBottom='30px'
            sx={{ zIndex: 99999 }} item sm={3}>

            <Typography> Medicines </Typography>


            <Select
              options={formattedMedicalTestOptions}
              value={formState?.medicine?.map((test) => ({
                value: test,
                label: formattedMedicalTestOptions.find(
                  (option) => option.value === test
                )?.label,
              }))}
              isMulti
              isSearchable
              placeholder="Medicine"
              className="react-select"
              classNamePrefix="select"
              onChange={(selectedOptions) => {
                // Convert selectedOptions to an array of values
                const selectedValues = Array.isArray(selectedOptions)
                  ? selectedOptions.map((option) => option.value)
                  : [];
                handleChange("medicine", selectedValues); // Update formState with values only
              }}
              styles={{
                width: "100%",
                backgroundColor: "#F7F7FF",
                flexGrow: 1,
                container: (provided) => ({
                  ...provided,
                  flexGrow: 1, // Take up the remaining space
                }),

                // Menu z-index to avoid overlap issues
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999999,
                  fontFamily: "Outfit",
                }),
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 999999,
                }),
                // Control styles for the Select input box
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? "#2e37a4"
                    : "rgba(46, 55, 164, 0.1)",
                  boxShadow: state.isFocused ? "0 0 0 1px #2e37a4" : "none",
                  // "&:hover": {
                  //   borderColor: "#2e37a4",
                  // },
                  backgroundColor: "#F7F7FF",
                  borderRadius: "15px",
                  fontSize: "14px",
                  width: "100%",
                  minHeight: "45px",
                  zIndex: 999999,
                  fontFamily: "Outfit"
                }),
                // Dropdown indicator for animation and styling
                dropdownIndicator: (base, state) => ({
                  ...base,
                  fontFamily: 'Outfit',
                  transform: state.selectProps.menuIsOpen
                    ? "rotate(-180deg)"
                    : "rotate(0)",
                  transition: "transform 250ms ease",
                  // width: "35px",
                  // height: "35px",
                }),
              }}
              menuPortalTarget={document.body}
            />
          </Box>



        </Box>
        <Box
          container
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ zIndex: "10" }}
          lg={12}
          marginBottom={"5px"}
        >
          <Grid item></Grid>
          <Grid item>
            <Button

              className="btn draw-border"
              variant="contained"
              id="submit"
              sx={{
                ...primaryBtnBlue,
                width: "195px",
                display: "flex",

                alignItems: "center",
                justifyContent: "center",
              }}
            //   disabled={isLoading}
            >
              <Typography
                textTransform={"capitalize"}
                color={"neutral.white"}
                variant="title1_medium"
              >
                Update  Medicine Salt
              </Typography>
            </Button>
          </Grid>
        </Box>

      </Box >
    </>
  );
};

export default EditMedicineSalt;

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
} from "@mui/material";
import { drTableRow } from "../../style/DrApotComponentStyle";
import { postPrintLabTest } from "../../connections/postApi";

const ComponentToPrint = React.forwardRef(
  ({ reportData, patientData, selectedDate }, ref) => {
    const [printData, setPrintData] = useState();
    const [organization, setOrganization] = useState({});
    console.log(reportData, "report data");
    const handlePrint = async (item) => {
      const testIdsString =
        reportData?.length > 0 &&
        reportData
          ?.map((test) => test.medical_test_id)
          ?.filter(Boolean)
          ?.join(",");

      const payload = {
        id: reportData[0]?.patient_id,
        mobile: patientData?.mobile,
        paid_date: selectedDate,
        TestIdS: testIdsString,
      };

      if (testIdsString) {
        const result = await postPrintLabTest(payload);
        // console.log(result,'result')
        setOrganization(result?.meta?.organization);
        setPrintData(result?.payload);
      }
    };

    const getMarginTopForPageType = (organizationSettings, pageType) => {
      const setting = organizationSettings?.find(
        (setting) => setting?.page_type === pageType
      );
      return setting;
    };

    const orgSettingLab = getMarginTopForPageType(
      organization?.organization_settings,
      "lab"
    );

    useEffect(() => {
      handlePrint();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData]);

    const compositesArray = printData?.medical_test_reports
      ?.map((report) => {
        // Check if the report contains a composites object
        if (report.reports.composites) {
          return {
            id: report.reports.composites.id,
            patient: report?.patient,
            test_name_main: report?.reports?.test_name,
            status: report.reports.composites.status,
            generated_at: report.reports.composites.generated_at,
            patient_id: report.reports.composites.patient_id,
            medical_test_category_id:
              report.reports.composites.medical_test_category_id,
            lab_id: report.reports.composites.lab_id,
            prescription_id: report.reports.composites.prescription_id,
            test_name: report.reports.composites.test_name,
            medical_test_id: report.reports.composites.medical_test_id,
            invoice_item_id: report.reports.composites.invoice_item_id,
            value: report.reports.composites.value,
            name: report.reports.composites.name,
            reference_range: {
              male_min_max:
                report.reports.composites.reference_range.male_min_max,
              female_min_max:
                report.reports.composites.reference_range.female_min_max,
              unit: report.reports.composites.reference_range.unit,
            },
          };
        }
        return null;
      })
      .filter((composite) => composite !== null); // Filter out null values

    // console.log(compositesArray);
    // console.log(printData?.medical_test_reports);

    const formatDate = () => {
      const date = new Date();
      // Options for the date format: MM/DD/YY, h:mm AM/PM
      const options = {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      };

      return date.toLocaleString("en-US", options);
    };

    return (
      <Box
        ref={ref}
        sx={{
          width: "97%",
          // marginBottom: `${
          //   orgSettingLab?.footer_height && orgSettingLab?.is_active
          //     ? orgSettingLab?.footer_height
          //     : "2"
          // }in`,
          // marginTop: `${
          //   orgSettingLab?.header_height && orgSettingLab?.is_active
          //     ? orgSettingLab?.header_height
          //     : "3"
          // }in`,
          display: "none",
          "@page": {
            marginTop: `${orgSettingLab?.header_height && orgSettingLab?.is_active
              ? orgSettingLab?.header_height
              : "2"
              }in`,
            marginBottom: `${orgSettingLab?.footer_height && orgSettingLab?.is_active
              ? orgSettingLab?.footer_height
              : "1"
              }in`,
          },

          "@media print": {
            display: "block",
            "& table": {
              pageBreakInside: "avoid",
              //  marginTop: "2in", // Adjust based on header margin
              // marginBottom: "1in", // Adjust based on footer margin
            },
          },
        }}
      >

        <Typography variant="heading_Large">{`${patientData?.name} (${patientData?.uid})`}</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px 2px 5px 2px",
          }}
        >
          <Typography sx={{ fontWeight: "bold" }} fontSize={"14px"}>
            {patientData?.age === 0 ? 1 : patientData?.age} /{" "}
            {patientData?.gender}{" "}
          </Typography>
          <Typography sx={{ fontWeight: "bold" }} fontSize={"14px"}>
            {patientData?.name}
          </Typography>
          <Typography sx={{ fontWeight: "bold" }} fontSize={"14px"}>
            Mob: {patientData?.mobile}
          </Typography>
          <Typography
            sx={{ fontWeight: "bold" }}
            fontSize={"14px"}
          >{`Report Date : ${selectedDate}`}</Typography>
        </Box>
        <Table
          sx={{
            border: "1px solid lightgrey",
            borderBottom: "1.9px solid grey",
            width: "100%",
            mb: "1rem !important",
          }}
        >
          {printData?.medical_test_reports?.some(report => !report?.reports?.composites) && (
            <TableHead>
              {/* <TableRow sx={{ ...drTableRow }}> */}
              <TableRow>
                <TableCell
                  align="center" // Use align prop for proper text alignment
                  sx={{ fontWeight: "bold", width: "34%", padding: "6px" }}
                >
                  Test Name
                </TableCell>
                <TableCell
                  align="center" // Use align prop for proper text alignment
                  sx={{ fontWeight: "bold", width: "30%", padding: "6px" }}
                >
                  Result
                </TableCell>
                <TableCell
                  align="center" // Use align prop for proper text alignment
                  sx={{ fontWeight: "bold", width: "30%", padding: "6px" }}
                >
                  Reference Range
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {printData?.medical_test_reports?.length > 0 &&
              printData?.medical_test_reports?.map((report, index) =>

                report?.reports?.composites ? null : (
                  <TableRow key={index} sx={{ width: "100%" }}>
                    <TableCell
                      align="center" // Use align prop for proper text alignment
                      sx={{ width: "35%", padding: "4px" }}
                    >
                      {report?.reports?.test_name}
                    </TableCell>
                    <TableCell
                      align="center" // Use align prop for proper text alignment
                      sx={{ width: "32%", padding: "4px" }}
                    >
                      {report?.reports?.value}
                    </TableCell>
                    <TableCell
                      align="center" // Use align prop for proper text alignment
                      sx={{ width: "32%", padding: "4px" }}
                    >
                      {report?.patient?.gender === "Female"
                        ? report?.reports?.reference_range?.female_min_max
                        : report?.reports?.reference_range?.male_min_max}
                    </TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
        {/* {compsite table{}} */}
        {compositesArray?.length > 0 &&
          [
            ...new Set(compositesArray?.map((item) => item?.test_name_main)),
          ]?.map((testNameMain, index) => (
            <React.Fragment key={index}>
              {/* Render a table for each unique test_name_main */}
              <Table
                sx={{
                  marginTop: "100px",
                  border: "1px solid lightgrey",
                  borderBottom: "1.9px solid grey",
                  textAlign: "center",
                  width: "100%",
                  mb: "1rem !important",
                }}
              >
                <TableBody>
                  {/* Header Row */}
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <Typography sx={{ fontWeight: "bold" }}>
                        {testNameMain}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Filter rows based on test_name_main */}
                  {compositesArray
                    ?.filter(
                      (report) => report?.test_name_main === testNameMain
                    )
                    ?.map((report, idx) => (
                      <TableRow key={idx}>
                        <TableCell
                          sx={{
                            width: "33%",
                            padding: "4px",
                            textAlign: "center",
                          }}
                        >
                          {report?.test_name}
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "33%",
                            padding: "4px",
                            textAlign: "center",
                          }}
                        >
                          {report?.value}
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "33%",
                            padding: "4px",
                            textAlign: "center",
                          }}
                        >
                          {report?.patient?.gender === "Female"
                            ? report?.reference_range?.female_min_max
                            : report?.reference_range?.male_min_max}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </React.Fragment>
          ))}
        <Typography
          variant="subtitle1"
          fontSize={"14px"}
          fontWeight={"600"}
          mt={-1}
        >
          Printed at {formatDate()}
        </Typography>
      </Box>
    );
  }
);

export default ComponentToPrint;

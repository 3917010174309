import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getMedicineBatch } from "../connections/getApi";
import { setError, setLoading } from "../redux/slices/appointmentSlice";

const UseGetBatchDetail = (id) => {

    const dispatch = useDispatch();

    return useQuery({
        queryKey: ["getMedicineBatch", id],
        queryFn: () => getMedicineBatch(id),
        enabled: !!id,
        cacheTime: 0, // Cache the data for 1 hour
        refetchOnWindowFocus: false, // Don't refetch when window regains focus
        onError: () => {
            dispatch(setError(true));
            dispatch(setLoading(false));
        },
        onSettled: () => {
            dispatch(setLoading(false));
        },
    });
};

export default UseGetBatchDetail;

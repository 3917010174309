import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Modal,
} from "@mui/material";
import {
  headingStyle,
  tableRowLab,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import {
  primaryBtn,
} from "../../style/AppointmentStyle";
import { primaryBtnBlue } from "../../style/PatientDetailsStyle";
import { showToast } from "../../utils/toastUtil";
import { autoFocusOffWithOutBorder } from "../../style/PatinetFormStyle";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { DeleteForeverRounded } from "@mui/icons-material";
import json_icon from '../../assets/json_icon.svg';
import csv_icon from '../../assets/csv_icon.svg';
import CustomModal from "../CustomModal/CustomModal";
import EditPurchaseOrder from "./EditPurchaseOrder";
import NewPurchaseOrder from "./NewPurchaseOrder";
import medicines_icon from '../../assets/medicine.svg'
import EditMedicines from "./EditMedicines";
import UseFetchMedicineDetailsAdmin from "../../helper/UseFetchMedicineDetailsAdmin";
import formattedDate, { formattedDateWithoutBg } from '../../utils/constantFunctions'
import { deleteMedicine } from "../../connections/deleteApi";
import { useNavigate } from "react-router-dom";

const MedicinesDetails = ({ onClose, id, refetch, page, itemPerPage }) => {
  const { data: medicineDetails } = UseFetchMedicineDetailsAdmin(id);

  //  console.log(medicineDetails);
  const [filterModal, setFilterModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [editPurchaseOrderModal, setEditPurchaseOrderModal] = useState(false);
  const [newPurchaseOrderModal, setNewPurchaseOrderModal] = useState(false);

  const handleDelete = async (id) => {
    setDeleteModal(false);
    const res = await deleteMedicine(id);
    console.log(res);
    showToast(res?.status?.message || res?.status?.status, res?.status?.status);
    if (res?.status?.status === 'SUCCESS') {
      refetch(page, itemPerPage);
      onClose();
    }
  }

  // console.log(medicines);
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ width: "100%", display: 'flex', height: "90vh", flexDirection: 'column', justifyContent: "space-between" }}>
        {/* //modals for delete and filter */}

        <Modal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          sx={{
            position: 'absolute',
            top: '25%',
            // left:'50%',
            width: "450px",
            // height:"400px",
            margin: 'auto',
            //  display: "flex",
            //  alignItems: "flex-start",
            //  justifyContent: "center",
            //  overflow: "auto",
            borderRadius: '20px',
          }}
        >

          <Box
            sx={{
              mt: 2,
              bgcolor: "background.paper",
              borderRadius: 3.6,
              boxShadow: 24,
              p: 4.5,
              "&:focus": {
                outline: "none",
              },


            }}
          >

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography textAlign={'center'}>Are you sure?</Typography>
              <Typography textAlign={'center'}>You want to delete these selected purchase orders?</Typography>

            </Box>

            <Box onClick={() => handleDelete(id)} sx={{ display: 'flex', flexDirection: 'flex', gap: '25px', width: 'max-content', margin: 'auto', marginTop: "40px" }}>
              <Button sx={{
                backgroundColor: "#FF392B", color: "white", border: '', width: "150px", borderRadius: "5px", fontSize: "12px", textTransform: 'capitalize', '&:hover': {
                  backgroundColor: "red",
                }
              }}>Delete</Button>
              <Button onClick={() => setDeleteModal(false)} sx={{ backgroundColor: "white", color: "black", border: '2px solid #FF392B', width: "150px", borderRadius: "5px", fontSize: "12px", textTransform: 'capitalize' }}>Cancel</Button>
            </Box>

          </Box>
        </Modal>

        <Modal
          open={filterModal}
          onClose={() => setFilterModal(false)}
          sx={{
            position: 'absolute',
            top: '14%',
            // left:'50%',
            width: "340px",
            // height:"400px",
            margin: 'auto',
            //  display: "flex",
            //  alignItems: "flex-start",
            //  justifyContent: "center",
            //  overflow: "auto",
            borderRadius: '20px',
          }}
        >

          <Box
            sx={{
              mt: 2,
              bgcolor: "background.paper",
              borderRadius: '20px',
              boxShadow: 24,
              p: 1,
              "&:focus": {
                outline: "none",
              },


            }}
          >

            <Grid container spacing={2} sx={{ padding: '9px' }}>
              <Grid item xs={12}>
                <Typography variant="subTitle116px" fontWeight={'600'} textAlign={'center'} margin={'auto'}>Filters</Typography>
              </Grid>



              {/* <Grid item xs={12}>
        <Typography  variant="subTitle116px" >Suppliers</Typography>
      </Grid> */}

              {/* Filter Selection */}

              <Grid item xs={12}>

                <Select fullWidth defaultValue="" placeholder="Select Filter" sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Ensure border remains none on focus
                  },
                  "& .MuiSelect-select": {
                    padding: "9px",
                    borderRadius: "15px",
                    backgroundColor: "#F7F7FF",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                  }
                }}

                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <span style={{ color: "text.default_grey" }}>
                          Select filter
                        </span>
                      );
                    }
                    return selected === "filter1"
                      ? "filter2"
                      : selected === "filter3"
                        ? "filter3"
                        : "select filter";
                  }}
                >
                  <MenuItem value="filter1">Filter 1</MenuItem>
                  <MenuItem value="filter2">Filter 2</MenuItem>
                  <MenuItem value="filter3">Filter 3</MenuItem>
                </Select>
              </Grid>

              {/* Purchase Date */}
              <Grid item xs={12}>
                <Typography variant="subTitle116px">Purchase Date</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="From"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="To"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Department Selection */}
              <Grid item xs={12}>
                <Typography variant="subTitle116px">Department</Typography>
                <Select fullWidth defaultValue="" sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Ensure border remains none on focus
                  },
                  "& .MuiSelect-select": {
                    padding: "9px",
                    borderRadius: "15px",
                    backgroundColor: "#F7F7FF",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                  }
                }}>
                  <MenuItem value="dept1">Department 1</MenuItem>
                  <MenuItem value="dept2">Department 2</MenuItem>
                  <MenuItem value="dept3">Department 3</MenuItem>
                </Select>
              </Grid>

              {/* Created At */}
              <Grid item xs={12}>
                <Typography variant="subTitle116px">Created At</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="From"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="To"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Buttons */}
              <Grid item xs={6}>
                <Button fullWidth variant="subTitle116px16px" sx={{ ...primaryBtnBlue, color: 'white' }}>Apply</Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={() => setFilterModal(false)} fullWidth variant="subTitle116px16px" sx={{ ...primaryBtn, color: 'white', border: 'none' }}>Clear Filters</Button>
              </Grid>
            </Grid>



          </Box>
        </Modal>


        <Box sx={{
          overflowY: 'auto', maxHeight: "80vh", '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}  >

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
          >
            <Box></Box>


            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              <img src={medicines_icon} alt='' sx={{ color: 'neutral.navyBlue', marginBottom: "4px", marginRight: '8px' }} />
              <Typography
                variant="heading_Large"
                fontWeight={700}
                gutterBottom
                sx={headingStyle}
              >
                Medicine Details
              </Typography>
            </Box>
            <IconButton sx={{ marginBottom: "1rem" }} onClick={onClose}>
              <CloseIcon size="small" sx={{ color: "red" }} />
            </IconButton>
          </Box>

          <Box
            mt={-1}

            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingX="25px"
          >
            <Box >
            </Box>


            <Box sx={{ display: 'flex', gap: '8px', marginRight: "-8px" }}>
              <EditRoundedIcon onClick={() => {
                setEditPurchaseOrderModal(true)
                navigate("/pharmacy",
                  { state: medicineDetails?.payload })

              }} sx={{ backgroundColor: "#FF8D1E", p: '2px', width: "20px", color: 'white', borderRadius: "8px", cursor: "pointer" }} />
              {/* <DeleteForeverRounded onClick={()=>{setDeleteModal(true)}} sx={{backgroundColor:"#FF392B" ,p:'2px',  width:"18px", color:"white" , borderRadius:"8px" ,cursor:"pointer"}}/> */}
            </Box>
          </Box>


          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "96%", margin: 'auto', marginTop: "10px" }}>
            <Box sx={{ display: "flex", gap: '22px', width: '25%' }}> <Typography variant="heading2_14"> Name</Typography> <Typography>  {medicineDetails?.payload?.name}</Typography> </Box>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', justifyContent: 'flex-end' }}> <Typography variant="heading2_14"> Company  </Typography> <Typography> {medicineDetails?.payload?.company} </Typography></Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "96%", margin: 'auto', marginTop: "12px" }}>
            <Box sx={{ display: "flex", gap: '22px', width: '25%' }}> <Typography variant="heading2_14">  Flat price  </Typography> <Typography>    {medicineDetails?.flat_price}</Typography> </Box>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', justifyContent: "center" }}><Typography variant="heading2_14"> Discount  </Typography> <Typography>  {medicineDetails?.payload?.discount} </Typography> </Box>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', justifyContent: "center" }}> <Typography variant="heading2_14"> SRP </Typography> <Typography>  {medicineDetails?.payload?.srp}</Typography></Box>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', justifyContent: 'flex-end' }}><Typography variant="heading2_14">  Stock Left  </Typography> <Typography>   {medicineDetails?.payload?.total_stock_left} </Typography> </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "96%", margin: 'auto', marginTop: "12px", marginBottom: '14px' }}>
            <Box sx={{ display: "flex", gap: '22px', width: '25%' }}> <Typography variant="heading2_14"> Auto Purchase  </Typography> <Typography>   {medicineDetails?.payload?.auto_purchase_threshold}</Typography> </Box>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', justifyContent: "center" }}><Typography variant="heading2_14"> Medicine salt  </Typography> <Typography>{medicineDetails?.payload?.medicine_salt?.name} </Typography> </Box>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', justifyContent: "center" }}><Typography variant="heading2_14">  HSN name </Typography> <Typography>{medicineDetails?.payload?.hsn_name}</Typography> </Box>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', justifyContent: 'flex-end' }}><Typography variant="heading2_14"> Updated at  </Typography> <Typography> {formattedDateWithoutBg(medicineDetails?.payload?.updated_at?.split(' ')[0])}  {medicineDetails?.payload?.updated_at?.split(' ')[1]} </Typography> </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "96%", margin: 'auto', marginTop: "12px", marginBottom: '14px' }}>
            <Box sx={{ display: "flex", gap: '22px', width: '25%' }}> <Typography variant="heading2_14"> GST  </Typography> <Typography>   {medicineDetails?.payload?.gst} </Typography> </Box>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', justifyContent: "center" }}><Typography variant="heading2_14">  CGST  </Typography> <Typography>   {medicineDetails?.payload?.cgst} </Typography> </Box>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', justifyContent: "center" }}><Typography variant="heading2_14">   SGST </Typography> <Typography> {medicineDetails?.payload?.sgst}</Typography> </Box>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', justifyContent: 'flex-end' }}><Typography variant="heading2_14"> Created at  </Typography> <Typography> {formattedDateWithoutBg(medicineDetails?.payload?.created_at?.split(' ')[0])}  {medicineDetails?.payload?.created_at?.split(' ')[1]} </Typography> </Box>
          </Box>



          <Typography variant="heading2_14" sx={{ marginLeft: "18px" }} >
            Items
          </Typography>

          <Grid item xs={12}>
            <TableContainer sx={{ mt: 2, width: "100%" }}>
              <Table sx={{ width: "100%" }}>
                <TableHead stickyHeader >
                  <TableRow sx={{ textAlign: "center", }}>


                    <TableCell
                      variant="body1_text_bold"
                      sx={{

                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      S no.
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "8px",
                        //  paddingX:"4px",
                        textAlign: "center",
                        width: "200px",
                      }}
                    >
                      Batch
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        width: "130px",
                        paddingRight: "30px",
                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Flat price
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Form type
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Expiry
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Packing
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Stock left
                    </TableCell>


                  </TableRow>

                </TableHead>

                {medicineDetails?.payload?.medicine_batches?.length > 0 ? (
                  <TableBody>

                    {medicineDetails?.payload?.medicine_batches?.map((batch, index) =>
                      <TableRow sx={{
                        ...tableRowLab, textAlign: "center",
                        borderTop: '2px solid white',
                        borderBottom: '6px solid white',
                      }} key={index}>
                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            color: 'neutral.black',
                            p: "8px",
                            textAlign: "center",
                            borderTopLeftRadius: '20px',
                            borderBottomLeftRadius: '20px',
                          }}
                        >
                          <Typography variant="body_Grey1"> {index + 1}</Typography>
                        </TableCell>
                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            color: 'neutral.black',
                            p: "8px",
                            //  paddingX:"4px",
                            textAlign: "center",
                            width: "200px",
                          }}
                        >
                          <Typography variant="body_Grey1"> {batch.batch_no}

                          </Typography>
                        </TableCell>

                        <TableCell

                          variant="body1_text_bold"
                          sx={{
                            width: "130px",
                            paddingRight: "30px",
                            color: 'neutral.black',
                            p: "8px",
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="body_Grey1">  {batch?.amount}</Typography>
                        </TableCell>
                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            color: 'neutral.black',
                            p: "8px",
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="body_Grey1">  {batch?.purchase_cost} </Typography>
                        </TableCell>


                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            color: 'neutral.black',
                            p: "8px",
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="body_Grey1">{batch?.form_type}</Typography>
                        </TableCell>
                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            color: 'neutral.black',
                            p: "8px",
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="body_Grey1">{batch?.expiry_date}</Typography>
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            color: 'neutral.black',
                            p: "8px",
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="body_Grey1">{batch?.packing}</Typography>
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            color: 'neutral.black',
                            p: "8px",
                            textAlign: "center",
                            borderTopRightRadius: '20px',
                            borderBottomRightRadius: '20px',
                          }}
                        >
                          <Typography variant="body_Grey1">{batch?.stock_left}</Typography>
                        </TableCell>

                      </TableRow>
                    )}
                  </TableBody>
                ) : (
                  <TableRow>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>


          </Grid>
        </Box>


      </Box>

      <CustomModal
        customStyles={{ border: "none", minWidth: "85%", maxWidth: "92%" }}
        open={editPurchaseOrderModal}
        onClose={() => setEditPurchaseOrderModal(false)}
      >
        <EditMedicines onClose={() => setEditPurchaseOrderModal(false)} />
      </CustomModal>

      <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={newPurchaseOrderModal}
        onClose={() => setNewPurchaseOrderModal(false)}
      >
        <NewPurchaseOrder onClose={() => setNewPurchaseOrderModal(false)} />
      </CustomModal>
    </>
  );
};

export default MedicinesDetails;

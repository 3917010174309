import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Modal,
  Skeleton,
} from "@mui/material";
import {
  headingStyle,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import {
  primaryBtn,
} from "../../style/AppointmentStyle";
import { primaryBtnBlue } from "../../style/PatientDetailsStyle";
import { showToast } from "../../utils/toastUtil";
import { autoFocusOffWithOutBorder, drTableRowBlueBg } from "../../style/PatinetFormStyle";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { DeleteForeverRounded } from "@mui/icons-material";
import CustomModal from "../CustomModal/CustomModal";
import NewMedicineSalt from "./NewMedicineSalt";
import medicine_salt from '../../assets/medicine_salt.svg'
import EditMedicineSalt from "./EditMedicineSalt";
import UseFetchMedicineSaltDetails from "../../helper/UseFetchMedicineSaltDetails";
import { deleteMedicineSalt } from "../../connections/deleteApi";
import { formattedDateWithoutBg } from "../../utils/constantFunctions";
import UseFetchMedicalTestDetails from "../../helper/UseFetchMedicalTestDetails";

const MedicalTestDetails = ({ onClose, id, refetch, page, itemPerPage }) => {

  const { data: medicineDetails, isLoading } = UseFetchMedicalTestDetails(id);

  const [filterModal, setFilterModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [editPurchaseOrderModal, setEditPurchaseOrderModal] = useState(false);
  const [newPurchaseOrderModal, setNewPurchaseOrderModal] = useState(false);

  const handleDelete = async (id) => {
    setDeleteModal(false);
    const res = await deleteMedicineSalt(id);
    console.log(res);
    showToast(res?.status?.message || res?.status?.status, res?.status?.status);
    if (res?.status?.status === 'SUCCESS') {
      refetch(page, itemPerPage);
      onClose();
    }
  }
  console.log(medicineDetails);

  return (
    <>
      <Box sx={{ width: "100%", display: 'flex', height: "90vh", flexDirection: 'column' }}>

        {/* //modals for delete and filter */}

        <Modal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          sx={{
            position: 'absolute',
            top: '25%',
            // left:'50%',
            width: "450px",
            // height:"400px", 
            margin: 'auto',
            //  display: "flex",
            //  alignItems: "flex-start",
            //  justifyContent: "center",
            //  overflow: "auto",
            borderRadius: '20px',
          }}
        >

          <Box
            sx={{
              mt: 2,
              bgcolor: "background.paper",
              borderRadius: 3.6,
              boxShadow: 24,
              p: 4.5,
              "&:focus": {
                outline: "none",
              },


            }}
          >

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography textAlign={'center'}>Are you sure?</Typography>
              <Typography textAlign={'center'}>You want to delete these selected purchase orders?</Typography>

            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'flex', gap: '25px', width: 'max-content', margin: 'auto', marginTop: "40px" }}>
              <Button onClick={() => handleDelete(id)} sx={{
                backgroundColor: "#FF392B", color: "white", border: '', width: "150px", borderRadius: "5px", fontSize: "12px", textTransform: 'capitalize', '&:hover': {
                  backgroundColor: "red",
                }
              }}>Delete</Button>

              <Button onClick={() => setDeleteModal(false)} sx={{ backgroundColor: "white", color: "black", border: '2px solid #FF392B', width: "150px", borderRadius: "5px", fontSize: "12px", textTransform: 'capitalize' }}>Cancel</Button>
            </Box>

          </Box>
        </Modal>

        <Modal
          open={filterModal}
          onClose={() => setFilterModal(false)}
          sx={{
            position: 'absolute',
            top: '14%',
            // left:'50%',
            width: "340px",
            // height:"400px",
            margin: 'auto',
            //  display: "flex",
            //  alignItems: "flex-start",
            //  justifyContent: "center",
            //  overflow: "auto",
            borderRadius: '20px',
          }}
        >

          <Box
            sx={{
              mt: 2,
              bgcolor: "background.paper",
              borderRadius: '20px',
              boxShadow: 24,
              p: 1,
              "&:focus": {
                outline: "none",
              },


            }}
          >

            <Grid container spacing={2} sx={{ padding: '9px' }}>
              <Grid item xs={12}>
                <Typography variant="subTitle116px" fontWeight={'600'} textAlign={'center'} margin={'auto'}>Filters</Typography>
              </Grid>



              {/* <Grid item xs={12}>
        <Typography  variant="subTitle116px" >Suppliers</Typography>
      </Grid> */}

              {/* Filter Selection */}
              <Grid item xs={12}>

                <Select fullWidth defaultValue="" placeholder="Select Filter" sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Ensure border remains none on focus
                  },
                  "& .MuiSelect-select": {
                    padding: "9px",
                    borderRadius: "15px",
                    backgroundColor: "#F7F7FF",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                  }
                }}

                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <span style={{ color: "text.default_grey" }}>
                          Select filter
                        </span>
                      );
                    }
                    return selected === "filter1"
                      ? "filter2"
                      : selected === "filter3"
                        ? "filter3"
                        : "select filter";
                  }}
                >
                  <MenuItem value="filter1">Filter 1</MenuItem>
                  <MenuItem value="filter2">Filter 2</MenuItem>
                  <MenuItem value="filter3">Filter 3</MenuItem>
                </Select>
              </Grid>

              {/* Purchase Date */}
              <Grid item xs={12}>
                <Typography variant="subTitle116px">Purchase Date</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="From"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="To"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Department Selection */}
              <Grid item xs={12}>
                <Typography variant="subTitle116px">Department</Typography>
                <Select fullWidth defaultValue="" sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Ensure border remains none on focus
                  },
                  "& .MuiSelect-select": {
                    padding: "9px",
                    borderRadius: "15px",
                    backgroundColor: "#F7F7FF",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                  }
                }}>
                  <MenuItem value="dept1">Department 1</MenuItem>
                  <MenuItem value="dept2">Department 2</MenuItem>
                  <MenuItem value="dept3">Department 3</MenuItem>
                </Select>
              </Grid>

              {/* Created At */}
              <Grid item xs={12}>
                <Typography variant="subTitle116px">Created At</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="From"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="To"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Buttons */}
              <Grid item xs={6}>
                <Button fullWidth variant="subTitle116px16px" sx={{ ...primaryBtnBlue, color: 'white' }}>Apply</Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={() => setFilterModal(false)} fullWidth variant="subTitle116px16px" sx={{ ...primaryBtn, color: 'white', border: 'none' }}>Clear Filters</Button>
              </Grid>
            </Grid>



          </Box>
        </Modal>


        <Box sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}  >

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
          >
            <Box></Box>


            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: "5px" }} >
              <img src={medicine_salt} alt='' sx={{ color: 'neutral.navyBlue', marginBottom: "4px", marginRight: '14px' }} />
              <Typography
                variant="heading_Large"
                fontWeight={700}
                gutterBottom
                sx={{ ...headingStyle }}
              >
                Medical Test Details
              </Typography>
            </Box>
            <IconButton sx={{ marginBottom: "0rem" }} onClick={onClose}>
              <CloseIcon size="small" sx={{ color: "red" }} />
            </IconButton>
          </Box>

          <Box
            mt={1}
            mb={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
            paddingX="25px"
          >
            <Box sx={{ display: 'flex', gap: '20px', width: "100%" }}>


              <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                <Typography fontWeight={'400'}>
                  {/* {medicineDetails?.payload?.name} */}
                </Typography>

                <Typography>

                </Typography>
              </Box>
            </Box>


            <Box mt={-3} sx={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>

              {/* <EditRoundedIcon onClick={()=>{setEditPurchaseOrderModal(true)}}  sx={{ backgroundColor:"#FF8D1E" , p:'2px',  width:"20px", color:'white' , borderRadius:"8px" ,cursor:"pointer"}}/> */}
              {/* <DeleteForeverRounded onClick={()=>{setDeleteModal(true)}} sx={{backgroundColor:"#FF392B" ,p:'2px',  width:"18px", color:"white" , borderRadius:"8px" ,cursor:"pointer"}}/> */}
            </Box>
          </Box>

          <Grid item xs={12}>


          </Grid>
        </Box>

        <Box mt={0} sx={{
          display: 'flex', width: "100%",

        }}>


          {isLoading ?
            (<Skeleton
              sx={{
                borderRadius: '20px',
                backgroundColor: "light-grey",
                width: '45%',
                height: '600px',
                marginTop: '-100px',
                marginBottom: '80px',
              }} />) : (

              <Box sx={{ width: '45%', marginBottom: '80px', marginTop: "24px", borderRadius: "20px", padding: "14px", boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px' }}>
                <Box sx={{ width: "100%", marginTop: "12px", gap: '16px' }}>

                  <Box sx={{ marginBottom: "8px", textAlign: "center", display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent: 'space-around' }}>
                    <Box sx={{ display: 'flex', width: '45%' }} >
                      <Typography fontWeight={'600'}> Name</Typography>
                      <Typography sx={{ marginLeft: "20px" }}>   </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', width: '45%', }} >
                      <Typography> {medicineDetails?.payload?.name}</Typography>
                      <Typography sx={{ marginLeft: "20px" }}> {''}   </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ marginBottom: "8px", textAlign: "center", display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent: 'space-around' }}>
                    <Box sx={{ display: 'flex', width: '45%' }} >
                      <Typography fontWeight={'600'}> Amount</Typography>
                      <Typography sx={{ marginLeft: "20px" }}> {''}      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', width: '45%', }} >
                      <Typography> {medicineDetails?.payload?.amount} </Typography>
                      <Typography sx={{ marginLeft: "20px" }}> {''}      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ marginBottom: "8px", textAlign: "center", display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent: 'space-around' }}>
                    <Box sx={{ display: 'flex', width: '45%' }} >
                      <Typography fontWeight={'600'}> Discount</Typography>
                      <Typography sx={{ marginLeft: "20px" }}> {''}     </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', width: '45%', }} >
                      <Typography>  {medicineDetails?.payload?.discount} </Typography>
                      <Typography sx={{ marginLeft: "20px" }}> {''}     </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ marginBottom: "8px", textAlign: "center", display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent: 'space-around' }}>
                    <Box sx={{ display: 'flex', width: '45%' }} >
                      <Typography fontWeight={'600'}>Medical Test Category</Typography>
                      <Typography sx={{ marginLeft: "20px" }}> {''}     </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', width: '45%', }} >
                      <Typography>  {medicineDetails?.payload?.category}</Typography>
                      <Typography sx={{ marginLeft: "20px" }}>{''}    </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ marginBottom: "8px", textAlign: "center", display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent: 'space-around' }}>
                    <Box sx={{ display: 'flex', width: '45%' }} >
                      <Typography fontWeight={'600'}>Female</Typography>
                      <Typography sx={{ marginLeft: "20px" }}> {''}     </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', width: '45%', }} >
                      <Typography> {medicineDetails?.payload?.female_min}  -  {medicineDetails?.payload?.female_max}</Typography>
                      <Typography sx={{ marginLeft: "20px" }}> {''}    </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ marginBottom: "8px", textAlign: "center", display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent: 'space-around' }}>
                    <Box sx={{ display: 'flex', width: '45%' }} >
                      <Typography fontWeight={'600'}> Male</Typography>
                      <Typography sx={{ marginLeft: "20px" }}>{''}    </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', width: '45%', }} >
                      <Typography> {medicineDetails?.payload?.male_min} -  {medicineDetails?.payload?.male_max}  </Typography>
                      <Typography sx={{ marginLeft: "20px" }}> {''}    </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ marginBottom: "8px", textAlign: "center", display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent: 'space-around' }}>
                    <Box sx={{ display: 'flex', width: '45%' }} >
                      <Typography fontWeight={'600'}> Composite </Typography>
                      <Typography sx={{ marginLeft: "20px" }}> {''}    </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', width: '45%', }} >
                      <Typography>  {medicineDetails?.payload?.composite ? "Yes" : "No"}</Typography>
                      <Typography sx={{ marginLeft: "20px" }}>{''}    </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ marginBottom: "8px", textAlign: "center", display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent: 'space-around' }}>
                    <Box sx={{ display: 'flex', width: '45%' }} >
                      <Typography fontWeight={'600'}> Unit </Typography>
                      <Typography sx={{ marginLeft: "20px" }}> {''}    </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', width: '45%', }} >
                      <Typography> {medicineDetails?.payload?.unit}  </Typography>
                      <Typography sx={{ marginLeft: "20px" }}>{''}    </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ marginBottom: "8px", textAlign: "center", display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent: 'space-around' }}>
                    <Box sx={{ display: 'flex', width: '45%' }} >
                      <Typography fontWeight={'600'}> Cost to lab </Typography>
                      <Typography sx={{ marginLeft: "20px" }}> {''}    </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', width: '45%', }} >
                      <Typography> {medicineDetails?.payload?.cost_to_lab} </Typography>
                      <Typography sx={{ marginLeft: "20px" }}>{''}    </Typography>
                    </Box>
                  </Box>


                  <Box sx={{ marginBottom: "8px", textAlign: "center", display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent: 'space-around' }}>
                    <Box sx={{ display: 'flex', width: '45%' }} >
                      <Typography fontWeight={'600'}> Active</Typography>
                      <Typography sx={{ marginLeft: "20px" }}> {''}    </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', width: '45%', }} >

                      <Typography sx={{ backgroundColor: medicineDetails?.payload?.active ? "#7FDC8840" : '#fd5c63', padding: "4px 8px", borderRadius: "10px" }}> {medicineDetails?.payload?.active ? "Yes" : "No"} </Typography>
                      <Typography sx={{ marginLeft: "20px" }}>{''}    </Typography>
                    </Box>

                  </Box>



                </Box>
              </Box>
            )
          }

        </Box>




      </Box>

      <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={editPurchaseOrderModal}
        onClose={() => setEditPurchaseOrderModal(false)}
      >
        <EditMedicineSalt onClose={() => setEditPurchaseOrderModal(false)} />
      </CustomModal>

      <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={newPurchaseOrderModal}
        onClose={() => setNewPurchaseOrderModal(false)}
      >
        <NewMedicineSalt onClose={() => setNewPurchaseOrderModal(false)} />
      </CustomModal>


    </>
  );
};

export default MedicalTestDetails;

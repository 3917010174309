import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Popper,
  ListItem,
  Modal,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
// import EmailIcon from "@mui/icons-material/Email";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";
import {
  capitalizeFirstLetter,
  PrescriptionForm,
} from "../../../utils/constant";
import CloseIcon from "@mui/icons-material/Close";
import {
  mailBtn,
  primaryBtnBlue,
  textfieldTextPrep,
} from "../../../style/PatientDetailsStyle";
import UseFetchOptions from "../../../helper/UseFetchOptions";
import {
  menuItemStyle,
  primaryBtn,
  textfieldText,
} from "../../../style/AppointmentStyle";
import UseFetchDoctorList from "../../../helper/UseFetchDoctorList";
import UseFetchReferDoctor from "../../../helper/UseFetchReferDoctor";
import {
  removeInvestigation,
  updateMasterData,
} from "../../../connections/putApi";
import { showToast } from "../../../utils/toastUtil";
import { addOptionInAppointForm } from "../../../connections/postApi";
import { useDispatch, useSelector } from "react-redux";
import { createPatient } from "../../../connections/postApi";
import { saveOldPatientAppointment } from "../../../connections/putApi";
import CustomModal from "../../CustomModal/CustomModal";
import PatientMedicalReports from "../../ModalChildren/PatientMedicalReports";
import ReferrerEmail from "../../ModalChildren/ReferrerEmail";
import { setDoctorOption } from "../../../redux/slices/prescriptionSlice";
import {
  appointmentDetail,
  getMailTemplate,
  getPatientPrescription,
} from "../../../connections/getApi";
import Referrer from "../../ModalChildren/Referrer";
import UseGetInvestigationList from "../../../helper/UseGetInvestigationList";
import { Block, Image } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  setPrescriptionData,
  setSearchPatient,
  setPatientDetailsData,
  setAppointmentData,
} from "../../../redux/slices/prescriptionSlice";
import useDebounce from "../../../hooks/UseDebounce";
import UseSearchPatient from "../../../helper/UseSearchPatient";
import UsePatientDetail from "../../../helper/UsePatientDetail";
import UseFetchAppointmentDetailsPatient from "../../../helper/UseFetchAppointmentDetailsPatient";
import UsePatientDetailPrescription from "../../../helper/UsePatientDetailPrescription";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import SchoolIcon from "@mui/icons-material/School";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import LocationCityIcon from "@mui/icons-material/LocationCity";

import { keyframes } from "@emotion/react";
import loadingSpinner from "../../../assets/loadingSpinner.svg";
import UseFetchInvestigationList from "../../../helper/UseFetchInvestigationList";
//  import { keyframes } from '@mui/system';

const rotateSpinner = keyframes`
 0% { transform: rotate(0deg); }
 10% { transform: rotate(36deg); }
 20% { transform: rotate(72deg); }
 30% { transform: rotate(108deg); }
 40% { transform: rotate(144deg); }
 50% { transform: rotate(180deg); }
 60% { transform: rotate(216deg); }
 70% { transform: rotate(252deg); }
 80% { transform: rotate(288deg); }
 90% { transform: rotate(324deg); }
 100% { transform: rotate(360deg); }
`;

const PatientInfoForm = ({ setCommonData, errors, setErrors }) => {
  const [allFormFields, setAllFormFields] = useState(PrescriptionForm);
  const [formState, setFormState] = useState({});
  const [fieldOptions, setFieldOptions] = useState({});
  const [openDialogs, setOpenDialogs] = useState({});
  const [dialogInput, setDialogInput] = useState("");
  const [editingOption, setEditingOption] = useState(null);
  const [investigations, setInvestigations] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openReferrerEmail, setOpenReferrerEmail] = useState(false);
  const [openRefModal, setRefModal] = useState(false);
  const [drReferrerDetail, setDrReferrerDetail] = useState(false);
  const [mailTemplate, setMailTemplate] = useState(null);
  const [openSearchDropDown, setOpenSearchDropDown] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [patientId, setPatientId] = useState(null);
  const [searchData, setSearchData] = useState({
    q: "",
    page: 1,
    perPage: 10,
  });
  const [appointmentId, setAppointmentId] = useState(null);
  const [prescriptionId, setPrescriptionId] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [appointmentsDate, setAppointmentsDate] = useState([]);
  const [drConsultId, setDrConsultId] = useState("");


  //showing data in mount phase
  const patientDetailsData = useSelector(
    (state) => state.prescription.patientDetailsData
  );
  const appointmentData = useSelector(
    (state) => state.prescription.appointmentData
  );
  // const isLoading = useSelector((state) => state.prescription.isLoading);

  const dispatch = useDispatch();

  const handlePreview = () => {
    if (!patientDetailsData.id) {
      showToast(" Please select a patient first ", "ERROR");
      return null;
    }
    setOpenModal(true);
  };

  const handleReferrerEmail = () => {
    if (!patientDetailsData.id) {
      showToast("Please select a patient first ", "ERROR");
      return null;
    }
    if (!drReferrerDetail) {
      showToast("Please select a doctor to refer first", "ERROR");
      return null;
    }
    setOpenReferrerEmail(true);
  };

  const handleRefModal = () => {
    setRefModal(true);
  };

  //  console.log(formState , 'formState')
  // console.log(fieldOptions , 'fieldOptions');

  useEffect(() => {
    if (patientDetailsData) {
      setFormState({
        id: patientDetailsData?.uid,
        fullName: patientDetailsData?.full_name,
        email: patientDetailsData?.email,
        mobile: patientDetailsData?.mobile,
        maritalStatus: patientDetailsData?.marital_status?.toLowerCase(),
        diet: patientDetailsData?.diet?.toLowerCase(),
        gender: patientDetailsData?.gender?.toLowerCase(),
        occupation: patientDetailsData?.occupation_id || null,
        age: patientDetailsData?.age,
        address: patientDetailsData?.address,
        education: patientDetailsData?.education_id || null,
        city: +patientDetailsData?.city || null,
        pincode: patientDetailsData?.pincode,
        visitType: appointmentData?.visit_type,
        attendingDoctor: appointmentData?.doctor?.id,
        consultancyDoctor: appointmentData?.consultant?.id,
        comments: appointmentData?.comments,
        referrer: appointmentData?.referrer_id,
        height: appointmentData?.height,
        weight: appointmentData?.weight,
        fever: appointmentData?.fever,
        bpSystolic: appointmentData?.bp_s,
        bpDiastolic: appointmentData?.bp_d,
        bmi: appointmentData?.bmi,
        specialist_plan_title: appointmentData?.specialist_plan_title,
      });
      setDrConsultId(appointmentData?.consultant?.id)
      setErrors({});
    }
  }, [appointmentData, patientDetailsData]);

  useEffect(() => {
    if (appointmentData?.ready_investigations && appointmentData?.ready_investigations?.length > 0) {
      setInvestigations(appointmentData?.ready_investigations);
    }
    else {
      setInvestigations([]);
    }
  }, [appointmentData]);

  const handleInvestigationChange = (event) => {
    let selectedInvestigation = event.target;
    // console.log(event.target)
    selectedInvestigation = { name: selectedInvestigation?.value?.name, id: selectedInvestigation?.value?.value };
    // console.log(selectedInvestigation);
    if (selectedInvestigation !== "default") {
      setInvestigations((prevInvestigations = []) => {
        if (!prevInvestigations.includes(selectedInvestigation?.value?.value)) {
          return [...prevInvestigations, selectedInvestigation];
        }
        return prevInvestigations;
      });
    }
  };

  //handle delete investigations
  const handleDeleteInvestigation = async (investigationToDelete) => {
    const investigationFind = appointmentData?.ready_investigations?.filter(
      // if investigation exist in appointment state also then call remove api also along with locally
      (investigation) => investigation.name === investigationToDelete
    );

    if (investigationFind?.length > 0) {
      try {
        const res = await removeInvestigation(
          appointmentData.id,
          investigationToDelete
        );
        // console.log(investigationToDelete, "investigationToDelete");
        setInvestigations((prevInvestigations) =>
          prevInvestigations.filter(
            (investigation) => investigation.name !== investigationToDelete
          )
        );
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    } else {
      // if investigation not exist in appointment state  then  delete locally
      setInvestigations((prevInvestigations) =>
        prevInvestigations.filter(
          (investigation) => investigation.name !== investigationToDelete
        )
      );
    }
  };

  useEffect(() => {
    setCommonData((prev) => ({
      ...prev,
      investigations: investigations,
    }));

    // setFieldOptions((prevOptions) => {
    //   const updatedOptions = [...prevOptions['investigation']];

    //   return { ...prevOptions, ['investigation']: updatedOptions };
    // });
  }, [investigations, setCommonData]);

  //  console.log(investigations)
  // hooks to fetch data
  const { data: investigationList, isLoading: isLoadingInvestigation } = UseFetchInvestigationList(drConsultId);
  const { data: allOccupation, refetch: refetchOccupation } =
    UseFetchOptions("occupations");
  const { data: allCities, refetch: refetchCities } = UseFetchOptions("cities");
  const { data: allEducations, refetch: refetchEducation } =
    UseFetchOptions("educations");
  const { data: drQuery } = UseFetchDoctorList();
  const { data: referDr, refetch: refetchReferrer } = UseFetchReferDoctor();
  //  console.log(investigationList?.payload?.investigations)

  const handleInputChange = (event, value, name) => {
    //  console.log("Event:", event, "Value:", value, "Name:", name);
    let fieldName;

    if (event) {
      const { name: eventName, value: eventValue } = event.target;
      fieldName = event.target.name;

      if ((fieldName === "age" && eventValue?.length > 3) || (fieldName === "mobile" && eventValue?.length > 10)) {
        return;
      }

      if (fieldName === "consultancyDoctor") {
        if (drConsultId !== eventValue) {
          setDrConsultId(eventValue);
          setInvestigations(appointmentData?.ready_investigations || [])
        }
        setFormState((prevState) => ({
          ...prevState,
          [eventName]: eventValue,
        }));
      }

      setFormState((prevState) => ({
        ...prevState,
        [eventName]: eventValue,
      }));
    } else if (name) {
      fieldName = name;
      if (value?.value === "other") {
        setFormState((prev) => ({ ...prev, [name]: true }));
      } else if (name === "referrer") {
        setFormState((prevState) => ({
          ...prevState,
          [name]: value?.id || value,
        }));
        setDrReferrerDetail(value);
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
    setErrors((prev) => ({ ...prev, [fieldName]: undefined }));
  };

  const handleOpenDialog = (name, currentValue) => {
    console.log(name, currentValue, "name , currentValue");
    setOpenDialogs((prev) => ({ ...prev, [name]: true }));

    // Determine if currentValue is an integer
    if (Number.isInteger(currentValue)) {
      // If currentValue is an integer, find the corresponding option object
      const foundOption = fieldOptions[name]?.find(
        (option) => option.id === currentValue
      );
      // Set dialog input to the title of the found option, or fallback to currentValue
      setDialogInput(foundOption ? foundOption : currentValue);
      setEditingOption(foundOption ? foundOption : currentValue || "");
    } else {
      // If currentValue is not an integer (likely an object), directly set it
      setDialogInput(currentValue || "");
      setEditingOption(currentValue || "");
    }
  };

  const handleCloseDialog = (name) => {
    setOpenDialogs((prev) => ({ ...prev, [name]: false }));
    setDialogInput("");
    setEditingOption(null);
  };

  const handleDialogInputChange = (event) => {
    setDialogInput(event.target.value);
  };

  // const handleAddOrEditValue = async (name) => {
  //   let updatedOptions = {};
  //   let index = null;

  //   if (dialogInput) {
  //     setFieldOptions((prevOptions) => {
  //       updatedOptions = [...prevOptions[name]];

  //       if (editingOption) {
  //         index = updatedOptions.findIndex(
  //           (option) => option.id === editingOption.id
  //         );
  //         console.log(index, "index filed");
  //         if (index !== -1) {
  //           updatedOptions[index].value = dialogInput;
  //           updatedOptions[index].title = dialogInput;
  //         }
  //       } else {
  //         updatedOptions.push({
  //           title: dialogInput,
  //           value: dialogInput,
  //           id: Math.random(),
  //         });
  //         index = updatedOptions.length - 1;
  //       }
  //       return { ...prevOptions, [name]: updatedOptions };
  //     });
  //     // console.log(updatedOptions[index], editingOption.id, "updatedOptions");

  //     if (editingOption) {
  //       const result = await updateMasterData(name, updatedOptions[index]?.id, {
  //         value: updatedOptions[index].value,
  //       });
  //       showToast(`${name} updated successfully`, "SUCCESS");
  //       console.log(result);
  //     } else {
  //       const result = await addOptionInAppointForm(
  //         { value: updatedOptions[index].value },
  //         name
  //       );
  //       showToast(`${name} added successfully`, "SUCCESS");
  //       // console.log(result);
  //     }
  //     setFormState((prevState) => ({
  //       ...prevState,
  //       [name]: updatedOptions[index],
  //     }));

  //     handleCloseDialog(name);
  //   }
  // };

  const handleAddOrEditValue = async (name) => {
    let updatedOptions = {};
    // let index = null;
    if (dialogInput) {
      let result;
      if (editingOption) {
        result = await updateMasterData(name, editingOption.id, {
          value: dialogInput,
        });
        showToast(`${name} updated successfully`, "SUCCESS");
        setFieldOptions((prevOptions) => {
          const updatedOptions = [...prevOptions[name]].map((option) => {
            if (option.id === result?.payload?.id) {
              return {
                ...option,
                title: result?.payload?.title || result?.payload?.name,
                value: result?.payload?.value || result?.payload?.name,
                id: result?.payload?.id,
              };
            }
            return option;
          });
          return {
            ...prevOptions,
            [name]: updatedOptions,
          };
        });
      } else {
        result = await addOptionInAppointForm({ value: dialogInput }, name);
        showToast(`${name} added successfully`, "SUCCESS");
        setFieldOptions((prevOptions) => {
          updatedOptions = [...prevOptions[name]];
          updatedOptions.push({
            title: result?.payload?.title || result?.payload?.name,
            value: result?.payload?.value || result?.payload?.name,
            id: result?.payload?.id,
          });
          // index = updatedOptions?.length - 1;
          return { ...prevOptions, [name]: updatedOptions };
        });
      }
      setFormState((prevState) => ({
        ...prevState,
        [name]: result?.payload?.id,
      }));
      handleCloseDialog(name);
    }
  };

  const handleAddOrEditValueRef = (name, referrer) => {
    console.log(name, referrer);
    referrer = {
      title: referrer.name + " - " + referrer.address + " - " + referrer.phone,
      value: referrer.name,
    };
    setFormState((prevState) => ({
      ...prevState,
      [name]: referrer,
    }));
    refetchReferrer();
  };

  /// set field option
  useEffect(() => {
    const initialOptions = {};
    allFormFields.forEach((field) => {
      if (field.field === "Autocomplete") {
        initialOptions[field.name] = field.options;
      }
    });

    setFieldOptions(initialOptions);
  }, []);
  // set education occupation and cities option in fields

  useEffect(() => {
    const newReferDr = referDr?.payload?.referrers?.map((refer) => ({
      id: refer?.id,
      title: `${refer?.full_name || ""}${refer?.profession ? ` [${refer?.profession}]` : ""
        }${refer?.qualification ? ` [${refer?.qualification}]` : ""}${refer?.city ? ` - ${refer?.city}` : ""
        }${refer?.phone ? ` - ${refer?.phone}` : ""}`,
      value: refer?.full_name,
      email: refer?.email,
      phone: refer?.phone,
      address: refer?.address,
    }));
    const newAllCities = allCities?.cities?.map((el) => ({
      id: el?.id,
      title: el?.name,
      value: el?.name,
    }));

    setFieldOptions((prev) => ({
      ...prev,
      occupation: allOccupation?.occupations,
      education: allEducations?.educations,
      city: newAllCities,
      referrer: newReferDr,
    }));
  }, [allCities, allEducations, allOccupation, referDr]);

  // set dr option
  useEffect(() => {
    const formattedDrList = drQuery?.doctors?.map((dr) => ({
      value: dr?.id,
      label: dr?.full_name,
      id: dr?.id,
    }));
    let newData = allFormFields?.map((field) => {
      if (
        field.name === "attendingDoctor" ||
        field.name === "consultancyDoctor"
      ) {
        return { ...field, options: formattedDrList };
      }
      return field;
    });
    dispatch(setDoctorOption(formattedDrList));

    setAllFormFields(newData);
  }, [drQuery]);

  function cleanPayload(obj) {
    if (Array.isArray(obj)) {
      return obj.map(cleanPayload).filter((item) => item != null);
    } else if (typeof obj === "object" && obj !== null) {
      return Object.fromEntries(
        Object.entries(obj)
          .map(([key, value]) => [key, cleanPayload(value)])
          .filter(([_, value]) => value != null && value !== "")
      );
    }
    return obj;
  }

  // console.log( 'patientDetails from api ', patientDetailsData);
  // console.log( 'form state', formState);

  const validateForm = (formData) => {
    let newErrors = {};
    let isValid = true;

    PrescriptionForm.slice(1, 13).forEach((field) => {
      if (field.pattern) {
        const value = formData[field.name];
        if (!new RegExp(field.pattern).test(value)) {
          newErrors[field.name] = field.errorMessage || "Invalid input";
          isValid = false;
        }
      }
      // Add any other validation rules here
      if (field.required && !formData[field.name]) {
        // newErrors[field.name] = `${[field.name]}  is required`;
        newErrors[field.name] = field.errorMessage || "Invalid input";
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSavePatient = async () => {
    setButtonLoading(true);

    if (!validateForm(formState)) {
      setButtonLoading(false);
      return null;
    }

    try {
      let oldPatientData = {
        id: patientDetailsData?.id,
        uid: formState?.id,
        full_name: formState?.fullName,
        email: formState?.email,
        mobile: formState?.mobile,
        marital_status: formState?.maritalStatus,
        diet: formState?.diet,
        gender: formState?.gender,
        occupation_id:
          formState?.occupation?.id || formState?.occupation || null,
        age: formState?.age,
        address: formState?.address,
        education_id: formState?.education?.id || formState?.education || null,
        city: formState?.city?.id || formState?.city || null,
        pincode: formState?.pincode,
      };

      oldPatientData = cleanPayload(oldPatientData);
      if (patientDetailsData?.id) {
        let result = await saveOldPatientAppointment(
          oldPatientData,
          patientDetailsData?.id
        );
        showToast(
          "update patient ",
          result?.status?.message || result?.status?.status,
          result?.status?.status
        );
        // console.log(result, "result of update");
        setButtonLoading(false);
      } else {
        let createData = {
          patient: {
            full_name: formState?.fullName,
            email: formState?.email,
            mobile: formState.mobile,
            marital_status: formState?.maritalStatus?.toLowerCase(),
            diet: formState?.diet?.split("-")?.join("_")?.toLowerCase(),
            gender: formState?.gender?.toLowerCase(),
            occupation_id:
              formState?.occupation?.id || formState?.occupation || null,
            age: formState?.age,
            address: formState?.address,
            education_id:
              formState?.education?.id || formState?.education || null,
            city: formState?.city?.id || formState?.city || null,
            pincode: formState?.pincode,
          },
        };

        createData = cleanPayload(createData);
        let createdPatient = await createPatient(createData);
        if (createdPatient?.status?.status === "SUCCESS") {
          dispatch(setPatientDetailsData(createdPatient?.payload));
        }
        const statusMessage = createdPatient?.status?.status || "ERROR";
        const detailedMessage =
          createdPatient?.status?.message || "Something went wrong";
        showToast(statusMessage, detailedMessage);
        showToast(
          createdPatient?.status?.message || createdPatient?.status?.status,
          createdPatient?.status?.status
        );
        // console.log(createdPatient, "result of create patient");
        setButtonLoading(false);
      }
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
    }
  };

  //referrer email
  useEffect(() => {
    const fetchMailTemplate = async () => {
      try {
        if (drReferrerDetail?.id && patientDetailsData?.id) {
          let res = await getMailTemplate(
            drReferrerDetail?.id,
            patientDetailsData?.id
          );
          setMailTemplate(res);
        }
      } catch (error) {
        console.error("Error fetching mail template:", error);
      }
    };
    fetchMailTemplate();
  }, [drReferrerDetail, patientDetailsData]);

  useEffect(() => {
    setCommonData((prev) => ({
      ...prev,
      patientForm: formState,
    }));
  }, [formState, setCommonData]);

  const { patientData } = useSelector((state) => state.prescription);

  // search query for patient by name or id
  const debouncedInputValue = useDebounce(inputValue, 1000);

  useEffect(() => {
    if (debouncedInputValue) {
      setSearchData((prev) => ({ ...prev, q: debouncedInputValue }));
    } else {
      dispatch(setSearchPatient([]));
    }
  }, [debouncedInputValue, dispatch]);

  // change input for search
  const { data: searchResult } = UseSearchPatient(searchData);
  const { data: patientDetailsQuery, isLoading: patientDetailsLoading } =
    UsePatientDetail(patientId);
  const { data: appointmentDetails, isLoading: appointmentDataLoading } =
    UseFetchAppointmentDetailsPatient(appointmentId);
  const { data: prescriptionData, isLoading: prescriptionDataLoading, status: prescriptionStatus } =
    UsePatientDetailPrescription(prescriptionId);

  const handleInputChangeSearch = (event, newInputValue) => {
    setInputValue(newInputValue);
  };
  useEffect(() => {
    if (searchResult?.payload?.patients) {
      dispatch(setSearchPatient(searchResult?.payload?.patients));
    }
  }, [searchResult, dispatch, inputValue]);

  useEffect(() => {
    if (patientDetailsQuery?.payload) {
      dispatch(setPatientDetailsData(patientDetailsQuery?.payload)); // Dispatch patient details to store
    }

    if (patientDetailsQuery?.payload?.appointments) {
      const dates = patientDetailsQuery?.payload?.appointments?.filter(appointment =>
        ["completed", "paid"].includes(appointment.status)).map((appointment) => ({
          date: new Date(appointment.visit_date).toDateString(),
          id: appointment?.id, // Track the appointment ID for fetching details
          prescription_id: appointment?.prescription_id,
          appointmentStatus: appointment?.status,
        }));
      setAppointmentsDate(dates);
    }
    else {
      setAppointmentsDate([]);
    }

  }, [patientDetailsQuery, dispatch]);

  // console.log(appointmentsDate)

  useEffect(() => {
    if (appointmentDetails) {
      dispatch(setAppointmentData(appointmentDetails)); // Dispatch patient  appointment data to Redux store
    }
  }, [appointmentDetails, dispatch]);

  // Set the appointment ID after fetching patient details
  useEffect(() => {
    if (patientDetailsQuery?.payload?.appointments?.length > 0 && appointmentsDate?.length > 0) {
      setAppointmentId(appointmentsDate[0]?.id);  // Assuming you need the first appointment ID
    }
  }, [patientDetailsQuery, appointmentsDate]);

  // console.log(appointmentsDate[0]?.id , 'appt id');


  // console.log(appointmentDetails?.prescription?.id, 'prec id')
  // Set the prescription ID after fetching appointment details
  useEffect(() => {
    if (appointmentDetails?.patient?.appointments?.length > 0) {
      // setPrescriptionId(  
      //   appointmentDetails?.patient?.appointments[0]?.prescription_id
      // );
      // Assuming you need the first prescription ID
      setPrescriptionId(
        (appointmentDetails?.prescription?.id && appointmentDetails?.prescription?.id) || (appointmentsDate?.length >= 2 && appointmentsDate[1]?.prescription_id)
      );
    }
  }, [appointmentDetails]);

  // console.log(appointmentsDate , 'appointmentsDate patient  details')
  // Dispatch prescription data to Redux store
  useEffect(() => {
    if (prescriptionData && prescriptionStatus === 'success') {

      dispatch(setPrescriptionData(prescriptionData));
      // console.log("prescriptionData form ");
    }
  }, [prescriptionData, dispatch]);

  const selectedPatient = (id) => {
    dispatch(setPatientDetailsData({})); // remove previous patient data
    dispatch(setAppointmentData({}));
    dispatch(setPrescriptionData([]));
    setCommonData({
      patientForm: {},
      treatmentDetail: {},
      investigations: [],
      medicines: [],
    });

    setTimeout(() => {
      setPatientId(id?.id);
    }, 0);
  };

  const [allDataLoading, setAllDataLoading] = useState(false);
  // Debounce effect to stabilize allDataLoading state
  useEffect(() => {
    if (patientDetailsLoading) {
      // console.log(patientDetailsLoading);
      setAllDataLoading(true); // Set to true immediately when patientDetailsLoading starts
    } else if (
      !patientDetailsLoading &&
      !appointmentDataLoading &&
      !prescriptionDataLoading
    ) {
      // Use a timeout to debounce the false state change
      const timer = setTimeout(() => {
        setAllDataLoading(false);
        // console.log("i am loader", "all api loader");
      }, 1000);

      return () => clearTimeout(timer); // Clear timeout if dependencies change before debounce ends
    }
  }, [patientDetailsLoading, appointmentDataLoading, prescriptionDataLoading]);

  // Console log to observe state changes dddd

  // console.log(formState)
  // console.log(
  //   `${allDataLoading} all api loader`,
  //   `${patientDetailsLoading} api 1`,
  //   `${appointmentDataLoading} api 2`,
  //   `${prescriptionDataLoading} api 3`
  // );

  // console.log(investigations, 'investigations')
  // useEffect(() => {
  //   const fetchPrescription = async () => {
  //     if (!appointmentDetails?.prescription_id) {
  //       if (
  //         patientDetailsData?.appointments?.length > 1 &&
  //         !patientDetailsData?.appointments[0]?.prescription_id
  //       ) {
  //         let prescriptionTruthyId = null;

  //         for (let appointment of patientDetailsData?.appointments) {
  //           if (appointment.prescription_id) {
  //             prescriptionTruthyId = appointment.prescription_id;
  //             break; // Stop at the first truthy prescription_id
  //           }
  //         }
  //         if (prescriptionTruthyId) {
  //           try {
  //             const res = await getPatientPrescription(prescriptionTruthyId);
  //             // console.log(res, "Fetched Prescription Data TO save medicine from an prev prescription");

  //             if (res) {
  //               dispatch(setPrescriptionData(res));
  //             }
  //           } catch (error) {
  //             console.error("Error fetching prescription:", error);
  //           }
  //         } else {
  //           console.warn("No truthy prescription ID found in appointments");
  //         }
  //       }
  //     }
  //   };
  //   fetchPrescription();
  // }, [appointmentDetails]);

  // console.log(investigations, 'investigations')

  const renderField = (field) => {
    let maxLength;
    if (field.name === "mobile") {
      maxLength = 10;
    }
    switch (field.field) {
      case "TextField":
        return (
          <TextField
            fullWidth
            type={field.type}
            // {field.name=="id"?  s}
            disabled={field.isDisabled}
            placeholder={field.placeholder}
            sx={{
              ...field.sx,
              cursor: field.name === "id" ? "not-allowed" : "auto",
            }}
            variant={field.variant}
            name={field.name}
            value={formState[field.name] || ""}
            onChange={handleInputChange}
            error={Boolean(errors[field.name])}
            helperText={errors[field.name] || ""}
            inputProps={{
              ...(field.type === "date"
                ? { max: new Date().toISOString().split("T")[0] }
                : {}),
              ...(field.name === "mobile" ? { maxLength: maxLength } : {}),
            }}
          />
        );
      case "Select":
        if (field.name === "investigationType") {
          // const { data: investigationList, isLoadingInvestigation } = UseGetInvestigationList(drConsultId);
          //  console.log(investigationList?.payload?.investigations?.extras ,'investigation')
          // Transform the fetched data to match the expected structure
          // const options =
          //   investigationList?.payload?.investigations?.extras.map((item) => ({
          //     value: item?.name.toUpperCase(),
          //     label: item?.name.toUpperCase(),
          //   })) || [];

          const options = investigationList?.payload?.medical_tests
            ?.filter(
              (item) =>
                !investigations?.some(
                  (inv) => inv?.id === item?.id
                )
            )
            ?.map((item) => ({
              value: item?.id,
              name: item?.name,
              label: item?.code,
            })) || [];

          // console.log(options)
          return (
            <FormControl fullWidth variant="outlined">
              <Select

                fullWidth
                sx={{
                  ...field.sx,
                  color: formState[field.name]
                    ? "text.black"
                    : "text.default_grey",
                }}

                onChange={(event) => {
                  handleInvestigationChange(event);
                }}
                value={formState[field.name] || ""}
                name={field.name}
                displayEmpty
                required={true}
              >
                <MenuItem value="" disabled>
                  <span>{field.placeholder || "Select investigation"}</span>
                </MenuItem>

                {/* Render options dynamically from fetched API */}
                {options.map((option) => (
                  <MenuItem
                    id={option.value || "menu"}
                    key={option.value + Math.random()}
                    value={option}
                    sx={{
                      ...(option.value === field.defaultValue
                        ? field.defaultMenuStyle
                        : field.menuItemStyle),
                      "&:hover": {
                        backgroundColor: "#0067FF40 !important",
                        borderRadius: "16px",
                        color: "black !important",
                      },
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              {field.name === "investigationType" && isLoadingInvestigation && (
                <InputAdornment
                  position="end"
                  sx={{ marginLeft: "200px", marginTop: "-20px" }}
                >
                  <CircularProgress size={20} />
                </InputAdornment>
              )}


              {/* Display errors if any */}
              {errors[field.name] && (
                <Typography fontSize={"12px"} color={"#d32f2f"}>
                  {errors[field.name]}
                </Typography>
              )}
            </FormControl>
          );
        } else
          return (
            <FormControl fullWidth variant="outlined">
              <Select
                fullWidth
                sx={{
                  ...field.sx,
                  color: formState[field.name]
                    ? "text.black"
                    : "text.default_grey",
                }}
                onChange={(event) => {
                  handleInputChange(event);
                }}
                value={formState[field.name] || ""}
                //onChange={handleFormChange}

                name={field.name}
                displayEmpty
                required={true}
              >
                <MenuItem value="" disabled>
                  <span>{field?.placeholder || "Select an option"}</span>
                </MenuItem>

                {field?.options?.map((option) => (
                  <MenuItem
                    id={option?.value || "menu"}
                    key={option.value + Math.random()}
                    value={option.value}
                    sx={{
                      ...(option.value === field.defaultValue
                        ? field.defaultMenuStyle
                        : field.menuItemStyle),
                      "&:hover": {
                        backgroundColor: "#0067FF40 !important",
                        borderRadius: "16px",
                        color: "black !important",
                      },
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>

              {errors[field.name] && (
                <Typography fontSize={"12px"} color={"#d32f2f"}>
                  {errors[field.name]}
                </Typography>
              )}
            </FormControl>
          );
      case "Autocomplete":
        return (
          <>
            <Autocomplete
              clearIcon={false}
              popupIcon={false}
              options={[
                ...(fieldOptions[field.name] || field.options),
                { id: "other", value: "other", title: "Other" },
              ]}
              // options={
              //   field.name === "investigationType"
              //   ? (fieldOptions[field.name] || field?.options)?.filter((option) => {
              //     console.log(option?.title , 'hello'); // This logs the title for debugging.
              //     return !investigations
              //       ?.map((inv) => inv.name.toLowerCase())
              //       ?.includes(option?.title?.toLowerCase()); // Ensure case-insensitive comparison.
              //   })
              // : [
              //     ...(fieldOptions[field.name] || field.options),
              //     { id: "other", value: "other", title: "Other" },
              //   ]
              // }
              sx={{
                "& .MuiAutocomplete-listbox": {
                  width: "400px !important",
                },
              }}
              value={
                (formState[field.name] &&
                  (fieldOptions[field.name] || field.options)?.find(
                    (option) =>
                      option.value ===
                      (formState[field.name]?.value ||
                        formState[field.name]) ||
                      option.title ===
                      (formState[field.name]?.title ||
                        formState[field.name]) ||
                      option.id === formState[field.name]
                  )) ||
                null
              }
              onChange={(event, newValue) =>
                handleInputChange(null, newValue, field.name)
              }
              PopperComponent={(props) => (
                <Grid item lg={8}>
                  <Popper
                    {...props}
                    sx={{ width: "fit-content" }}
                    placement="bottom-start"
                    style={menuItemStyle}
                  />
                </Grid>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={field.placeholder}
                  variant={field.variant}
                  fullWidth
                  sx={field.sx}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        <IconButton
                          onClick={() =>
                            handleOpenDialog(field.name, formState[field.name])
                          }
                        >
                          <EditNoteIcon />
                        </IconButton>
                      </>
                    ),
                  }}
                />
              )}
              getOptionLabel={(option) =>
                option.title && option.value ? `${option.title}` : ""
              }
              renderOption={(props, option, { index }) => (
                <MenuItem
                  sx={{
                    ...menuItemStyle,
                    "&:hover": {
                      backgroundColor: "#0067FF40 !important",
                      borderRadius: "16px",
                      color: "black !important",
                    },
                    ...(index === 0 && {
                      backgroundColor: "#0067FF40 !important",
                      color: "black !important",
                      borderRadius: "16px",
                    }),
                  }}
                  {...props}
                  key={option.id + Math.random()}
                >
                  {option.title}
                </MenuItem>
              )}
            />
            <Dialog
              open={openDialogs[field.name] || false}
              onClose={() => handleCloseDialog(field?.name)}
              PaperProps={{
                sx: {
                  marginTop: "60",
                  top: "0",
                  position: "absolute",
                  width: "23%",
                  maxWidth: "none",
                  borderRadius: "20px",
                },
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <DialogTitle>
                  {/* {editingOption ? `Update/Create` : "Update / Create"}{" "}
                {field.name} */}

                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    {field.name === "city" && (
                      <LocationCityIcon
                        sx={{
                          color: "neutral.navyBlue",
                          height: "30px",
                          width: "30px",
                        }}
                      />
                    )}
                    {field.name === "occupation" && (
                      <WorkOutlineOutlinedIcon
                        sx={{
                          color: "neutral.navyBlue",
                          height: "30px",
                          width: "28px",
                        }}
                      />
                    )}
                    {field.name === "education" && (
                      <SchoolIcon
                        sx={{
                          color: "neutral.navyBlue",
                          height: "30px",
                          width: "28px",
                        }}
                      />
                    )}

                    <Typography variant="heading1">
                      {" "}
                      {editingOption ? `Update/Create` : "Update / Create"}{" "}
                      {field.name}
                    </Typography>
                  </Box>
                </DialogTitle>

                <Button onClick={() => handleCloseDialog(field.name)}>
                  <CloseIcon
                    sx={{ color: "red", height: "20px" }}
                    size="small"
                    color="red"
                  />
                </Button>
              </Box>

              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  value={
                    typeof dialogInput === "string"
                      ? dialogInput
                      : dialogInput?.title ||
                      (formState[field.name] &&
                        (fieldOptions[field.name] || field.options)?.find(
                          (option) =>
                            [option.value, option.title, option.id].includes(
                              formState[field.name]?.value ||
                              formState[field.name]?.title ||
                              formState[field.name]
                            )
                        )?.title) ||
                      ""
                  }
                  placeholder="Edit option"
                  onChange={handleDialogInputChange}
                  InputProps={{
                    sx: {
                      height: "42px",
                      padding: "12px",
                      borderRadius: "20px",
                      "& .MuiInputBase-input": {
                        height: "15px",
                        padding: "0px",
                        boxSizing: "border-box",
                      },
                    },
                  }}
                />
                <Button
                  onClick={() => handleAddOrEditValue(field.name)}
                  sx={{
                    ...primaryBtnBlue,
                    marginTop: "8px",
                    float: "right",
                    p: 2.3,
                    color: "white",
                  }}
                >
                  {editingOption ? "Save" : "Save"}
                </Button>
              </DialogContent>

              <DialogActions></DialogActions>
            </Dialog>

            {errors[field.name] && (
              <Typography fontSize={"12px"} color={"#d32f2f"}>
                {errors[field.name]}
              </Typography>
            )}
          </>
        );
      case "Autocomplete1":
        return (
          <>
            {/* {console.log(formState[field.name])} */}
            <Autocomplete
              options={fieldOptions[field.name] || field.options}
              clearIcon={false}
              popupIcon={false}
              getOptionLabel={(option) =>
                option.title && option.value ? `${option.title}` : ""
              }
              // value={formState[field.name] || ""}
              value={
                (formState[field.name] &&
                  (fieldOptions[field.name] || field.options)?.find(
                    (option) =>
                      option.value ===
                      (formState[field.name]?.value ||
                        formState[field.name]) ||
                      option.title ===
                      (formState[field.name]?.title ||
                        formState[field.name]) ||
                      option.id === formState[field.name]
                  )) ||
                formState[field.name]
              }
              onChange={(event, newValue) =>
                handleInputChange(null, newValue, field.name)
              }
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  sx={{
                    maxHeight: "578.51px",
                    overflowY: "auto",
                    minWidth: "400px",
                    zIndex: 1300,
                  }}
                  placement="bottom-start"
                />
              )}
              renderOption={(props, option, { index }) => (
                <MenuItem
                  {...props}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    // "&:hover": {
                    //   backgroundColor: "#0067FF40 !important",
                    //   color: "white !important",
                    //   borderRadius:'16px',
                    // },

                    ...menuItemStyle,
                    "&:hover": {
                      backgroundColor: "#0067FF40 !important",
                      borderRadius: "16px",
                      color: "black !important",
                    },
                    ...(index === 0 && {
                      backgroundColor: "#0067FF40 !important",
                      color: "black !important",
                      borderRadius: "16px",
                    }),
                  }}
                >
                  {option.title}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={field.placeholder}
                  variant={field.variant}
                  fullWidth
                  sx={{ ...field.sx }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params?.InputProps?.endAdornment}
                        <IconButton onClick={() => handleRefModal(true)}>
                          <EditNoteIcon />
                        </IconButton>
                      </>
                    ),
                  }}
                />
              )}
            />

            <CustomModal
              open={openRefModal}
              onClose={() => setRefModal(false)}
              // title="Referrer"
              // isTitle={true}
              customStyles={{ border: "none", minWidth: "50%" }}
            >
              <Referrer
                // selectedReferrer={formState["referrer"]}
                //  {...console.log(fieldOptions.referrer, "field option")}
                selectedReferrer={
                  (formState[field.name] &&
                    (fieldOptions.referrer || fieldOptions[field.name])?.find(
                      (option) =>
                        option.value === formState[field.name]?.value ||
                        option.id === formState[field.name]
                    )) ||
                  formState[field.name]
                }
                // selectedReferrer={
                //   formState[field.name] &&
                //   (fieldOptions[field.name] || fieldOptions.referrer)?.find(
                //     (option) =>
                //       option.value === formState[field.name]?.value ||
                //       option.id === formState[field.name]?.id
                //   ) || formState[field.name] // Fallback to formState[field.name] if no match is found
                // }

                setOpenModal={setRefModal}
                onSave={handleAddOrEditValueRef}
              />
            </CustomModal>
          </>
        );
      case "Button":
        return (
          <Box display="flex" sx={{ width: "100%" }}>
            {field?.hasIcon ? (
              field?.icon === "EmailIcon" ? (
                <IconButton sx={{ p: 0, m: 0 }} className="btn draw-border">
                  <ForwardToInboxOutlinedIcon
                    onClick={handleReferrerEmail}
                    sx={mailBtn}
                  />
                </IconButton>
              ) : (
                <IconButton
                  sx={{ p: 0, m: 0, marginLeft: "8px" }}
                  className="btn draw-border"
                >
                  <ContentPasteGoOutlinedIcon
                    onClick={handlePreview}
                    sx={mailBtn}
                  />
                </IconButton>
              )
            ) : (
              <Button
                disabled={buttonLoading}
                fullWidth
                variant={field.variant}
                sx={{
                  ...field.sx,
                  width: "100%",
                  display: "flex",

                  ...(field?.text === "Save Patient Info"
                    ? { whiteSpace: "nowrap", fontSize: "14px" }
                    : {}),
                }}
                onClick={() => {
                  if (field?.text === "Save Patient Info") {
                    handleSavePatient();
                  } else if (field?.text === "Reports") {
                    handlePreview();
                  } else {
                    console.log(formState);
                  }
                }}
              >
                {field.text}
              </Button>
            )}
          </Box>
        );
      case "Typography":
        return (
          <Typography
            sx={{
              ...field.sx,
              display: "block",
              marginLeft: "5px",
              marginTop: "25px",
              marginBottom: "6px",
            }}
            variant={field.variant}
          >
            {field?.title}

            {appointmentData?.specialist_plan_title &&
              <Typography variant="span" fontSize='16px' marginLeft={'12px'}>
                ({appointmentData?.specialist_plan_title})
              </Typography>
            }

          </Typography>
        );
      default:
        return null;
    }
  };
  console.log(investigations, 'investigation');
  console.log(appointmentData?.ready_investigations, 'appt ready ')
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F1F1FF",
          // border: "1px solid #dee2e",
          border: "none !important",
          outline: "none !important",
          marginTop: "-20px",
          marginLeft: "-10px",
          padding: 2,
          pt: 5,
          pb: 4,
        }}
      >
        <Modal
          // open={true}
          open={allDataLoading}
          sx={{
            transition: "ease-in-out 1s",
            border: "none !important",
            outline: "none !important",
            position: "absolute",
            top: "40%",
            left: "40%",
          }}
        >
          <Box
            sx={{
              animation: `${rotateSpinner} 1s linear infinite`,
              width: "50px",
              border: "none !important",
              outline: "none !important",
            }}
          >
            <img
              src={loadingSpinner}
              alt=""
              style={{
                width: "50px",
                height: "50px",
                border: "none !important",
                outline: "none !important",
              }}
            />
          </Box>
        </Modal>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Autocomplete
              popupIcon={false}
              open={patientData?.length > 0 && openSearchDropDown}
              clearIcon={false}
              onOpen={() => setOpenSearchDropDown(true)}
              onClose={() => {
                dispatch(setSearchPatient([]));
                setOpenSearchDropDown(false);
              }}
              filterOptions={(options) => options}
              onChange={(event, newValue) => {
                if (newValue) {
                  selectedPatient(newValue);
                }
                setInputValue("");
              }}
              onInputChange={handleInputChangeSearch}
              options={patientData || []}
              getOptionLabel={(option) =>
                option.uid && option.full_name
                  ? `${option.uid} - ${option.full_name}`
                  : ""
              }
              noOptionsText=""
              renderOption={(props, option) => (
                <MenuItem
                  key={option.id + Math.random()}
                  // onClick={() => selectedPatient(option)}
                  {...props}
                  sx={{
                    ...menuItemStyle,
                    width: "100%",
                    "&:hover, &.Mui-focused": {
                      backgroundColor: "#0067FF40 !important",
                      color: "black !important",
                    },
                  }}
                >
                  {" "}
                  {option.uid
                    ? `${option.uid} - ${option.full_name}`
                    : option.full_name}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  id="search"
                  // value={inputValue}
                  value={inputValue}
                  placeholder="Search Patient"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        <SearchIcon sx={{ opacity: 0.4 }} color="grey" />

                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  sx={{
                    ...textfieldTextPrep,
                  }}
                />
              )}
            />
          </Grid>
          {allFormFields.map((field) => (
            <Grid
              item
              key={field.id}
              {...field.gridProps}
              sx={{
                display: field.position === "right" ? "flex" : "block",
                justifyContent:
                  field.position === "right" ? "flex-end" : "flex-start",
              }}
            >
              {renderField(field)}
            </Grid>
          ))}
        </Grid>

        <Grid container mt={1.5}>
          {investigations?.map((investigation) => (
            <Box
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                minWidth: "175px",
                maxWidth: '180px',
                margin: "4px",
              }}
              style={{ flexDirection: "column" }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", marginTop: "2px" }}
              >
                <CloseIcon
                  onClick={() => handleDeleteInvestigation(investigation.name)}
                  sx={{
                    color: "red",
                    fontWeight: "800",
                    width: "20px",
                    marginRight: "2px",
                    cursor: "pointer",
                  }}
                />

                <Typography variant="heading2_14" fontWeight={"410"}
                  sx={{
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                      display: 'none', // For Webkit browsers (Chrome, Safari): Hides the scrollbar
                    },
                  }}
                >
                  {investigation?.name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "5px" }}>
                <Box>
                  <TextField
                    sx={{
                      ...textfieldTextPrep,
                      backgroundColor: "white",
                      borderRadius: "15px",
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                        cursor: "not-allowed",
                        color:
                          investigation?.before_color === "danger"
                            ? "CypherMD_Text.tomato"
                            : "",
                      },
                    }}
                    disabled={true}
                    placeholder={`Before ${investigation?.name}`}
                    value={investigation?.before_value}
                  />
                  <Typography variant="body2_text">
                    {investigation?.before_date}
                  </Typography>
                </Box>

                <Box>
                  <TextField
                    sx={{
                      ...textfieldTextPrep,
                      backgroundColor: "white",
                      borderRadius: "15px",
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                        cursor: "not-allowed",
                        color:
                          investigation?.before_color === "danger"
                            ? "CypherMD_Text.tomato"
                            : "",
                      },
                    }}
                    disabled={true}
                    placeholder={`After ${investigation?.name}`}
                    value={investigation?.after_value}
                  />
                  <Typography variant="body2_text" sx={{ textAlign: "center" }}>
                    {investigation?.after_date}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Grid>
      </Box>

      <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
        <PatientMedicalReports onClose={() => setOpenModal(false)} />
      </CustomModal>

      <CustomModal
        open={openReferrerEmail}
        onClose={() => setOpenReferrerEmail(false)}
      >
        <ReferrerEmail
          mailTemplate={mailTemplate}
          onClose={() => setOpenReferrerEmail(false)}
        />
      </CustomModal>

    </>
  );
};

export default PatientInfoForm;

import Cookies from "js-cookie";
import { BASE_URL } from "./connectionUrl";
import { showToast } from "../utils/toastUtil";
const authStaging = Cookies.get("authStaging")
  ? JSON.parse(Cookies.get("authStaging"))
  : null;
const token = authStaging?.token || "";

export const getAvailableSlots = async (date) => {
  const url = `${BASE_URL}/api/v1/appointments/available_slots?date=${date}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}}`,
      },
    });

    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Fetching available slots failed:", error);
    throw error;
  }
};

export const getSearchPatient = async ({ q, perPage, page }) => {
  const url = `${BASE_URL}/api/v1/patients?q=${q}&page=${page}&per_page=${perPage}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getPatient = async (id) => {
  const url = `${BASE_URL}/api/v1/patients/${id}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getEducationOptions = async (type) => {
  const url = `${BASE_URL}/api/v1/master/${type}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}}`,
      },
    });

    const data = await res.json();

    return data?.payload;
  } catch (error) {
    return error;
  }
};

export const getDoctorList = async () => {
  const url = `${BASE_URL}/api/v1/doctors`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    // console.log("API Response:", data);
    return data.payload;
  } catch (error) {
    console.error("Error fetching doctor list:", error);
    throw error;
  }
};

export const getDoctorPlans = async (id, visitType=null) => {

  let type= visitType? visitType.toLowerCase() : null;

  const url = `${BASE_URL}/api/v1/billings/specialist_plans?doctor_id=${id}&visit_type=${type}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    // console.log("API Response:", data);
    return data;
  } catch (error) {
    console.error("Error fetching doctor list:", error);
    throw error;
  }
};


export const getInvestigationAccordingToDr = async (id, visitType=null) => {

  const url = `${BASE_URL}/api/v1/specialists/${id}/default_investigations`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    // console.log("API Response:", data);
    return data;
  } catch (error) {
    console.error("Error fetching doctor list:", error);
    throw error;
  }
};


export const getAppointmentCount = async () => {
  const url = `${BASE_URL}/api/v1/appointments/daily_appointments_stats`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    // console.log("API Response:", data);
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getFutureAppointment = async (date, page, per_page) => {
  const url = `${BASE_URL}/api/v1/appointments/date_wise_appointments_status?date=${date}&page=${page}&per_page=${per_page}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getupcomingHolidays = async () => {
  const url = `${BASE_URL}/api/v1/appointments/upcoming_holiday_list`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getFilterDoctorAppointment = async ({
  visitDate,
  status,
  // visitIteq,
  doctorId,
}) => {
  const url = `${BASE_URL}/api/v1/appointments/filter_visits?q[visit_date_eq]=${visitDate}&q[status_eq]=${status}&doctor_id=${doctorId}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getCallReminderDetails = async (id) => {
  const url = `${BASE_URL}/api/v1/appointments/call_details?appointment_id=${id}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

///// get apis for prescription page///////////

export const appointmentDetail = async (id) => {
  const url = `${BASE_URL}/api/v1/appointments/${id}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};
export const getPatientPrescription = async (id) => {
  const url = `${BASE_URL}/api/v1/prescriptions/${id}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getMedicineList = async (q) => {
  const url = `${BASE_URL}/api/v1/prescriptions/medicines_list?q=${q}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getDescriptionList = async (q) => {
  const url = `${BASE_URL}/api/v1/medicines/descriptions?q=${q}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getReferDoctor = async () => {
  const url = `${BASE_URL}/api/v1/referrers?type=all`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const TreatmentList = async () => {
  const url = `${BASE_URL}/api/v1/prescriptions/treatments`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getAllTreatmentShortCodeList = async () => {
  const url = `${BASE_URL}/api/v1/prescriptions/special_instructions_shortcodes`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const selectedTreatment = async (id) => {
  const url = `${BASE_URL}/api/v1/treatments/${id}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const doctorAppointmentStats = async () => {
  const url = `${BASE_URL}/api/v1/prescriptions/doctor_appointment_stats`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const medicalTestShortCode = async (id) => {
  const url = `${BASE_URL}/api/v1/medical_tests?shortcode=${id}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const followUpList = async (query) => {
  const url = `${BASE_URL}/api/v1/medicines/followup?q=${query}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload.followups;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const videosList = async () => {
  const url = `${BASE_URL}/api/v1/prescriptions/videos`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getMailTemplate = async (referrerId, patientId) => {
  const url = `${BASE_URL}/api/v1/prescriptions/referrer_template?referrer_id=${referrerId}&patient_id=${patientId}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getSplInst = async (chip) => {
  const url = `${BASE_URL}/api/v1/prescriptions/special_instructions?code=${chip}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getMedicalReports = async (id) => {
  const url = `${BASE_URL}/api/v1/prescriptions/medical_reports?patient_id=${id}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getMedicineRemarkList = async (q) => {
  const url = `${BASE_URL}/api/v1/medicines/remarks?q=${q}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

// export const getInvestigationListTrue = async (q) => {
//   const url = `${BASE_URL}/api/v1/prescriptions/investigations?is_investigation=true`;
//   try {
//     const res = await fetch(url, {
//       method: "GET",
//       headers: {
//         Authorization: `Token ${token}`,
//       },
//     });

//     if (!res.ok) {
//       throw new Error(`HTTP error! status: ${res.status}`);
//     }

//     const data = await res.json();
//     // console.log(data);
//     return data;
//   } catch (error) {
//     console.error("Error fetching :", error);
//     return error;
//   }
// };

export const getInvestigationList = async () => {
  const url = `${BASE_URL}/api/v1/prescriptions/default_investigations`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getAllMedicine = async () => {
  const url = `${BASE_URL}/api/v1/prescriptions/medicines_list`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getAllMedicalTestCategory = async () => {
  const url = `${BASE_URL}/api/v1/medical_tests/medical_test_category_list`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getAllLabList = async () => {
  const url = `${BASE_URL}/api/v1/medical_tests/lab_list`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getAllMedicalTestAndCompositeLab = async (page,perPage,q) => {
   
  const url = q? `${BASE_URL}/api/v1/medical_tests?q=${q}` :
  `${BASE_URL}/api/v1/medical_tests?page=${page}&?perPage=${perPage}` 
  // const url = `${BASE_URL}/api/v1/medical_tests?type=all`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getAllMedicineSalt = async () => {
  const url = `${BASE_URL}/api/v1/prescriptions/medicine_salts_list`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getAllMedicineSaltPharmacy = async () => {
  // /api/v1/pharmacy/medicine_salts
  const url = `${BASE_URL}/api/v1/pharmacy/medicine_salts?type=all`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getAllMedicineDescription = async () => {
  const url = `${BASE_URL}/api/v1/prescriptions/medicine_descriptions`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getAllRemarks = async () => {
  const url = `${BASE_URL}/api/v1/prescriptions/medicines_remarks
`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getRecomendations = async () => {
  const url = `${BASE_URL}/api/v1/prescriptions/recomendations`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getMedicalTestShortCodeList = async () => {
  const url = `${BASE_URL}/api/v1/medical_tests/medical_test_shortcodes`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getMedicalTestsList = async () => {
  const url = `${BASE_URL}/api/v1/medical_tests/medical_tests_list`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

// api for billing //////

export const getBillingInvoices = async () => {
  const url = `${BASE_URL}/api/v1/invoices`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getMedicalTestList = async () => {
  const url = `${BASE_URL}/api/v1/billings/departments_medical_tests_list`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getMedicalTestById = async (id) => {
  const url = `${BASE_URL}/api/v1/medical_tests/${id}`;
  //  const url = `${BASE_URL}/api/v1/medical_tests/daycare_procedure?id=${id}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};
export const getProcedureMedicalTestById = async (id, type) => {
  // const url = `${BASE_URL}/api/v1/medical_tests/${id}`;
  if (!id) {
    showToast("id is not define for the selected test", "ERROR");
    return;
  }
  const url = `${BASE_URL}/api/v1/medical_tests/daycare_procedure?id=${id}&type=${type}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getInvoiceDataById = async (id) => {
  const url = `${BASE_URL}/api/v1/invoices/${id}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};
export const rePrintInvoice = async (id) => {
  const url = `${BASE_URL}/api/v1/invoices/${id}/reprint`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getAppointmentCountDetail = async ( 
  page = 1,
  per_page = 10,
  type = null,
) => {

  // const endpoint =
  //   type === null
  //     ? `/api/v1/pharmacy/medicines?page=${page}&per_page=${per_page}`
  //     : `/api/v1/pharmacy/medicines?type=${type}`;
 
   const endpoint= type==='all' ? `/api/v1/prescriptions/show_appointment_list?page=${page}&per_page=${per_page}`  :
      `/api/v1/prescriptions/show_appointment_list?type=${type}&page=${1}&per_page=${per_page}`
  try {
    const res= await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}${endpoint}`,{
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getQuickCheckPatient = async (id) => {
  const url = `${BASE_URL}/api/v1/quick_check?doctor_id=${id}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};
export const getCollectionData = async () => {
  const url = `${BASE_URL}/api/v1/billings/daily_collections?type=all`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};
export const getMedicalTestByPatientId = async (id) => {
  const url = `${BASE_URL}/api/v1/appointments/${id}/medical_tests`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

// api for lab
export const getCountOfTests = async () => {
  const url = `${BASE_URL}/api/v1/labs`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data?.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getPatientPreviousReport = async (id, mobile, date) => {
  const endpoint = date
    ? `/api/v1/labs/previous_reports?patient_id=${id}&mobile=${mobile}&date=${date}`
    : `/api/v1/labs/previous_reports?patient_id=${id}`;
  const url = `${BASE_URL}${endpoint}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data?.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};
export const getPendingLabReport = async (page, per_page) => {
  const url = `${BASE_URL}/api/v1/labs/pending_tests?page=${page}&per_page=${per_page}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};
export const getLabPatientsReportData = async (testDate) => {
  // console.log(testDate, "testDate");
  const endpoint = testDate
    ? `/api/v1/labs/patients?date=${testDate}`
    : `/api/v1/labs/patients`;
  const url = `${BASE_URL}${endpoint}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data?.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};
export const getShowTestList = async ({ id, date = "" }) => {
  const endpoint = `/api/v1/labs/show_tests_list?patient_id=${id}${
    date ? `&date=${date}` : ""
  }`;

  const url = `${BASE_URL}${endpoint}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data?.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};
export const postMarkStatus = async (payload) => {
  const { status, medical_test_id, invoice_item_id, patient_id } = payload;
  const url = `${BASE_URL}/api/v1/labs/mark_status?status=${status}&medical_test_id=${medical_test_id}&invoice_item_id=${invoice_item_id}&patient_id=${patient_id}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const sendReferrerMail = async (data, referrerId, patientId) => {
  const strippedData = encodeURIComponent(data.content); // Encode the data to be used in the URL
  const url = `${BASE_URL}/api/v1/prescriptions/send_referrer_mail?referrer_id=${referrerId}&patient_id=${patientId}&content=${strippedData}`;
  try {
    const res = await fetch(url, {
      method: "GET", // Keep as GET
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    const result = await res.json();
    return result;
  } catch (error) {
    return error;
  }
};

//for pharmacy

export const getTotalCollection = async (date) => {
  const url = `${BASE_URL}/api/v1/pharmacy/daily_collections?date=${date}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getMedicineByName = async (medicineNames, currentName) => {
  const url = `${BASE_URL}/api/v1/medicines/medicine_by_name?names=${medicineNames}&current_name=${currentName}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getMedicineDetail = async (id) => {
  const url = `${BASE_URL}/api/v1/medicines/detail?id=${id}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getPriceByQuantity = async (
  medicineId,
  quantity,
  batchId = null
) => {
  const url = `${BASE_URL}/api/v1/medicines/get_price_by_quantity?id=${medicineId}&quantity=${quantity}&batch_id=${batchId}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getPriceByQuantityForReturn = async (medicineId, quantity) => {
  const url = `${BASE_URL}/api/v1/medicines/get_price_by_quantity?id=${medicineId}&quantity=${quantity}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const pharmacyIndex = async () => {
  const url = `${BASE_URL}/api/v1/pharmacy`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getInvoice = async (id) => {
  const url = `${BASE_URL}/api/v1/pharmacy/edit_invoice?invoice_id=${id}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const refundInvoice = async (invoiceId) => {
  const url = `${BASE_URL}/api/v1/pharmacy/refund?invoice_id=${invoiceId}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const reprintInvoice = async (invoiceId, prescriptionId) => {
  const url = `${BASE_URL}/api/v1/pharmacy/reprint?invoice_id=${invoiceId}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const prePatientMedicine = async (prescriptionId) => {
  const url = `${BASE_URL}/api/v1/pharmacy/patient_medicines?prescription_id=${prescriptionId}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    return data.payload;
  } catch (error) {
    console.error("Error fetching :", error);
    return error;
  }
};

export const getMedicalTestListLab = async (
  page = 1,
  pageSize = 10,
  type = null
) => {
  const endpoint =
    !type? `/api/v1/medical_tests?page=${page}&perPage=${pageSize}`
      : `/api/v1/medical_tests?q=${type}`;
      // : `/api/v1/medical_tests?page=${page}&perPage=${pageSize}&q=${type}`;
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}${endpoint}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else { 
      console.log("error fetching purchase_orders list ");
    }
  } catch (error) {
    console.log(error.message || "something went wrong");
  }
};


export const getMedicalTestDetail = async (id) => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}/api/v1/medical_tests/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
          Authorization: `Token ${token}`,
        },
      }
    );
    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.log("error fetching purchase_orders list ");
    }
  } catch (error) {
    console.log(error.message || "something went wrong");
  }
};

//  purchase order pharmacy admin api
export const getPurchaseOrderList = async (
  page = 1,
  pageSize = 10,
  type = null
) => {
  const endpoint =
    type === null
      ? `/api/v1/pharmacy/purchase_orders?page=${page}&perPage=${pageSize}`
      : `/api/v1/pharmacy/purchase_orders?type=${type}`;
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}${endpoint}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.log("error fetching purchase_orders list ");
    }
  } catch (error) {
    console.log(error.message || "something went wrong");
  }
};

export const getSupplierList = async (page = 1, pageSize = 10, type = null) => {
  const endpoint =
    type === null
      ? `/api/v1/pharmacy/suppliers?page=${page}&perPage=${pageSize}`
      : `/api/v1/pharmacy/suppliers?type=all`;
  try {
    const response = await fetch(
      `${BASE_URL}${endpoint}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.log("error fetching purchase_orders list ");
    }
  } catch (error) {
    console.log(error.message || "something went wrong");
  }
};

export const getPurchaseOrderDetail = async (id) => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}/api/v1/pharmacy/purchase_orders/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.log("error fetching purchase_orders list ");
    }
  } catch (error) {
    console.log(error.message || "something went wrong");
  }
};

export const getSupplierDetails = async (id) => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}/api/v1/pharmacy/suppliers/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.log("error fetching purchase_orders list ");
    }
  } catch (error) {
    console.log(error.message || "something went wrong");
  }
};


export const getMedicineDetails = async (id) => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}/api/v1/pharmacy/medicines/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.log("error fetching purchase_orders list ");
    }
  } catch (error) {
    console.log(error.message || "something went wrong");
  }
};

export const getMedicineSaltDetails = async (id) => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}/api/v1/pharmacy/medicine_salts/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.log("error fetching purchase_orders list ");
    }
  } catch (error) {
    console.log(error.message || "something went wrong");
  }
};

export const getLabInventories = async () => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}/api/v1/pharmacy/lab_inventories?type=all`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.log("error fetching purchase_orders list ");
    }
  } catch (error) {
    console.log(error.message || "something went wrong");
  }
};

export const getPackList = async () => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}/api/v1/pharmacy/purchase_orders/medicines_packing`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.log("error fetching purchase_orders list ");
    }
  } catch (error) {
    console.log(error.message || "something went wrong");
  }
};

export const getQuickCheckReports = async (patientId, appointmentId) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/v1/medical_test_reports/current_reports?patient_id=${patientId}&appointment_id=${appointmentId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.log("error fetching purchase_orders list ");
    }
  } catch (error) {
    console.log(error.message || "something went wrong");
  }
};

export const getFormTypeList = async () => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}/api/v1/pharmacy/medicines/medicine_form_types`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.log("error fetching purchase_orders list ");
    }
  } catch (error) {
    console.log(error.message || "something went wrong");
  }
};

export const getMedicineListAdmin = async (
  page = 1,
  pageSize = 10,
  type = null
) => {
  const endpoint =
    type === null
      ? `/api/v1/pharmacy/medicines?page=${page}&per_page=${pageSize}`
      : `/api/v1/pharmacy/medicines?type=${type}`;
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}${endpoint}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.log("error fetching medicine list ");
    }
  } catch (error) {
    console.log(error.message || "something went wrong");
  }
};

export const getMedicineSaltListAdmin = async (
  page = 1,
  pageSize = 10,
  type = null
) => {
  const endpoint =
    type === null
      ? `/api/v1/pharmacy/medicine_salts?page=${page}&per_page=${pageSize}`
      : `/api/v1/pharmacy/medicine_salts?type=${type}`;
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}${endpoint}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.log("error fetching medicine salt list ");
    }
  } catch (error) {
    console.log(error.message || "something went wrong");
  }
};

export const getMedicineBatch = async (id) => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${BASE_URL}/api/v1/pharmacy/medicine_batches/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.log("error fetching medicine salt list ");
    }
  } catch (error) {
    console.log(error.message || "something went wrong");
  }
};

import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Box,
  FormControl,
  Select,
  MenuItem,
  keyframes,
  Modal,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PushPinIcon from "@mui/icons-material/PushPin";
import { drListWrapper, drTableRow } from "../../style/DoctorListComponent";
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { menuItemStyle, selectField, tableCellStyle } from "../../style/AppointmentStyle";
import { useDispatch, useSelector } from "react-redux";
import { setAppointmentData, setDoctorOption, setPatientDetailsData } from "../../redux/slices/nurseSlice";
import UseFetchDoctorList from "../../helper/UseFetchDoctorList";
import UseFetchQuickCheck from "../../helper/UseFetchQuickCheck";
import { pinPatientAppointment } from "../../connections/putApi";
import { showToast } from "../../utils/toastUtil";
import UseFetchAppointmentDetailsPatient from "../../helper/UseFetchAppointmentDetailsPatient";
import UsePatientDetail from "../../helper/UsePatientDetail";
import updownar from '../../assets/updownar.svg';
import loadingSpinner from '../../assets/loadingSpinner.svg';
import not_found from '../../assets/not_found.svg'


const rotateSpinner = keyframes`
0% { transform: rotate(0deg); }
10% { transform: rotate(36deg); }
20% { transform: rotate(72deg); }
30% { transform: rotate(108deg); }
40% { transform: rotate(144deg); }
50% { transform: rotate(180deg); }
60% { transform: rotate(216deg); }
70% { transform: rotate(252deg); }
80% { transform: rotate(288deg); }
90% { transform: rotate(324deg); }
100% { transform: rotate(360deg); }
`;


const DrApot = () => {

  const dispatch = useDispatch();
  const { data: drQuery } = UseFetchDoctorList();

  useEffect(() => {
    dispatch(setDoctorOption(drQuery?.doctors));
    setDoctors(drQuery?.doctors)
  }, [drQuery, dispatch])

  const { doctorOptions } = useSelector((state) => state.nurse);
  const [doctors, setDoctors] = useState(drQuery?.doctors);
  const [doctorId, setDoctorId] = useState(drQuery?.doctors[0]?.id || 1);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const { data: appointmentDetails, isLoading } = UseFetchAppointmentDetailsPatient(selectedPatientId);
  const { data: patientDetails } = UsePatientDetail(appointmentDetails?.patient_id);

  // console.log(patientDetails);
  // Dispatch data to Redux store when it changes
  useEffect(() => {
    if (patientDetails) {
      dispatch(setPatientDetailsData(patientDetails?.payload));
    }
  }, [patientDetails, dispatch]);

  useEffect(() => {
    if (appointmentDetails) {
      dispatch(setAppointmentData(appointmentDetails));
    }
  }, [appointmentDetails, dispatch]);



  const pinPatient = async (id, e) => {
    e.stopPropagation();
    const response = await pinPatientAppointment(id);
    if (!response.error) {
      refetch();
      console.log(response.payload);
      showToast("SUCCESS  ", "SUCCESS");
    } else {
      console.error("Failed to pin appointment:",);
    }
  };

  const handleDoctorChange = (event) => {
    setDoctorId(event.target.value);
  };

  const { data: filterList, refetch, isError } = UseFetchQuickCheck(doctorId);

  // console.log(doctors);
  // console.log(drQuery?.doctors);
  // console.log(doctorOptions);
  // console.log(filterList);
  // console.log(filterList?.payload?.appointments);


  const handleSelect = (id) => {
    setSelectedPatientId(id);
  };


  return (
    <Grid
      //  sx={drListWrapper }
      sx={{
        backgroundColor: '#F7F7FF', margin: 0,
        padding: "20px",
        paddingTop: "35px",
        borderTopLeftRadius: "20px",
        marginBottom: "auto",
      }}
      container
      spacing={0}
      justifyContent="center"
      lg={4}

      md={3.5}
      sm={12}
    >

      {/* Doctor list */}
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
          columnGap={2}
        >

          <FormControl variant="outlined">
            <Select
              fullWidth
              sx={{
                ...selectField,
              }}
              value={doctorId || ""}
              onChange={handleDoctorChange}
              name="doctor"
              displayEmpty
            >

              <MenuItem value="" disabled>
                <em>{"Select Doctor"}</em>
              </MenuItem>
              {doctors?.map((option) => (
                <MenuItem
                  id={option?.id || "menu"}
                  key={option.id + Math.random()}
                  value={option.id}
                  sx={menuItemStyle}
                >
                  {option.full_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>


          <Typography
            letterSpacing={"0.15px"}
            variant="heading1"
            fontSize={'18px'}
            color={"neutral.navyBlue"}
          > {filterList?.payload?.appointments?.length &&
            ` Appointments : ${filterList?.payload?.appointments?.length}`
            } </Typography>
        </Box>


        <TableContainer
          sx={{ mt: 2, maxHeight: "100vh", minHeight: "70vh" }}
        >

          <Table sx={{ overflowY: "scroll" }}>
            <TableHead>
              <TableRow >
                <TableCell sx={tableCellStyle}>
                  <Typography >Name </Typography>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <Typography >
                    Appointment ID
                  </Typography>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <Typography >
                    Visit Time
                  </Typography>
                </TableCell>

                <TableCell sx={tableCellStyle}>
                  <Typography >Pin  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            {filterList?.payload?.appointments?.length > 0 ? (
              <TableBody>
                {filterList?.payload?.appointments?.map((patient, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      // backgroundColor:"white",
                      // borderTop:"2px solid #F7F7FF",
                      // borderBottom:"8px solid #F7F7FF",
                      // cursor: "pointer",
                      // borderRadius:'20px',
                      // "&:hover": {
                      //  boxShadow: "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
                      // },
                      borderBottom: '8px solid #F7F7FF',
                      backgroundColor: selectedRow === patient?.id ? '#0067FF40' : "white",
                      borderTop: index === 0 ? '6px solid #F7F7FF' : '',
                      transition: 'all 0.3s ease-in-out', // Smooth transition for hover effects
                      '&:hover': {
                        transform: 'scale(1.001)', // Slight scaling for the pop-up effect
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for outer glow
                        borderRadius: '20px !important',
                      },
                    }}
                    onClick={() => {
                      handleSelect(patient?.id)
                      setSelectedRow(patient?.id)
                    }
                    }
                  >

                    <TableCell sx={{ padding: "4px", pl: "16px", borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px" }}>
                      <Typography variant="body_Grey1" fontWeight={'bold'}>
                        {" "}
                        {patient.patient_name}
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center" }}>

                        <Typography variant="body2_text">
                          {" "}
                          {patient?.patient_uid} ({patient.status})
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ p: "4px", pl: "16px" }}>
                      <Typography variant="body_Grey1" color={"text.grey"}>
                        {patient?.id}
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ p: "4px", pl: "16px" }}>
                      <Typography color={"text.grey"} variant="body3_text">
                        {/* {patient?.visit_time} */}
                        {patient?.appt_time}
                      </Typography>{" "}
                      <br />
                      <Typography color={"text.grey1"} variant="body2_text">
                        ({patient.visit_type})
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ p: "4px", pl: "16px", borderTopRightRadius: "20px", borderBottomRightRadius: "20px" }}>
                      <PushPinOutlinedIcon
                        disabled={isLoading}
                        sx={{
                          border: '2px solid #0067FF',
                          borderRadius: '50%',
                          cursor: 'pointer',
                          padding: '2px',
                          backgroundColor: patient.pinned_sequence > 0 ? '#0067FF' : '',
                          color: patient.pinned_sequence > 0 ? 'white' : '#0067FF',
                          width: '20px',
                          height: '20px',
                          margin: '5px',
                        }}

                        onClick={(e) => pinPatient(patient.id, e)}
                      />



                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

            ) : (
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "center",
                    borderBottom: 'none'
                  }}
                  rowSpan={5}
                  colSpan={5}
                >

                  <Box sx={{ width: '100%', display: filterList?.payload?.appointments?.length > 0 ? 'none' : '', }}>
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: 'white', borderRadius: '20px', height: '185px', marginTop: '100px', borderBottom: 'none' }}>

                      <Box sx={{ marginTop: '-80px', marginLeft: '20px' }}>
                        <img src={not_found} alt='' width={'160px'} />
                      </Box>

                      <Box sx={{ paddingRight: '50px', marginTop: '-14px' }}>

                        <Typography fontSize={'22px'} fontWeight={'bold'} color={'neutral.navyBlue'}><Typography variant="span" color={'black'}>Data not found ! </Typography> </Typography>
                        {/* <Typography fontSize={'24px'}  fontWeight={'bold'} >You've made it </Typography> */}
                        <Typography sx={{ marginTop: "8px" }}> </Typography>
                      </Box>

                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            )}

          </Table>




        </TableContainer>
      </Grid>

      <Modal
        open={isLoading}
        // open={true}
        //  onClose={}
        sx={{
          border: 'none !important',
          outline: 'none !important',
          position: "absolute",
          top: "40%",
          left: "47%",
        }}
      >
        <Box sx={{
          animation: `${rotateSpinner} 1s linear infinite`, width: "50px", border: 'none !important',
          outline: 'none !important',
        }}>
          <img src={loadingSpinner} alt='' style={{
            width: "50px", height: "50px", border: 'none !important',
            outline: 'none !important',
          }} />
        </Box>

      </Modal>

    </Grid>

  );
};

export default DrApot;
